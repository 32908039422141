import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../../services/events.service';
import { ModalActionsService } from '../../../services/modalActions.service';

@Component({
  selector: 'app-horse-dispensation',
  templateUrl: './horse-dispensation.component.html',
  styleUrls: ['./horse-dispensation.component.scss']
})
export class HorseDispensationComponent implements OnInit {


  @Input() horse;
  meta: {
    loading: boolean,
    saving: boolean
  };
  possibleStatuses: string[];
  selectedStatus: string;
  userId: number;

  constructor(
    private activeModal: NgbActiveModal,
    private notification: ToastrService,
    private eventsService: EventsService,
    private modalActionsService: ModalActionsService,
  ) { }

  ngOnInit() {
    this.meta = {
      loading: false,
      saving: false
    };

    this.possibleStatuses = [
      'Fully Shod',
      'Unshod',
      'Partially Shod - Front Shoes Off',
      'Partially Shod - Hind Shoes Off',
      'Other'
    ];

    this.eventsService.observableUser.subscribe(data => {
      this.userId = data.id;
    });
  }

  confirm = () => {
    this.meta.saving = true;
    const payload = {
      horseId: this.horse.horseId,
      dispensation: this.selectedStatus,
      userId: this.userId
    };
    this.modalActionsService.updateHorseShoesDispensation(payload).subscribe(
      res => {
        this.activeModal.close(res);
        this.notification.success(`${this.horse.name} shoes dispensation has been updated.`, 'Success!');
        this.meta.saving = false;
      },
      err => {
        this.notification.error(`Could't update the shoe dispensation: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.saving = false;
      }
    );
  }

  cancel = (action) => {
    this.activeModal.dismiss(action);
  }

}
