<div class="d-flex flex-row" [ngClass]="{'justify-content-center': detailed, 'justify-content-start': !detailed}">
  <div class="flex-shrink-1" [ngbTooltip]="!detailed && suspendedDates ? suspensionDatesTooltip : null" placement="top" container="body">
    <span *ngFor="let outcome of processedOutcomes; last as lastOutcome" [ngClass]="{'font-weight-bold': detailed}">
      <span>{{outcome}}</span>
      <span *ngIf="!lastOutcome">, </span>
    </span>
  </div>
</div>
<div *ngIf="detailed" class="d-flex flex-column">
  <div *ngIf="suspendedDates">
    <span *ngIf="suspensionPeriod === 'Days'" class="text-center">
      <span>Suspension Dates: </span>
      <span *ngIf="!suspendedDates.length">Not Specified</span>
      <span *ngFor="let suspension of suspendedDates; last as lastSuspension">
        {{suspension.date | date:'dd/MM/yyyy'}}
        <span *ngIf="!lastSuspension">, </span>
      </span>
    </span>
    <span *ngIf="suspensionPeriod !== 'Days'" class="text-center">
      <span *ngIf="!suspendedDates.length">Not Specified</span>
      <span *ngIf="suspendedDates.length">
        <span>From {{suspendedDates[0].date | date:'dd/MM/yyyy'}}</span>
        <span> - To {{suspendedDates[1].date | date:'dd/MM/yyyy'}}</span>
      </span>
    </span>
  </div>
</div>

<ng-template #suspensionDatesTooltip>
  <span *ngIf="suspendedDates">
    <span *ngIf="suspensionPeriod === 'Days'">
      <span>Suspension Days</span>
      <br>
      <span *ngIf="!suspendedDates.length">Not Specified</span>
      <span *ngFor="let suspension of suspendedDates; last as lastSuspension">
        {{suspension.date | date:'dd/MM/yyyy'}}
        <span *ngIf="!lastSuspension">, </span>
      </span>
    </span>
    <span *ngIf="suspensionPeriod !== 'Days'">
      <span *ngIf="!suspendedDates.length">Not Specified</span>
      <span *ngIf="suspendedDates.length">Suspended from {{suspendedDates[0].date | date:'dd/MM/yyyy'}}</span>
      <span *ngIf="suspendedDates.length"> to {{suspendedDates[1].date | date:'dd/MM/yyyy'}}</span>
    </span>
  </span>
</ng-template>
