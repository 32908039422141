import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toStones'
})
export class ToStonesPipe implements PipeTransform {

  transform(value: string|any, args?: any): string|any {
    if (value && (typeof value === 'number')) {
      // console.log('number', value)
      const stones = Math.floor(value / 14);
      const lbs = value % 14;
      return `${stones}st ${lbs}lbs`;
    } else if (value && (typeof value === 'string')) {
      // console.log('string ...', value)
      const decimalPattern = /^\d+(\.\d{2})?$/;
      if (decimalPattern.test(value)) {
        // console.log('... has decimal', value)
        value = parseFloat(value);
        const stones = Math.floor(value / 14);
        const lbs = value % 14;
        return `${stones} st ${lbs} lbs`;
      } else {
        // console.log('... not decimale', value)
        return value;
      }
    } else {
      // console.log('something unexpected, casting to string', value)
      return value;
    }
  }

}
