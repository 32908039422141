import {Component, OnInit, Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../../../services/events.service';
import { ModalActionsService } from '../../../../services/modalActions.service';
import * as moment from 'moment';

@Component({
  selector: 'app-race-late-race',
  templateUrl: './race-late-race.component.html',
  styleUrls: ['./race-late-race.component.scss'],
})
export class RaceLateRaceComponent implements OnInit {
  @Input() race;
  @Input() lateRaces;
  meta: {
    loading: boolean,
    saving: boolean
  };
  selectFromReasons = [
    {reason: 'Ambulance Issue'},
    {reason: 'Weather'},
    {reason: 'Track Issue'},
    {reason: 'Loading/Starting Issues'},
    {reason: 'Welfare Issues'},
    {reason: 'Equipment/Shoeing'},
    {reason: 'Raceday Equipment Failure'},
    {reason: 'Television/Broadcast delay request'},
    {reason: 'Official delay request (due to abandonment elsewhere)'},
    {reason: 'Avoiding Clash'},
    {reason: 'Knock on from previous late race'},
    {reason: 'Late to post'},
    {reason: 'Unruly/Loose horse'},
    {reason: 'Other'},
  ];
  ambulanceIssues = [
    {reason: 'Human'},
    {reason: 'Horse'},
  ];
  weatherIssues = [
    {reason: 'Low Sun'},
    {reason: 'Fog'},
    {reason: 'Other'},
  ];
  trackIssues = [
    {reason: 'Inspection'},
    {reason: 'Repair'},
    {reason: 'Replace rails'},
    {reason: 'Other'},
  ];
  loadingStartingIssues = [
    {reason: 'Slow Load'},
    {reason: 'Unruly Horse'},
    {reason: 'Slow to line up'},
    {reason: 'Stalls/Tape failure'},
  ];
  WelfareIssues = [
    {reason: 'Horse kicked at start'},
    {reason: 'Horse appeared lame at start'},
    {reason: 'Rider injured at start'},
    {reason: 'Equine Fatality'},
  ];
  EquipmentIssues = [
    {reason: 'Farrier'},
    {reason: 'Head-gear'},
    {reason: 'Saddle/girth'},
    {reason: 'Other'},
  ];
  LateToPostIssues = [
    {reason: 'Jockey change'},
    {reason: 'Lost colours'},
    {reason: 'Jockey late to parade ring'},
    {reason: 'Horse late to parade ring'},
    {reason: 'Late onto course'},
    {reason: 'Incident in previous race'},    
    {reason: 'Slow to post'},
  ];
  //actualRaceTime: string;
  userId: number;
  reason1: string;
  reason2: string;
  reason3: string;
  action: string;
  seconds: true;
  time = {hour: new Date().getHours(), minute: new Date().getMinutes(), second: 0};
  actualRaceTime = moment(this.time).format('HH:mm:ss');
  onTimeChange() {
    const actualRaceTime = this.time;
    this.actualRaceTime = moment(actualRaceTime).format('HH:mm:ss');
    // console.log(this.actualRaceTime);
  }
  onReason1Change() {
    this.reason2 = null;
  }
  constructor(
    private activeModal: NgbActiveModal,
    private notification: ToastrService,
    private eventsService: EventsService,
    private modalActionsService: ModalActionsService
  ) { }

  ngOnInit() {
    this.meta = {
      loading: false,
      saving: false
    };
    this.eventsService.observableUser.subscribe(data => {
      this.userId = data.id;
    });
    this.getLateRaces();
  }
  getLateRaces = () => {
    this.meta.loading = true;
    this.modalActionsService.getLateRaces(this.race).subscribe(
      res => {
        this.lateRaces = res;
        console.log(this.lateRaces);
        this.meta.loading = false;
      },
      err => {
        this.notification.error(`Couldn't  ` +
          `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
      }
    );
  }
  confirm = () => {
    this.meta.saving = true;
    const payload: any = {
      actualRaceTime: this.actualRaceTime,
      reason1: this.reason1,
      reason2: this.reason2,
      reason3: this.reason3,
      action: this.action,
      userId: this.userId,
    };
    this.modalActionsService.updateLateRace(this.race, payload).subscribe(
      res => {
        this.activeModal.close(res);
        this.notification.success(`${this.race.raceName} added as a late race`, 'Success!');
        this.meta.saving = true;
      },
      err => {
        this.notification.error(`Something went wrong ` +
          `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
        this.meta.saving = false;
      }
    );
  }
  deleteRace = () => {
    this.meta.loading = true;
    const payload: any = {
      actualRaceTime: this.actualRaceTime,
      reason1: this.reason1,
      reason2: this.reason2,
      reason3: this.reason3,
      action: this.action,
      userId: this.userId,
    };
    this.modalActionsService.removeLateRace(this.race, payload).subscribe(
      res => {
        this.activeModal.close(res);
        this.notification.success(`${this.race.raceName} removed as a late race`, 'Success!');
        this.meta.loading = false;
      },
      err => {
        this.notification.error(`Something went wrong ` +
          `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
        this.meta.loading = false;
      }
    );
  }
  cancel = (action) => {
    this.activeModal.dismiss(action);
  }
}
