

  <div  *ngIf="meta.loadingResults">
    <div class="row">
      <div class="col text-center">
        <img src="assets/loader.svg" class="d-block ml-auto mr-auto" alt="spinner">
        Loading...
      </div>
    </div>
  </div>
  <div *ngIf="!meta.loadingResults">

    <div class="row mb-3 mt-3">
      <div class="col text-right">
        <div (click)="showTableFilter()">
          <button *ngIf="!showFilter" class="btn btn-primary"><fa class="ml-1" name="filter"></fa> Show filter</button>
          <button *ngIf="showFilter" class="btn btn-primary"><fa class="ml-1" name="filter"></fa> Hide filter</button>
        </div>
      </div>
    </div>
    <div *ngIf="showFilter" class="showFilter">
      <form #filtersForm="ngForm" (ngSubmit)="loadResults()">
        <div class="row">
          <div class="col">
            <label class="font-weight-bold" for="fromDate">From Date</label>
            <input type="date" class="form-control" id="fromDate" required
                   [(ngModel)]="meta.filters.fromDate" (blur)="loadFilters(500)" name="fromDate" #fromDate="ngModel">
            <div [hidden]="fromDate.valid || fromDate.pristine" class="alert alert-danger p-0 m-0">
              This field is required
            </div>
          </div>
          <div class="col">
            <label class="font-weight-bold" for="toDate">To Date</label>
            <div class="form-group">
              <input type="date" class="form-control" id="toDate" required [(ngModel)]="meta.filters.toDate" (blur)="loadFilters(500)" name="toDate" #toDate="ngModel">
            </div>
            <div [hidden]="toDate.valid || toDate.pristine" class="alert alert-danger p-0 m-0">
              This field is required
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label class="font-weight-bold">Select one or more courses</label>
            <div class="form-group">
              <ng-select name="courseIds"
                         [items]="meta.availableFilters.courseIds"
                         [loading]="meta.loadingFilters"
                         bindLabel="value"
                         bindValue="id"
                         [multiple]="true"
                         appendTo="body"
                         [closeOnSelect]="true"
                         placeholder="Select one or more Courses"
                         [(ngModel)]="meta.filters.courseIds">
              </ng-select>
            </div>
          </div>
          <div class="col">
            <label class="font-weight-bold">Select one or more outcomes</label>
            <div class="form-group">
              <ng-select name="outcomes"
                         [items]="meta.availableFilters.outcomes"
                         [loading]="meta.loadingFilters"
                         bindLabel="value"
                         bindValue="id"
                         [multiple]="true"
                         appendTo="body"
                         [closeOnSelect]="true"
                         placeholder="Select one or more Outcomes"
                         [(ngModel)]="meta.filters.outcomes">
              </ng-select>
            </div>
          </div>
          <div class="col">
            <label class="font-weight-bold">Select one or more offences</label>
            <div class="form-group">
              <ng-select name="outcomeIds"
                         [items]="meta.availableFilters.offenceIds"
                         [loading]="meta.loadingFilters"
                         bindLabel="value"
                         bindValue="id"
                         [multiple]="true"
                         appendTo="body"
                         [closeOnSelect]="true"
                         placeholder="Select one or more Offences"
                         [(ngModel)]="meta.filters.offenceIds">
              </ng-select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label class="font-weight-bold">Select one or more Horses</label>
            <div class="form-group">
              <ng-select name="horseIds"
                         [items]="meta.availableFilters.horseIds"
                         [loading]="meta.loadingFilters"
                         bindLabel="value"
                         bindValue="id"
                         [multiple]="true"
                         appendTo="body"
                         [closeOnSelect]="true"
                         placeholder="Select one or more Horses"
                         [(ngModel)]="meta.filters.horseIds">
              </ng-select>
            </div>
          </div>
          <div class="col">
            <label class="font-weight-bold">Select one or more Penalties</label>
            <div class="form-group">
              <ng-select name="penalties"
                         [items]="meta.availableFilters.penalties"
                         [loading]="meta.loadingFilters"
                         bindLabel="value"
                         bindValue="id"
                         [multiple]="true"
                         appendTo="body"
                         [closeOnSelect]="true"
                         placeholder="Select one or more Penalties"
                         [(ngModel)]="meta.filters.penalties">
              </ng-select>
            </div>
          </div>
          <div class="col">
            <label class="font-weight-bold">Select one or more Sub-Offences</label>
            <div class="form-group">
              <ng-select name="subOffences"
                         [items]="meta.availableFilters.subOffences"
                         [loading]="meta.loadingFilters"
                         bindLabel="value"
                         bindValue="id"
                         [multiple]="true"
                         appendTo="body"
                         [closeOnSelect]="true"
                         placeholder="Select one or more Sub-Offences"
                         [(ngModel)]="meta.filters.subOffences">
              </ng-select>
            </div>
          </div>
        </div>

        <div class="row mb-5">
          <div class="col text-right">
            <button type="submit" class="btn btn-primary " [disabled]="!filtersForm.form.valid">
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
    <table class="table table-striped table-bordered table-responsive w-100 d-block d-md-table">
      <thead>
        <tr>
          <th></th>
          <th scope="col">Date</th>
          <th scope="col">Race</th>
          <th scope="col" *ngIf="entityType !== 'horse'">Horse</th>
          <th scope="col">Offence</th>
          <th scope="col">Outcome</th>
          <th scope="col">Penalties</th>
        </tr>
      </thead>
      <tbody *ngIf="entity.enquiries.length">
        <tr *ngFor="let e of entity.enquiries">
          <td class="px-0">
            <div class="enquiry-stages-nuggets">
              <div class="stage-legal" title="Legal Stage" [ngClass]="{'stage-active': e.stages.legal, 'stage-inactive': !e.stages.legal}">&nbsp;</div>
              <div class="stage-appeal" title="Appeal Stage" [ngClass]="{'stage-active': e.stages.appeal, 'stage-inactive': !e.stages.appeal}">&nbsp;</div>
              <div class="stage-disciplinary" title="Disciplinary Stage" [ngClass]="{'stage-active': e.stages.dp_committee, 'stage-inactive': !e.stages.dp_committee}">&nbsp;</div>
              <div class="stage-racecourse" title="Racecourse Stage" [ngClass]="{'stage-active': e.stages.racecourse, 'stage-inactive': !e.stages.racecourse}">&nbsp;</div>
            </div>
          </td>
          <td><button type="button" class="btn btn-link" (click)="openEnquiry(e.enquiryId)"><small>{{e.racedate | date:'dd/MM/yyyy'}}</small></button></td>
          <td>
            <div *ngIf="e.race" class="d-flex flex-column align-items-start justify-content-center">
              <a [routerLink]="['/races', e.race.year, e.race.fixtureId, e.race.raceId, e.race.divisionSequence]">{{e.race.courseShortName}} {{e.race.dateTime | date:'dd/MM/yyyy h:mm'}}</a>
              <small>{{e.race.distance}} C{{e.race.class}}</small>
            </div>
            <div *ngIf="!e.race" class="d-flex flex-column align-items-start justify-content-center">
              <span>N/A</span>
            </div>
          </td>
          <td *ngIf="entityType !== 'horse'">
            <div *ngIf="e.horseId" class="d-flex flex-column align-items-start justify-content-center">
              <a [routerLink]="['/horses', e.horseId]">{{e.horseName}}</a>
            </div>
            <div *ngIf="!e.horseId" class="d-flex flex-column align-items-start justify-content-center">
              <span>N/A</span>
            </div>
          </td>
          <td><div>{{e.offence.offence}}<span *ngIf="e.offence.subOffence"> ({{e.offence.subOffence}})</span></div></td>
          <td><div>{{e.breachStatus | titlecase}}</div></td>
          <td>
            <div>
            <app-enquiry-outcomes [enquiry]="e"></app-enquiry-outcomes>
          </div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!entity.enquiries.length">
        <tr class="text-center">
          <td class="p-5" *ngIf="entityType !== 'horse'" colspan="7">No Enquiries found for this {{entityType}}</td>
          <td class="p-5" *ngIf="entityType === 'horse'" colspan="6">No Enquiries found for this {{entityType}}</td>
        </tr>
      </tbody>
    </table>
  </div>
<div class="row">
  <div class="col text-center">
    <ngb-pagination *ngIf="meta.results.lastPage > 1" class="d-flex justify-content-center" [collectionSize]="meta.results.totalResult" [pageSize]="meta.results.pageSize" [rotate]="true" [maxSize]="3" [(page)]="meta.results.currentPage" (pageChange)="loadResults($event)" aria-label="pagination"></ngb-pagination>
  </div>
</div>
