import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { EventsService } from './events.service';

@Injectable()
export class AuthenticationService {

  loggedinHeaders: HttpHeaders;

  constructor(
    private http: HttpClient,
    private eventsService: EventsService,
  ) {
    const userSession = this.getFromSession();
    // console.log('LOGIN FROM SESSION', userSession);
    if (userSession !== null) {
      this.eventsService.changeUserData(userSession);
      this.setHeaders(userSession.token_type, userSession.access_token);
    }
  }

  login(username: string, password: string): any {
    const headers = new HttpHeaders({
      'Authorization': `Basic ${btoa(username + ':' + password)}`
    });
    return this.http.post(`${environment.apiBase}/v2/auth/token`, null, {headers: headers});
  }

  setHeaders(token_type = null, access_token = null): void {
    this.loggedinHeaders = null;
    if (token_type && access_token) {
      this.loggedinHeaders = new HttpHeaders({
        'Authorization': `${token_type} ${access_token}`
      });
      // console.log('DEFAULT HEADERS SET:', `'Authorization': ${token_type} ${access_token}`);
    }
  }

  getHeaders = () => this.loggedinHeaders;

  saveSession(userDetails): any {
    try {
      localStorage.setItem('Stipes_Auth', JSON.stringify(userDetails));
      return true;
    } catch (e) {
      throw e;
    }
  }

  destroySession(): any {
    try {
      localStorage.removeItem('Stipes_Auth');
      return true;
    } catch (e) {
      throw e;
    }
  }

  getFromSession(): any {
    return JSON.parse(localStorage.getItem('Stipes_Auth'));
  }

}
