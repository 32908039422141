<app-navbar></app-navbar>
<div *ngIf="meta.loading">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-4 offset-md-4 text-center">
        <img src="assets/loader.svg" class="d-block ml-auto mr-auto" alt="spinner">
      </div>
    </div>
  </div>
</div>
<div *ngIf="!meta.loading">
  <div class="container-fluid">
    <div class="row mb-5 mt-5 align-middle">
      <div class="col">
        <h2 class="font-weight-bold">{{owner.knownAs | uppercase}}</h2>
        <h5>{{owner.firstName}} {{owner.surname}}</h5>
      </div>
      <div class="col-2 text-right">
        <button type="button" class="btn btn-primary" (click)="openAddEnquiry(owner)">
          <span class="d-none d-md-block">Generic Enquiry</span>
        </button>
      </div>
    </div>
    <hr>
    <div class="row" *ngFor="let item of owner.contactDetails">
      <div class="col col-sm-4 col-md-2  pr-0 mr-0">
        <strong>{{item.type | titlecase}}:</strong>
      </div>
      <div class="col col-sm-6 col-md-10 ">
        {{item.details}}
      </div>
    </div>
    <br>
  <!-- Enquiries, Comments -->
    <ngb-tabset #ownerAdditionals="ngbTabset" [activeId]="meta.selectedTab" (tabChange)="selectTab($event.nextId)">
      <ngb-tab id="enquiries" title="Enquiries" [disabled]="meta.loading">
        <ng-template ngbTabContent>
          <div class="results-list">
            <app-enquiry-search *ngIf="owner.ownerId" [entity]="owner" entityType="owner" (reloaded)="enquiriesReloaded($event)"></app-enquiry-search>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab id="comments" title="Comments" [disabled]="meta.loading">
        <ng-template ngbTabContent class="results-list">
          <app-owner-comments [owner]="owner"></app-owner-comments>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
</div>
