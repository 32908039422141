<app-navbar></app-navbar>
<div *ngIf="meta.loading">
  <div class="row">
    <div class="col-12 col-md-4 offset-md-4 text-center">
      <img src="assets/loader.svg" class="d-block ml-auto mr-auto" alt="spinner">
    </div>
  </div>
</div>
<div *ngIf="!meta.loading">
  <div class="container-fluid">
  <div class="row mb-5 mt-5 align-middle">
    <div class="col-10">
      <h2 class="font-weight-bold">{{jockey.knownAs | uppercase}}</h2>
      <h5>{{jockey.firstName}} {{jockey.surname}}</h5>
    </div>
    <div class="col-2 text-right">
      <button type="button" class="btn btn-primary" (click)="openAddEnquiry(jockey)">
        <span class="d-none d-md-block">Generic Enquiry</span>
      </button>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col col-sm-4 col-md-2 ">
      <strong>Address:</strong>
    </div>
    <div class="col col-sm-6 col-md-10">
      <span *ngIf="jockey.address">{{jockey.address}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col col-sm-4 col-md-2 ">
      <strong>Next of Kin:</strong>
    </div>
    <div class="col col-sm-6 col-md-10">
      <span>{{(jockey.nextOfKin | titlecase) || 'N/A'}}</span>
      <span *ngIf="jockey.nextOfKinPhone"> ({{jockey.nextOfKinPhone}})</span>
    </div>
  </div>
  <div class="row" *ngFor="let item of jockey.contactDetails">
    <div class="col col-sm-4 col-md-2  pr-0 mr-0">
      <strong>{{item.type | titlecase}}:</strong>
    </div>
    <div class="col col-sm-6 col-md-10">
      {{item.details}}
    </div>
  </div>
  <br>
  <!-- Enquiries, Comments -->
  <div class="row">
    <div class="col">
      <ngb-tabset #jockeyAdditionals="ngbTabset" [activeId]="meta.selectedTab" (tabChange)="selectTab($event.nextId)">
        <ngb-tab id="enquiries" title="Enquiries" [disabled]="meta.loading">
          <ng-template ngbTabContent>
            <div class="results-list">
              <app-enquiry-search [entity]="jockey" entityType="jockey" (reloaded)="enquiriesReloaded($event)"></app-enquiry-search>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab id="comments" title="Comments" [disabled]="meta.loading">
          <ng-template ngbTabContent class="results-list">
            <app-jockey-comments [jockey]="jockey"></app-jockey-comments>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
  </div>
</div>
