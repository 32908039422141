import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../../../services/events.service';
import { ModalActionsService } from '../../../../services/modalActions.service';

@Component({
  selector: 'app-entry-sampling-request',
  templateUrl: './entry-sampling-request.component.html',
  styleUrls: ['./entry-sampling-request.component.scss']
})
export class EntrySamplingRequestComponent implements OnInit {

  @Input() entry;
  samplingRequest: any;
  possibleReasons: any[];
  meta: {
    title: string,
    edit: boolean,
    loading: boolean,
    saving: boolean,
    possibleReasons: {
      loading: boolean,
      saving: boolean
    }
  };
  userId: number;

  constructor(
    private activeModal: NgbActiveModal,
    private notification: ToastrService,
    private eventsService: EventsService,
    private modalActionsService: ModalActionsService,
  ) { }

  ngOnInit() {
    console.log('Opening sampling request edit/create', this.entry);
    this.meta = {
      title: this.entry.horseName,
      edit: false,
      loading: false,
      saving: false,
      possibleReasons: {
        loading: false,
        saving: false
      }
    };

    this.eventsService.observableUser.subscribe(data => {
      this.userId = data.id;
    });

    this.loadSamplingRequest(this.entry.samplingId);
  }

  loadSamplingRequest = (samplingId) => {
    this.meta.loading = true;
    if (samplingId) {
      this.meta.edit = true;
      this.modalActionsService.getSamplingRequest(samplingId).subscribe(
        res => {
          console.log('sampling request:', res);
          this.samplingRequest = res;
          this.getReasons();
          this.meta.loading = false;
        },
        err => {
          this.notification.error(`Could't retrieve sampling request information: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
          this.meta.loading = false;
        }
      );
    } else {
      this.samplingRequest = {};
      this.getReasons();
      this.meta.loading = false;
      this.meta.edit = false;
    }
  }

  getReasons = () => {
    this.meta.possibleReasons.loading = true;
      this.modalActionsService.getSampleRequestReasons().subscribe(
        res => {
          if (this.samplingRequest.reasons && this.samplingRequest.reasons.length) {
            this.samplingRequest.reasons = this.samplingRequest.reasons.map(rpc => rpc.id || rpc);
          } else {
            this.samplingRequest.reasons = [];
          }
          this.possibleReasons = res;
          this.meta.possibleReasons.loading = false;
        },
        err => {
          this.notification.error(`Could't retrieve the list of request reasons: ` +
          `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
          this.meta.possibleReasons.loading = false;
        }
      );
  }

  save = () => {
    this.meta.saving = true;
    const payload = {
      samplingId: this.samplingRequest.samplingId || null,
      horseId: this.entry.horseId,
      raceId: this.entry.raceId,
      year: this.entry.year,
      divisionSequence: this.entry.divisionSequence,
      additionalComment: this.samplingRequest.additionalComment || null,
      userId: this.userId
    };
    if (this.samplingRequest.reasons && this.samplingRequest.reasons.length) {
      payload['reasons'] = this.samplingRequest.reasons;
    }
    console.log('saving sampling request', payload);
    let request = this.modalActionsService.createSamplingRequest(payload);
    if (payload.samplingId) {
      request = this.modalActionsService.updateSamplingRequest(payload);
    }
    request.subscribe(
      res => {
        // console.log('Sampling Request saved', res);
        this.notification.success(`Sampling Request saved.`, 'Success!');
        this.meta.saving = false;
        this.activeModal.close(res);
      },
      err => {
        this.notification.error(`Could't save this Sampling Request: ` +
        `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
        this.meta.saving = false;
      }
    );
  }

  remove = () => {
    this.meta.saving = true;
    const payload = {
      samplingId: this.samplingRequest.samplingId || null,
      userId: this.userId
    };
    // console.log('removing sampling request', payload);
    this.modalActionsService.removeSamplingRequest(payload).subscribe(
      res => {
        // console.log('Sampling Request removed', res);
        this.notification.success(`Sampling Request removed.`, 'Success!');
        this.meta.saving = false;
        this.activeModal.close(res);
      },
      err => {
        this.notification.error(`Could't remove this Sampling Request: ` +
        `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
        this.meta.saving = false;
      }
    );
  }

  cancel = (action) => {
    this.activeModal.dismiss(action);
  }

}
