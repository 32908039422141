<div class="modal-header d-flex justify-content-center">
  <h4 class="modal-title">JOCKEY CHANGE HISTORY FOR {{ meta.title | uppercase }}</h4>
</div>
<div class="modal-body" *ngIf="meta.loading">
  <div class="row">
    <div class="col text-center">
      <fa name="circle-o-notch" size="2x" animation="spin"></fa>
    </div>
  </div>
</div>
<div class="modal-body" [ngClass]="{ 'scrollable-fullpage': meta.viewMode === 'view' }" *ngIf="!meta.loading">
  <div class="row">
    <div class="offset-3 col-6">
      <ul class="timeline">
        <li *ngFor="let item of jockeyChanges">
          <a *ngIf="item.newJockeyId" [routerLink]="['/jockeys', item.newJockeyId]"><span>{{item.newJockeyKnownAs || 'Not Available'}}</span></a>
          <a *ngIf="!item.newJockeyId"><span>Not Available</span></a>
          <span *ngIf="item.updatedAt" class="float-right">{{item.updatedAt | date:'dd MMM, yyyy - h:mm'}}</span>
          <br>
          <i>{{item.comment || 'No Comment Available'}}</i>
          <div>
            <button *ngIf="!item.enquiryCreated" (click)="openAddEnquiry(item.newJockeyId, item.newJockeyKnownAs)" class="btn btn-primary" [disabled]="!item.newJockeyId">Open Enquiry</button>
          </div>
          <small *ngIf="item.enquiryCreated">Enquiry Created successfully.<br>Check the Jockey's profile for details</small>
        </li>
      </ul>
    </div>
  </div>
</div>
<div *ngIf="!meta.saving" class="modal-footer d-flex justify-content-around">
  <button type="button" class="btn btn-outline-dark" *ngIf="meta.viewMode === 'modal'" (click)="cancel('cancel')">Close</button>
</div>
