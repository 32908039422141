/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./enquiry-outcomes.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "./enquiry-outcomes.component";
var styles_EnquiryOutcomesComponent = [i0.styles];
var RenderType_EnquiryOutcomesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EnquiryOutcomesComponent, data: {} });
export { RenderType_EnquiryOutcomesComponent as RenderType_EnquiryOutcomesComponent };
function View_EnquiryOutcomesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [", "]))], null, null); }
function View_EnquiryOutcomesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "font-weight-bold": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnquiryOutcomesComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.detailed); _ck(_v, 1, 0, currVal_0); var currVal_2 = !_v.context.last; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 4, 0, currVal_1); }); }
function View_EnquiryOutcomesComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Not Specified"]))], null, null); }
function View_EnquiryOutcomesComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [", "]))], null, null); }
function View_EnquiryOutcomesComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnquiryOutcomesComponent_8)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = !_v.context.last; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.context.$implicit.date, "dd/MM/yyyy")); _ck(_v, 1, 0, currVal_0); }); }
function View_EnquiryOutcomesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Suspension Dates: "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnquiryOutcomesComponent_6)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnquiryOutcomesComponent_7)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.suspendedDates.length; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.suspendedDates; _ck(_v, 6, 0, currVal_1); }, null); }
function View_EnquiryOutcomesComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Not Specified"]))], null, null); }
function View_EnquiryOutcomesComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["From ", ""])), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" - To ", ""])), i1.ɵppd(6, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _co.suspendedDates[0].date, "dd/MM/yyyy")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _co.suspendedDates[1].date, "dd/MM/yyyy")); _ck(_v, 5, 0, currVal_1); }); }
function View_EnquiryOutcomesComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnquiryOutcomesComponent_10)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnquiryOutcomesComponent_11)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.suspendedDates.length; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.suspendedDates.length; _ck(_v, 4, 0, currVal_1); }, null); }
function View_EnquiryOutcomesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnquiryOutcomesComponent_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnquiryOutcomesComponent_9)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.suspensionPeriod === "Days"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.suspensionPeriod !== "Days"); _ck(_v, 4, 0, currVal_1); }, null); }
function View_EnquiryOutcomesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "d-flex flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnquiryOutcomesComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.suspendedDates; _ck(_v, 2, 0, currVal_0); }, null); }
function View_EnquiryOutcomesComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Not Specified"]))], null, null); }
function View_EnquiryOutcomesComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [", "]))], null, null); }
function View_EnquiryOutcomesComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnquiryOutcomesComponent_17)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = !_v.context.last; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.context.$implicit.date, "dd/MM/yyyy")); _ck(_v, 1, 0, currVal_0); }); }
function View_EnquiryOutcomesComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Suspension Days"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnquiryOutcomesComponent_15)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnquiryOutcomesComponent_16)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.suspendedDates.length; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.suspendedDates; _ck(_v, 7, 0, currVal_1); }, null); }
function View_EnquiryOutcomesComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Not Specified"]))], null, null); }
function View_EnquiryOutcomesComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Suspended from ", ""])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _co.suspendedDates[0].date, "dd/MM/yyyy")); _ck(_v, 1, 0, currVal_0); }); }
function View_EnquiryOutcomesComponent_21(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" to ", ""])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _co.suspendedDates[1].date, "dd/MM/yyyy")); _ck(_v, 1, 0, currVal_0); }); }
function View_EnquiryOutcomesComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnquiryOutcomesComponent_19)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnquiryOutcomesComponent_20)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnquiryOutcomesComponent_21)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.suspendedDates.length; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.suspendedDates.length; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.suspendedDates.length; _ck(_v, 6, 0, currVal_2); }, null); }
function View_EnquiryOutcomesComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnquiryOutcomesComponent_14)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnquiryOutcomesComponent_18)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.suspensionPeriod === "Days"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.suspensionPeriod !== "Days"); _ck(_v, 4, 0, currVal_1); }, null); }
function View_EnquiryOutcomesComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnquiryOutcomesComponent_13)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.suspendedDates; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EnquiryOutcomesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "d-flex flex-row"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "justify-content-center": 0, "justify-content-start": 1 }), (_l()(), i1.ɵeld(4, 16777216, null, null, 3, "div", [["class", "flex-shrink-1"], ["container", "body"], ["placement", "top"]], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i3.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i3.NgbTooltipConfig, i1.NgZone, i2.DOCUMENT, i1.ChangeDetectorRef, i1.ApplicationRef], { placement: [0, "placement"], container: [1, "container"], ngbTooltip: [2, "ngbTooltip"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnquiryOutcomesComponent_1)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnquiryOutcomesComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["suspensionDatesTooltip", 2]], null, 0, null, View_EnquiryOutcomesComponent_12))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "d-flex flex-row"; var currVal_1 = _ck(_v, 3, 0, _co.detailed, !_co.detailed); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "top"; var currVal_3 = "body"; var currVal_4 = ((!_co.detailed && _co.suspendedDates) ? i1.ɵnov(_v, 10) : null); _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.processedOutcomes; _ck(_v, 7, 0, currVal_5); var currVal_6 = _co.detailed; _ck(_v, 9, 0, currVal_6); }, null); }
export function View_EnquiryOutcomesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-enquiry-outcomes", [], null, null, null, View_EnquiryOutcomesComponent_0, RenderType_EnquiryOutcomesComponent)), i1.ɵdid(1, 114688, null, 0, i4.EnquiryOutcomesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EnquiryOutcomesComponentNgFactory = i1.ɵccf("app-enquiry-outcomes", i4.EnquiryOutcomesComponent, View_EnquiryOutcomesComponent_Host_0, { enquiry: "enquiry", detailed: "detailed" }, {}, []);
export { EnquiryOutcomesComponentNgFactory as EnquiryOutcomesComponentNgFactory };
