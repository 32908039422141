import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../../../services/events.service';
import { ModalActionsService } from '../../../../services/modalActions.service';

@Component({
  selector: 'app-fixture-update-officials',
  templateUrl: './fixture-update-officials.component.html',
  styleUrls: ['./fixture-update-officials.component.scss']
})
export class FixtureUpdateOfficialsComponent implements OnInit {

  @Input() fixture;
  meta: {
    loading: boolean,
    saving: boolean
  };
  officialsText: string;
  userId: number;

  constructor(
    private activeModal: NgbActiveModal,
    private notification: ToastrService,
    private eventsService: EventsService,
    private modalActionsService: ModalActionsService,
  ) { }

  ngOnInit() {
    this.meta = {
      loading: false,
      saving: false
    };

    this.officialsText = this.fixture.officials.join(', ');

    this.eventsService.observableUser.subscribe(data => {
      this.userId = data.id;
    });
  }

  confirm = () => {
    this.meta.saving = true;
    this.modalActionsService
    .updateFixture(this.fixture, {officials: this.officialsText.split(',').map(o => o.trim()), userId: this.userId})
    .subscribe(
      res => {
        this.activeModal.close(res);
        this.notification.success(`${this.fixture.courseName} officials has been updated.`, 'Success!');
        this.meta.saving = false;
      },
      err => {
        this.notification.error(`Could't update the fixture: ` +
        `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
        this.meta.saving = false;
      }
    );
  }

  cancel = (action) => {
    this.activeModal.dismiss(action);
  }

}
