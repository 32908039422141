import { Component, OnInit, Input } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { HorsesService } from '../../../services/horses.service';

import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-horse-previous-forms',
  templateUrl: './horse-previous-forms.component.html',
  styleUrls: ['./horse-previous-forms.component.scss']
})
export class HorsePreviousFormsComponent implements OnInit {

  @Input() horse;
  meta: {
    loading: boolean,
    saving: boolean,
    results: {
      currentPage: number,
      pageSize: number,
      lastPage: number,
      totalResult: number
    }
  };

  constructor(
    private notification: ToastrService,
    private horsesService: HorsesService,
    private eventsService: EventsService
  ) { }

  ngOnInit() {
    this.meta = {
      loading: false,
      saving: false,
      results: {
        currentPage: null,
        pageSize: null,
        lastPage: null,
        totalResult: null
      }
    };

    this.loadResults(this.horse.horseId);
  }

  loadResults = (horseId, page = 1) => {
    this.meta.loading = true;
    this.horse.previousForm = [];
    this.horsesService.getHorsePreviousForms({horseId: horseId}, page).subscribe(
      res => {
        this.horse.previousForm = res.data;
        this.meta.results = {
          currentPage: res.current_page,
          pageSize: res.per_page,
          lastPage: res.last_page,
          totalResult: res.total
        };
        this.meta.loading = false;
      },
      err => {
        this.notification.error(`Could't get the Horse Previous Forms: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.loading = false;
      }
    );
  }

}
