import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../../../services/events.service';
import { ModalActionsService } from '../../../../services/modalActions.service';

@Component({
  selector: 'app-entry-withdrawn',
  templateUrl: './entry-withdrawn.component.html',
  styleUrls: ['./entry-withdrawn.component.scss']
})
export class EntryWithdrawnComponent implements OnInit {

  @Input() entry;
  withdrawnInfo: any;
  possibleReasons: any[];
  declared: {
    date: {
      year: number,
      month: number,
      day: number
    },
    time: {
      hour: number,
      minute: number
    }
  };
  meta: {
    title: string,
    loading: boolean,
    saving: boolean,
    possibleReasons: {
      loading: boolean,
      saving: boolean
    }
  };
  userId: number;

  constructor(
    private activeModal: NgbActiveModal,
    private notification: ToastrService,
    private eventsService: EventsService,
    private modalActionsService: ModalActionsService,
  ) { }

  ngOnInit() {
    console.log('Opening withdrawn modal', this.entry);
    this.meta = {
      title: this.entry.horseName,
      loading: false,
      saving: false,
      possibleReasons: {
        loading: false,
        saving: false
      }
    };

    this.eventsService.observableUser.subscribe(data => {
      this.userId = data.id;
    });

    this.getReasons();
  }

  getReasons = () => {
    this.meta.loading = true;
    this.meta.possibleReasons.loading = true;
    this.modalActionsService.getWithdrawnReasons().subscribe(
      res => {
        this.possibleReasons = res;
        this.loadWithdrawnInfo(this.entry);
        this.meta.possibleReasons.loading = false;
      },
      err => {
        this.notification.error(`Could't retrieve the list of reasons: ` +
        `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
        this.meta.possibleReasons.loading = false;
        this.meta.loading = false;
      }
    );
  }

  loadWithdrawnInfo = (entry) => {
    this.meta.loading = true;
    // Instance object as if there isn't already one in the system
    const reasonFound = this.possibleReasons.find(r => r.reason === this.entry.withdrawnDeclaredReason);
    this.withdrawnInfo = {
      year: this.entry.year,
      raceId: this.entry.raceId,
      divisionSequence: this.entry.divisionSequence,
      horseId: this.entry.horseId,
      declaredAt: this.entry.withdrawnDeclaredDate ? this.entry.withdrawnDeclaredDate + ' ' + this.entry.withdrawnDeclaredTime : null,
      reason: reasonFound ? reasonFound.id : null,
      comment: null,
      internalComment: null
    };
    // Build date/time models for UI
    if (this.withdrawnInfo.declaredAt) {
      this.declared = {
        date: {
          year: moment(this.entry.withdrawnDeclaredDate).year(),
          month: moment(this.entry.withdrawnDeclaredDate).month() + 1,
          day: moment(this.entry.withdrawnDeclaredDate).date(),
        },
        time: {
          hour: moment(this.entry.withdrawnDeclaredTime, 'HH:mm:ss').hours(),
          minute: moment(this.entry.withdrawnDeclaredTime, 'HH:mm:ss').minutes(),
        }
      };
    }
    this.modalActionsService.getWithdrawnInfo(entry).subscribe(
      res => {
        // If there is a response, overwrite the object with the data received
        if (res['id']) {
          this.withdrawnInfo = res;
        }
        if (res['declaredAt']) {
          this.withdrawnInfo.declaredAt = res['declaredAt'];
          // Rebuild date/time models for UI
          this.declared = {
            date: {
              year: moment(res['declaredAt']).year(),
              month: moment(res['declaredAt']).month() + 1,
              day: moment(res['declaredAt']).date(),
            },
            time: {
              hour: moment(res['declaredAt']).hour(),
              minute: moment(res['declaredAt']).minute(),
            }
          };
        }
        this.withdrawnInfo.userId = this.userId;
        this.meta.loading = false;
      },
      err => {
        this.notification.error(`Could't retrieve withdrawn information: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.loading = false;
      }
    );
  }

  save = () => {
    this.meta.saving = true;
    const declaredDate = Object.assign({}, this.declared.date);
    declaredDate.month -= 1;
    this.withdrawnInfo.declaredAt = moment(declaredDate).format('YYYY-MM-DD') + ' ' + moment(this.declared.time).format('HH:mm:ss');
    console.log('saving withdrawn request', this.withdrawnInfo);
    this.modalActionsService.updateWithdrawnInfo(this.withdrawnInfo).subscribe(
      res => {
        console.log('non runner info saved', res);
        this.eventsService.changeRacecardData({section: 'todoList', context: 'withdrawn', result: res});
        this.notification.success(`Withdrawn information saved.`, 'Success!');
        this.activeModal.close(res);
        this.meta.saving = false;
      },
      err => {
        this.notification.error(`Could't save Withdrawn information: ` +
        `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
        this.meta.saving = false;
      }
    );
  }

  cancel = (action) => {
    this.activeModal.dismiss(action);
  }

  // Force datepicker to close if click outside of it
  datepickerFix = (event, datePicker) => {
    if (event.target.offsetParent == null) {
      datePicker.close();
    } else if (event.target.offsetParent.nodeName !== 'NGB-DATEPICKER') {
      datePicker.close();
         }
  }

}
