import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../../../services/events.service';
import { ModalActionsService } from '../../../../services/modalActions.service';

@Component({
  selector: 'app-entry-non-runner',
  templateUrl: './entry-non-runner.component.html',
  styleUrls: ['./entry-non-runner.component.scss']
})
export class EntryNonRunnerComponent implements OnInit {

  @Input() entry;
  nonRunnerInfo: any;
  possibleReasons: any[];
  declared: {
    date: {
      year: number,
      month: number,
      day: number
    },
    time: {
      hour: number,
      minute: number
    }
  };
  meta: {
    title: string,
    loading: boolean,
    saving: boolean,
    possibleReasons: {
      loading: boolean,
      saving: boolean
    }
  };
  userId: number;
  typeOfPerson: any[] = [
    {type: 'Trainer'},
    {type: 'Owner'},
  ];
  owner: any;

  penaltyReasons = [
    {id: 0, reason: 'Waived'},
    {id: 1, reason: 'Fixed'},
  ];

  constructor(
    private activeModal: NgbActiveModal,
    private notification: ToastrService,
    private eventsService: EventsService,
    private modalActionsService: ModalActionsService,
  ) { }

  ngOnInit() {
    console.log('Opening non runner modal', this.entry);

    this.meta = {
      title: this.entry.horseName,
      loading: false,
      saving: false,
      possibleReasons: {
        loading: false,
        saving: false
      }
    };

    this.eventsService.observableUser.subscribe(data => {
      this.userId = data.id;
    });

    this.getReasons();
  }

  getReasons = () => {
    this.meta.loading = true;
    this.meta.possibleReasons.loading = true;
    this.modalActionsService.getNonRunnerReasons().subscribe(
      res => {
        this.possibleReasons = res;
        this.loadNonRunnerInfo(this.entry);
        this.meta.possibleReasons.loading = false;
      },
      err => {
        this.notification.error(`Could't retrieve the list of reasons: ` +
        `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
        this.meta.possibleReasons.loading = false;
        this.meta.loading = false;
      }
    );
  }

  loadNonRunnerInfo = (entry) => {
    this.meta.loading = true;
    // Instance object as if there isn't already one in the system
    const reasonFound = this.possibleReasons.find(r => r.reason === this.entry.nonRunnerDeclaredReason);
    this.nonRunnerInfo = {
      year: this.entry.year,
      raceId: this.entry.raceId,
      divisionSequence: this.entry.divisionSequence,
      horseId: this.entry.horseId,
      penalty: 0,
      declaredAt: this.entry.nonRunnerDeclaredDate + ' ' + this.entry.nonRunnerDeclaredTime,
      reasonId: reasonFound ? reasonFound.id : null,
      comment: null,
      internalComment: null,
      penaltyFine: 140,
      typeOfPerson: 'Trainer',
    };
    // Build date/time models for UI
    this.declared = {
      date: {
        year: moment(this.entry.nonRunnerDeclaredDate).year(),
        month: moment(this.entry.nonRunnerDeclaredDate).month() + 1,
        day: moment(this.entry.nonRunnerDeclaredDate).date(),
      },
      time: {
        hour: moment(this.entry.nonRunnerDeclaredTime, 'HH:mm:ss').hours(),
        minute: moment(this.entry.nonRunnerDeclaredTime, 'HH:mm:ss').minutes(),
      }
    };
    this.modalActionsService.getNonRunnerInfo(entry).subscribe(
      res => {
        // If there is a response, overwrite the object with the data received
        if (res['id']) {
          this.nonRunnerInfo = res;
          // Rebuild date/time models for UI
          this.declared = {
            date: {
              year: moment(res['declaredAt']).year(),
              month: moment(res['declaredAt']).month() + 1,
              day: moment(res['declaredAt']).date(),
            },
            time: {
              hour: moment(res['declaredAt']).hour(),
              minute: moment(res['declaredAt']).minute(),
            }
          };
        }
        this.nonRunnerInfo.userId = this.userId;
        this.meta.loading = false;
        if (this.nonRunnerInfo.penaltyFine === null) {
          this.nonRunnerInfo.penaltyFine = 140;
        }
        if (this.nonRunnerInfo.typeOfPerson === null) {
          this.nonRunnerInfo.typeOfPerson = 'Trainer';
        }
      },
      err => {
        this.notification.error(`Could't retrieve non-runner information: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.loading = false;
      }
    );
  }

  save = () => {
    this.meta.saving = true;
    if (this.nonRunnerInfo.penalty === 0) {
      this.nonRunnerInfo.penaltyFine = null;
      this.nonRunnerInfo.typeOfPerson = null;
    }
    const declaredDate = Object.assign({}, this.declared.date);
    declaredDate.month -= 1;
    this.nonRunnerInfo.declaredAt = moment(declaredDate).format('YYYY-MM-DD') + ' ' + moment(this.declared.time).format('HH:mm:ss');
    console.log('saving sampling request', this.nonRunnerInfo);
    this.modalActionsService.updateNonRunnerInfo(this.nonRunnerInfo).subscribe(
      res => {
        console.log('non runner info saved', res);
        this.eventsService.changeRacecardData({section: 'todoList', context: 'nonRunner', result: res});
        this.notification.success(`Non-Runner information saved.`, 'Success!');
        this.activeModal.close(res);
        this.meta.saving = false;
      },
      err => {
        this.notification.error(`Could't save Non-Runner information: ` +
        `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
        this.meta.saving = false;
      }
    );
  }

  cancel = (action) => {
    this.activeModal.dismiss(action);
  }

  // Force datepicker to close if click outside of it
  datepickerFix = (event, datePicker) => {
    if (event.target.offsetParent == null) {
      datePicker.close();
    } else if (event.target.offsetParent.nodeName !== 'NGB-DATEPICKER') {
      datePicker.close();
         }
  }

}
