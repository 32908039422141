<form name="form" #formComment="ngForm" (ngSubmit)="formComment.form.valid && save()">
  <div class="modal-header d-flex justify-content-center">
    <h4 class="modal-title text-truncate">{{meta.edit ? 'EDIT' : 'CREATE'}} COMMENT {{meta.title | uppercase}}</h4>
  </div>
  <div class="modal-body" *ngIf="meta.loading">
    <div class="row">
      <div class="col text-center">
        <fa name="circle-o-notch" size="2x" animation="spin"></fa>
      </div>
    </div>
  </div>
  <div class="modal-body" *ngIf="!meta.loading">
    <div class="row" *ngIf="possibleSubjects">
      <div class="col text-center">
        <div class="form-group">
          <label for="commentSubject">Select a Subject</label>
          <select
            [disabled]="comment.id"
            class="custom-select"
            [ngClass]="{ 'is-invalid': formComment.submitted && commentSubject.invalid }"
            name="commentSubject"
            #commentSubject="ngModel"
            [(ngModel)] = "comment.subject"
            (ngModelChange)="prepareModal()"
            required>
              <option *ngFor="let subject of possibleSubjects" [ngValue]="subject" [selected]="subject.id === comment.subject.id">{{subject.type | titlecase}} - {{subject.knownAs}}</option>
          </select>
          <div *ngIf="formComment.submitted && commentSubject.invalid">
            <div class="alert alert-danger" *ngIf="commentSubject.errors.required">A subject is required</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="comment.subject.type === 'horse'">
        <div class="col">
          <div class="form-group">
            <label>Race Performances Comments</label>
            <ng-select name="rpcComments"
              [items]="possibleRpcComments"
              [loading]="meta.possibleRpcComments.loading"
               bindLabel="racePerformanceComment"
               bindValue="id"
               [multiple]="true"
               [closeOnSelect]="true"
               placeholder="[Optional] Select one or more RPC"
               [(ngModel)]="comment.rpcComments">
          </ng-select>
          </div>
        </div>
      </div>
    <div class="row">
      <div class="col text-center">
        <div class="form-group">
          <label for="commentText">Comment</label>
          <textarea
            class="form-control"
            [ngClass]="{'textarea-bigger': comment.subject.type !== 'horse'}"
            name="commentText"
            [(ngModel)]="comment.comment"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="comment.subject.type === 'race' || comment.subject.type === 'fixture'">
      <div class="col text-center">
        <div class="form-group">
          <label for="breachValue">Published Status</label><br>
            <div class="btn-group btn-group-toggle" ngbRadioGroup name="publishedStatus" id="publishedStatus" [(ngModel)]="comment.published">
              <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': !comment.published}">
                <input ngbButton type="radio" [value]="false"> Not Published
              </label>
              <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': comment.published}">
                <input ngbButton type="radio" [value]="true"> Published
              </label>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!meta.saving" class="modal-footer d-flex justify-content-around">
    <button type="button" class="btn btn-outline-dark" (click)="cancel('cancel')">Close</button>
    <button type="submit" class="btn btn-primary" [disabled]="!comment.comment && !comment.rpcComments">Save</button>
  </div>
  <div *ngIf="meta.saving" class="modal-footer d-flex justify-content-around">
    <h1>
      <fa name="circle-o-notch" animation="spin"></fa>
    </h1>
  </div>
</form>
