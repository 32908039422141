<div class="results-list">
  <div class="col text-center pt-5" *ngIf="meta.loading">
    <img src="assets/loader.svg" class="d-block ml-auto mr-auto" alt="spinner">
    <br>Loading...
  </div>
  <div *ngIf="!meta.loading">
    <div class="row">
      <div class="col">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Comment</th>
              <th scope="col">Race</th>
            </tr>
          </thead>
          <tbody *ngIf="horse.comments.length">
            <tr *ngFor="let hc of horse.comments">
              <td><small><a [routerLink] (click)="openComment(hc.id)">{{hc.dateTime | date:'dd/MM/yy'}}</a></small></td>
              <td class="text-justify">
                <small *ngIf="hc.comment">{{hc.comment | slice:0:225}}<span *ngIf="hc.comment.length > 225">...</span></small>
                <small *ngIf="hc.rpcComments">{{hc.rpcComments | slice:0:225}}<span *ngIf="hc.rpcComments.length > 225">...</span></small>
              </td>
              <td><a [routerLink]="['/races', hc.year, hc.fixtureId, hc.raceId, hc.divisionSequence]">{{hc.dateTime | date:'dd/MM/yy h:mm'}} - {{hc.courseNameShort}}</a></td>
            </tr>
          </tbody>
          <tbody *ngIf="!horse.comments.length">
            <tr class="text-center">
              <td class="p-5" colspan="2">No Comments found for this horse</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col text-center">
    <ngb-pagination *ngIf="meta.results.lastPage > 1" class="d-flex justify-content-center" [collectionSize]="meta.results.totalResult" [pageSize]="meta.results.pageSize" [rotate]="true" [maxSize]="3" [(page)]="meta.results.currentPage" (pageChange)="loadResults(horse.horseId, $event)" aria-label="pagination"></ngb-pagination>
  </div>
</div>
