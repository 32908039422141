import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  @Input() error;
  stack: string;
  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    // this.stack = this.error.stack.replace(new RegExp(' at ', 'g'), '<br>at ');
    this.stack = this.error.stack;
  }

  cancel = (action) => {
    this.activeModal.dismiss(action);
  }

}
