import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestOptions } from '@angular/http';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';

@Injectable()
export class HorsesService {

  defaultHeaders: HttpHeaders;
  constructor(
    private http: HttpClient,
    private auth: AuthenticationService
  ) { }

  getHorseInfo(horse, headers = this.auth.getHeaders()): any {
    return this.http.get(`${environment.apiBase}/v2/horses/${horse.horseId}`, {headers});
  }

  getHorsePreviousForms(horse, page = 1, headers = this.auth.getHeaders()): any {
    return this.http.get(`${environment.apiBase}/v2/horses/${horse.horseId}/previous-form?perPage=8&page=${page}`, {headers});
  }

  getHorseComments(horse, page = 1, headers = this.auth.getHeaders()): any {
    return this.http.get(`${environment.apiBase}/v2/comments?horseId=${horse.horseId}&perPage=8&page=${page}`, {headers});
  }

  getEnquiries(id, filters = null, page = 1, headers = this.auth.getHeaders()): any {
    let requestUrl = `${environment.apiBase}/v2/enquiries?horseId=${id}&perPage=5&page=${page}`;
    if (filters && filters.fromDate) {
      requestUrl += `&fromDate=${filters.fromDate}`;
    }
    if (filters && filters.toDate) {
      requestUrl += `&toDate=${filters.toDate}`;
    }
    if (filters && filters.courseIds.length) {
      requestUrl += '&' + filters.courseIds.map(el => 'courseIds[]=' + el).join('&');
    }
    if (filters && filters.horseIds.length) {
      requestUrl += '&' + filters.horseIds.map(el => 'horseIds[]=' + el).join('&');
    }
    if (filters && filters.outcomes.length) {
      requestUrl += '&' + filters.outcomes.map(el => 'outcomes[]=' + el).join('&');
    }
    if (filters && filters.penalties.length) {
      requestUrl += '&' + filters.penalties.map(el => 'penalties[]=' + el).join('&');
    }
    if (filters && filters.offenceIds.length) {
      requestUrl += '&' + filters.offenceIds.map(el => 'offenceIds[]=' + el).join('&');
    }
    if (filters && filters.subOffences.length) {
      requestUrl += '&' + filters.subOffences.map(el => 'subOffences[]=' + el).join('&');
    }
    return this.http.get(requestUrl, {headers});
  }

  getFilters(id, type, fromDate, toDate, headers = this.auth.getHeaders()) {
    return this.http.get(`${environment.apiBase}/v2/lists/enquiry-filters` +
    `?entityId=${id}&entityType=${type}&fromDate=${fromDate}&toDate=${toDate}`, {headers});
  }
}
