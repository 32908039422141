<form name="form" #formLateRace="ngForm">
  <div class="modal-header d-flex justify-content-center">
    <h4 class="modal-title">LATE RACE</h4>
  </div>
  <div class="modal-body">
    <div *ngIf="meta.loading">
      <div class="row">
        <div class="col-12">
          <img src="assets/loader.svg" class="d-block ml-auto mr-auto" alt="spinner">
        </div>
      </div>
    </div>
    <div  *ngIf="!meta.loading">
      <div class="row" *ngIf="lateRaces.reason1">
        <div class="col">
          <div class="late-race-list">
            <label class="font-weight-bold d-inline">Advertised Time of Race</label>
            <p class="d-inline float-right">{{lateRaces.publishedRaceTime | racetime}}</p>
          </div>
          <div class="late-race-list">
            <label class="font-weight-bold d-inline">Actual Start Time of Race</label>
            <p class="d-inline float-right">{{lateRaces.actualRaceTime | time}}</p>
          </div>
          <div class="late-race-list">
            <label class="font-weight-bold d-inline">Time difference in minutes</label>
            <p class="d-inline float-right">{{lateRaces.diffInMinutes}} mins</p>
          </div>
          <div class="late-race-list">
            <label class="font-weight-bold d-inline">Reason for Race Starting Late </label>
            <p class="d-inline float-right">{{lateRaces.reason1}}</p>
          </div>
          <div class="late-race-list" *ngIf="lateRaces.reason2">
            <label class="font-weight-bold d-inline">Additional Reason</label>
            <p class="d-inline float-right">{{lateRaces.reason2}}</p>
          </div>
          <div class="late-race-list" *ngIf="lateRaces.reason3">
            <label class="font-weight-bold d-inline">Additional Reason</label>
            <p class="d-inline float-right">{{lateRaces.reason3}}</p>
          </div>
          <div class="late-race-list">
            <label class="font-weight-bold d-inline">Further comments</label>
            <p class="d-inline float-right">{{lateRaces.action}}</p>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!lateRaces.reason1">
        <div class="col">
          <div class="form-group">
            <label class="font-weight-bold">Actual Start Time of Race (HH:MM:SS): *</label>
            <ngb-timepicker name="actualRaceTime" [(ngModel)]="time" (ngModelChange)="onTimeChange()" [seconds]="true" [required]="true" [meridian]="true"></ngb-timepicker>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">Reason for Race Starting Late *</label>
            <select class="form-control" name="reason1" [(ngModel)]="reason1" (ngModelChange)="onReason1Change()" required>
              <option [value]="reason.reason" *ngFor="let reason of selectFromReasons">{{reason.reason}}</option>
            </select>
          </div>
          <!-- IF REASON 1 HAS ANOTHER REASON 2 SELECT-->
          <div class="form-group" [hidden]="!reason1 || reason1 == 'Unruly/Loose horse' || reason1 == 'Television/Broadcast delay request' || reason1 == 'Avoiding Clash'|| reason1 == 'Other' || reason1 == 'Raceday Equipment Failure' || reason1 == 'Knock on from previous late race' || reason1 == 'Official delay request (due to abandonment elsewhere)'">
            <label class="font-weight-bold">Reason for {{reason1}}:</label>
            <select class="form-control" name="reason2" [(ngModel)]="reason2" [required]="reason1 == 'Weather' || reason1 == 'Ambulance Issue' || reason1 == 'Track Issue' || reason1 == 'Loading/Starting Issues' || reason1 == 'Welfare Issues' || reason1 == 'Equipment/Shoeing' || reason1 == 'Late to post'">
              <ng-container *ngIf="reason1 == 'Ambulance Issue'" >
                <option [value]="ambulance.reason" *ngFor="let ambulance of ambulanceIssues">{{ambulance.reason}}</option>
              </ng-container>
              <ng-container *ngIf="reason1 == 'Weather'">
                <option [value]="weather.reason" *ngFor="let weather of weatherIssues">{{weather.reason}}</option>
              </ng-container>
              <ng-container *ngIf="reason1 == 'Track Issue'">
                <option [value]="track.reason" *ngFor="let track of trackIssues">{{track.reason}}</option>
              </ng-container>
              <ng-container *ngIf="reason1 == 'Loading/Starting Issues'">
                <option [value]="loading.reason" *ngFor="let loading of loadingStartingIssues">{{loading.reason}}</option>
              </ng-container>
              <ng-container *ngIf="reason1 == 'Welfare Issues'">
                <option [value]="welfare.reason" *ngFor="let welfare of WelfareIssues">{{welfare.reason}}</option>
              </ng-container>
              <ng-container *ngIf="reason1 == 'Equipment/Shoeing'">
                <option [value]="equipment.reason" *ngFor="let equipment of EquipmentIssues">{{equipment.reason}}</option>
              </ng-container>
              <ng-container *ngIf="reason1 == 'Late to post'">
                <option [value]="late.reason" *ngFor="let late of LateToPostIssues">{{late.reason}}</option>
              </ng-container>
            </select>
          </div>
          <!-- IF OTHER IS SELECTED FROM REASON 1 -->
          <div class="form-group" *ngIf="reason1 == 'Other' || reason1 == 'Raceday Equipment Failure'">
            <label class="font-weight-bold">Additional information for  {{reason1}} *</label>
            <textarea class="form-control" name="reason2b" [(ngModel)]="reason2" [required]="reason1 == 'Other' || reason1 == 'Raceday Equipment Failure'"></textarea>
          </div>
          <!-- ELSE OTHER IS SELECTED FROM REASON 2 -->
          <div class="form-group" *ngIf="reason2 == 'Other' || reason2 == 'Incident in previous race'">
            <label class="font-weight-bold">Additional information for {{reason1}} -> {{reason2}} *</label>
            <textarea class="form-control" name="reason3" [(ngModel)]="reason3" [required]="reason2 == 'Other' || reason2 == 'Incident in previous race'"></textarea>
          </div>
          <!-- ACTIONS -->
          <div class="form-group">
            <label class="font-weight-bold">Further comments:</label>
            <textarea class="form-control" name="action" [(ngModel)]="action"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!meta.loading">
    <div *ngIf="!meta.saving" class="modal-footer d-flex justify-content-around">
      <button type="button" class="btn btn-outline-dark" (click)="cancel('cancel')">Close</button>
      <button type="button" class="btn btn-primary" (click)="confirm()" [disabled]="formLateRace.invalid" [hidden]="lateRaces?.reason1">Confirm</button>
      <!--    <button type="button" class="btn btn-primary" (click)="editLateRace()">edit</button>-->
      <button type="button" class="btn btn-danger" (click)="deleteRace()" *ngIf="lateRaces?.reason1">Delete</button>
    </div>
    <div *ngIf="meta.saving" class="modal-footer d-flex justify-content-around">
      <h1>
        <fa name="circle-o-notch" animation="spin"></fa>
      </h1>
    </div>
  </div>
</form>
