<app-navbar></app-navbar>
<div *ngIf="meta.loading">
  <div class="row">
    <div class="col-12 col-md-4 offset-md-4 text-center">
      <img src="assets/loader.svg" class="d-block ml-auto mr-auto" alt="spinner">
    </div>
  </div>
</div>
<div *ngIf="!meta.loading">
  <div class="container-fluid">
  <div class="row mb-5 mt-5 align-middle">
    <div class="col-10">
      <h2 class="font-weight-bold">{{trainer.knownAs | uppercase}}</h2>
      <h5>{{trainer.firstName}} {{trainer.surname}}</h5>
      <h5>{{trainer.contactDetails?.code}}</h5>
    </div>
    <div class="col-2 text-right">
      <button type="button" class="btn btn-primary" (click)="openAddEnquiry(trainer)">
        <span class="d-none d-md-block">Generic Enquiry</span>
      </button>
    </div>
  </div>
  <hr>
  <div class="row" *ngFor="let item of trainer.contactDetails">
    <div class="w-100"  *ngIf="item.type == 'Email' || item.type == 'SMS E-Mail'">
      <div class="col col-sm-4 col-md-2  pr-0 mr-0 d-inline-block" >
        <strong>{{item.type | titlecase}}:</strong>
      </div>
      <a href="mailto: {{item.details}}" target="_blank" class="col col-sm-6 col-md-10">{{item.details}}</a>
    </div>
    <div class="w-100" *ngIf="item.type == 'Web Site'">
      <div class="col col-sm-4 col-md-2  pr-0 mr-0 d-inline-block" >
        <strong>{{item.type | titlecase}}:</strong>
      </div>
      <a href="http://{{item.details}}" target="_blank" class="col col-sm-6 col-md-10">{{item.details}}</a>
    </div>

    <div class="w-100" [hidden]="item.type == 'Web Site' || item.type == 'Email' || item.type == 'SMS E-Mail'">
      <div class="col col-sm-4 col-md-2  pr-0 mr-0 d-inline-block" >
        <strong>{{item.type | titlecase}}:</strong>
      </div>
      <div class="col col-sm-6 col-md-10 d-inline-block">
        {{item.details}}
      </div>
    </div>
  </div>

  <br>
  <!-- Enquiries, Comments -->
  <div class="row">
    <div class="col">
      <ngb-tabset #trainerAdditionals="ngbTabset" [activeId]="meta.selectedTab" (tabChange)="selectTab($event.nextId)">
        <ngb-tab id="enquiries" title="Enquiries" [disabled]="meta.loading">
          <ng-template ngbTabContent>
            <div class="results-list">
              <app-enquiry-search [entity]="trainer" entityType="trainer" (reloaded)="enquiriesReloaded($event)"></app-enquiry-search>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab id="comments" title="Comments" [disabled]="meta.loading">
          <ng-template ngbTabContent class="results-list">
            <app-trainer-comments [trainer]="trainer"></app-trainer-comments>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
  </div>
</div>
