import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { JockeysService } from '../../../services/jockeys.service';
import { OwnersService } from '../../../services/owners.service';
import { TrainersService } from '../../../services/trainers.service';
import { HorsesService } from '../../../services/horses.service';

import { EnquiryComponent } from '../../modals/enquiry/enquiry.component';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-enquiry-search',
  templateUrl: './enquiry-search.component.html',
  styleUrls: ['./enquiry-search.component.scss']
})
export class EnquirySearchComponent implements OnInit {

  @Input() entity;
  @Input() entityType;
  @Output() reloaded = new EventEmitter();
  date$ = new Subject<string>();
  showFilter = false;
  meta: {
    loadingResults: boolean,
    loadingFilters: boolean,
    updateFilterTimeout: number,
    showFilters: Array<string>,
    availableFilters: {
      courseIds: Array<number>,
      horseIds: Array<number>,
      outcomes: Array<string>,
      penalties: Array<string>,
      offenceIds: Array<number>,
      subOffences: Array<string>
    },
    filters: {
      fromDate: string,
      toDate: string,
      courseIds: Array<number>,
      horseIds: Array<number>,
      outcomes: Array<string>,
      penalties: Array<string>,
      offenceIds: Array<number>,
      subOffences: Array<string>
    },
    results: {
      currentPage: number,
      pageSize: number,
      lastPage: number,
      totalResult: number
    }
  };

  constructor(
    private modalService: NgbModal,
    private notification: ToastrService,
    private jockeysService: JockeysService,
    private ownersService: OwnersService,
    private trainersService: TrainersService,
    private horsesService: HorsesService,
    private eventsService: EventsService
  ) { }

  ngOnInit() {
    this.meta = {
      loadingResults: false,
      loadingFilters: false,
      updateFilterTimeout: 0,
      showFilters: [],
      availableFilters: {
        courseIds: [],
        horseIds: [],
        outcomes: [],
        penalties: [],
        offenceIds: [],
        subOffences: []
      },
      filters: {
        fromDate: moment().subtract(12, 'months').format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        courseIds: [],
        horseIds: [],
        outcomes: [],
        penalties: [],
        offenceIds: [],
        subOffences: []
      },
      results: {
        currentPage: null,
        pageSize: null,
        lastPage: null,
        totalResult: null
      }
    };

    this.eventsService.observableEnquiries.subscribe(enquiryObject => {
      this.loadResults(this.meta.results.currentPage || 1);
    });

    this.loadFilters();
    this.loadResults();
  }

  loadFilters = (delay = 0) => {
    const entityId = this.entity[`${this.entityType}Id`];
    this.meta.loadingFilters = true;
    this[`${this.entityType}sService`]
      .getFilters(entityId, this.entityType, this.meta.filters.fromDate, this.meta.filters.toDate).subscribe(
      res => {
        this.meta.availableFilters = {
          courseIds: res.courseIds,
          horseIds: res.horseIds,
          outcomes: res.outcomes,
          penalties: res.penalties,
          offenceIds: res.offenceIds,
          subOffences: res.subOffences
        };
        this.meta.loadingFilters = false;
      },
      err => {
        this.notification.error(`Could't load the ${this.entityType} filters: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.loadingFilters = false;
      }
    );
  }

  loadResults = (page = 1) => {
    const entityId = this.entity[`${this.entityType}Id`];
    this.meta.loadingResults = true;
    this.entity.enquiries = [];
    if (entityId) {
      console.log('Searching with this filters:', this.meta.filters);
      this[`${this.entityType}sService`].getEnquiries(entityId, this.meta.filters, page).subscribe(
        res => {
          this.entity.enquiries = res.data;
          this.meta.results = {
            currentPage: res.current_page,
            pageSize: res.per_page,
            lastPage: res.last_page,
            totalResult: res.total
          };
          this.entity.enquiries.forEach(enquiry => {
            const breachStatus = enquiry.outcomes.find(eo => eo.objectType === 'inBreach');
            if (breachStatus !== undefined && breachStatus.isApplied) {
              enquiry.breachStatus = breachStatus.value.replace(/_/g, ' ');
            } else {
              enquiry.breachStatus = 'not in breach';
            }
          });
          this.meta.loadingResults = false;
          this.reloaded.emit(true);
        },
        err => {
          this.notification.error(`Could't get the ${this.entityType} Enquiries: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
          this.meta.loadingResults = false;
          this.reloaded.emit(false);
        }
      );
    } else {
      this.meta.loadingResults = false;
    }
  }

  openEnquiry = (enquiryId) => {
    const modalWindow = this.modalService
      .open(EnquiryComponent, { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.enquiry = {enquiryId};
    modalWindow.result.then((result) => {
      // console.log(`[ DEBUG ] Modal Closed with payload`, result);
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }
  showTableFilter() {
    this.showFilter = this.showFilter === false;
  }

}
