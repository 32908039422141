import { Component, OnInit, Input } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HorsesService } from '../../../services/horses.service';

import { CommentComponent } from '../../modals/comment/comment.component';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-horse-comments',
  templateUrl: './horse-comments.component.html',
  styleUrls: ['./horse-comments.component.scss']
})
export class HorseCommentsComponent implements OnInit {

  @Input() horse;
  meta: {
    loading: boolean,
    saving: boolean,
    results: {
      currentPage: number,
      pageSize: number,
      lastPage: number,
      totalResult: number
    }
  };

  constructor(
    private modalService: NgbModal,
    private notification: ToastrService,
    private horsesService: HorsesService,
    private eventsService: EventsService
  ) { }

  ngOnInit() {
    this.meta = {
      loading: false,
      saving: false,
      results: {
        currentPage: null,
        pageSize: null,
        lastPage: null,
        totalResult: null
      }
    };

    this.loadResults(this.horse.horseId);
  }

  loadResults = (horseId, page = 1) => {
    this.meta.loading = true;
    this.horse.comments = [];
    this.horsesService.getHorseComments({horseId: horseId}, page).subscribe(
      res => {
        this.horse.comments = res.data;
        this.horse.comments.forEach(hc => {
          if (hc.rpcComments) {
            hc.rpcComments = hc.rpcComments.map(rpcComment => rpcComment.racePerformanceComment).join(' | ');
          }
        });
        this.meta.results = {
          currentPage: res.current_page,
          pageSize: res.per_page,
          lastPage: res.last_page,
          totalResult: res.total
        };
        this.meta.loading = false;
      },
      err => {
        this.notification.error(`Could't get the Horse Comments: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.loading = false;
      }
    );
  }

  openComment = (id) => {
    const modalWindow = this.modalService.open(CommentComponent, { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.comment = {id};
    modalWindow.result.then((result) => {
      console.log(`[ DEBUG ] Modal Closed with payload`, result);
    }, (reason) => {
      console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
      if (reason === 'comment deleted') {
        this.loadResults(this.horse.horseId, this.meta.results.currentPage);
      }
    });
  }

}
