import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { forkJoin } from 'rxjs';

import * as moment from 'moment';

import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../../services/events.service';
import { FixturesService } from '../../../services/fixtures.service';
import { RacesService } from '../../../services/races.service';
import { EntriesService } from '../../../services/entries.service';

@Component({
  selector: 'app-fixture-racecards',
  templateUrl: './fixture-racecards.component.html',
  styleUrls: ['./fixture-racecards.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FixtureRacecardsComponent implements OnInit {

  withRPC: boolean;
  meta: {
    started: boolean,
    loading: boolean,
    status: string,
  };
  fixture: any;
  races: any[];


  constructor(
    private notification: ToastrService,
    private route: ActivatedRoute,
    private eventsService: EventsService,
    private fixturesService: FixturesService,
    private racesService: RacesService,
    private entriesService: EntriesService,
  ) { }

  ngOnInit() {
    this.withRPC = false;
    this.meta = {
      started: false,
      loading: false,
      status: 'initializing',
    };
    this.races = [];

    this.fixture = {
      fixtureId: Number(this.route.snapshot.paramMap.get('fixtureId')),
      fixtureYear: this.route.snapshot.paramMap.get('fixtureYear')
    };
  }

  start() {
    this.route.params.subscribe(params => {
      if (Object.keys(params).length) {
        this.meta.started = true;
        this.meta.loading = true;
        this.getFixtureInfo(this.fixture.fixtureYear, this.fixture.fixtureId);
      }
    });
  }

  getFixtureInfo = (fixtureYear, fixtureId) => {
    this.meta.status = 'Loading Fixture Info';
    this.fixturesService.getFixtureInfo({ fixtureYear, fixtureId }).subscribe(
      res => {
        this.fixture = res;
        this.fixture.fixtureYear = this.fixture.year;
        this.eventsService.changeFixtureData(this.fixture);
        this.getFixtureRaces(this.fixture.fixtureYear, this.fixture.fixtureId);
      },
      err => {
        this.notification.error(`Could't get the Fixture information: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.loading = false;
      }
    );
  }

  getFixtureRaces = (fixtureYear, fixtureId): void => {
    this.meta.status = 'Loading Fixture\'s Races';
    this.fixturesService.getFixtureRaces({ fixtureYear, fixtureId }).subscribe(
      res => {
        this.races = res;
        forkJoin(this.getEntries(this.races), this.getRaceInfo(this.races)).subscribe(
          () => console.log(this.fixture, this.races),
          err => console.log(err),
          () => {
            this.meta.status = 'finalising';
            setTimeout(() => {
              this.meta = {
                started: false,
                loading: false,
                status: 'initializing'
              };
            }, 1000);
          }
        );
      },
      err => {
        this.notification.error(`Could't get the Fixture Entries with Sampling Requests: ` +
        `${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
      }
    );
  }

  getRaceInfo = (races) => {
    return new Promise((resolve, reject) => {
      this.meta.status = 'Loading Races Info';
      const raceInfoRequests = races.map(race => this.racesService.getRaceInfo(race));
      forkJoin(...raceInfoRequests).subscribe(
        results => {
          results.forEach((raceInfo, index) => {
            if (!raceInfo || !raceInfo['fixtureId']) {
              console.log('Failed to load data:', races[index].raceId, races[index]);
              this.notification.error(`Could't get race info for the ${moment(races[index].raceDateTime).format('HH:mm')} race.`, 'Error!');
              this.races[index].info = {};
            } else {
              this.races[index].info = raceInfo;
            }
          });
          resolve();
        },
        errors => {
          console.log('Failed to load data:', errors);
          this.notification.error(`Could't get info for races.`, 'Error!');
          this.meta.loading = false;
          reject(errors);
        }
      );
    });
  }

  getEntries = (races) => {
    return new Promise( (resolve, reject) => {
      this.meta.status = 'Loading Races Runners';
      let racesCompleted = 0;
      const entriesRequests = races.map(race => this.racesService.getRaceEntries(race));
      forkJoin(...entriesRequests).subscribe(
        results => {
          results.forEach((entries: [any], indexRace) => {
            if (!entries || !entries[0]) {
              console.log('Failed to load data:', races[indexRace].raceId, races[indexRace]);
              this.notification.error(
                `Could't get some details for the ${moment(races[indexRace].raceDateTime).format('HH:mm')} race.`,
                'Error!'
              );
              this.races[indexRace].entries = [];
            } else {
              for (let i = 0; i < entries.length; i++) {
                entries[i] = this.processWearingCodes(entries[i]);
                entries[i].shortDispensation = entries[i].dispensation
                  ? entries[i].shoesDispensation.dispensation.split(' ').splice(0, 4).join(' ')
                  : null;
                entries[i].minHeightPx = 58;
                entries[i].customHeightPx = 58;
                entries[i].imageSize = 50;
                entries[i].customFontSize = 1;
                if (entries.length < 20) {
                  entries[i].customHeightPx = (20 / entries.length) * entries[i].customHeightPx + 5;
                  entries[i].imageSize = (20 / entries.length) * entries[i].imageSize;
                  entries[i].customFontSize = (20 / entries.length) * entries[i].customFontSize + 5;
                  if (entries[i].customHeightPx > 300) {
                    entries[i].customHeightPx = 300;
                  }
                  if (entries[i].imageSize > 80) {
                    entries[i].imageSize = 80;
                  }
                  if (entries[i].customFontSize > 1.15) {
                    entries[i].customFontSize = 1.15;
                  }
                } else {
                  entries[i].minHeightPx = 58;
                  entries[i].customHeightPx = 58;
                  entries[i].imageSize = 50;
                  entries[i].customFontSize = 1;
                }
              }
              this.races[indexRace].entries = entries;
              if (this.withRPC) {
                const entryLatestRequests = entries.map(
                  entry => this.getLatestEntryNotes(entry.horseId, this.races[indexRace].raceDateTime)
                );
                forkJoin(...entryLatestRequests).subscribe(
                  res => {
                    for (let indexEntry = 0; indexEntry < this.races[indexRace].entries.length; indexEntry++) {
                      this.races[indexRace].entries[indexEntry].latest = res[indexEntry] || {};
                    }
                  },
                  err => reject(err),
                  () => {
                    racesCompleted++;
                    this.meta.status = `Loading latest comments/enquiries ${racesCompleted}/${races.length}`;
                    if (racesCompleted === races.length) {
                      // console.log('resolve');
                      resolve();
                    }
                  }
                );
              } else {
                racesCompleted++;
                // console.log(racesCompleted + ' completed races');
                if (racesCompleted === races.length) {
                  resolve();
                }
              }
            }
          });
        },
        errors => {
          console.log('Failed to load data:', errors);
          this.notification.error(`Could't get some details for racecards.`, 'Error!');
          this.meta.loading = false;
          reject(errors);
        }
      );
    });
  }

  processWearingCodes(entry): void {
    if (entry.headGearCodeDefinition) {
      // Assuming the two arrays' elements are in the same order, which should be unless the backend code changes
      const codesArray = entry.headGearCodeDefinition.abbreviation.split(' ');
      for (let i = 0; i < entry.headGearCodeDefinition.firstTimeIndicator.length; i++) {
        entry.headGearCodeDefinition.firstTimeIndicator[i].code = codesArray[i];
      }
      entry.headgear = entry.headGearCodeDefinition.firstTimeIndicator;
    }
    return entry;
  }

  getLatestEntryNotes = (horseId, raceDate) => {
    return new Promise((resolve, reject) => {
      forkJoin(
        this.entriesService.getEntryOldComments(horseId, true, moment(raceDate).format('YYYYMMDD')),
        this.entriesService.getEntryOldEnquiries(horseId, moment(raceDate).format('YYYYMMDD'))
      ).subscribe(
        res => resolve({
          comment: {
            date: res[0]['data'][0] ? res[0]['data'][0]['dateTime'] : null,
            courseShortName: res[0]['data'][0] ? res[0]['data'][0]['courseNameShort'] : null,
            rpc: res[0]['data'][0] ? res[0]['data'][0]['rpcComments'] : null
          },
          enquiry: {
            date: res[1]['data'][0] ? res[1]['data'][0]['updatedAt'] : null,
            offence: res[1]['data'][0] ? res[1]['data'][0]['offence'] : null
          }
        }),
        err => reject(err)
      );
    });
  }

  doPrint = () => {
    window.print();
  }
}
