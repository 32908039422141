import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { EventsService } from '../../services/events.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  meta: {
    loading: boolean
    isLoggedIn: Object|Boolean
    urlReturn: string
  };
  authData: {
    username: string
    password: string
  };


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notification: ToastrService,
    private eventsService: EventsService,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.meta = {
      loading: false,
      isLoggedIn: false,
      urlReturn: '/fixtures'
    };
    this.authData = {
      username: '',
      password: ''
    };

    this.route.queryParams.subscribe(params => {
      this.meta.urlReturn = params['return'];
    });
  }

  doLogin() {
    this.authService.login(this.authData.username, this.authData.password).subscribe(
      res => {
        // console.log('LOGIN RESPONSE', res);
        this.authService.saveSession(res);
        this.authService.setHeaders(res.token_type, res.access_token);
        this.eventsService.changeUserData(res);
        // this.router.navigate(['/fixtures']);
        this.router.navigateByUrl(this.meta.urlReturn);
      },
      err => {
        console.error(err);
        this.notification.error(err.error.message, 'Authentication');
        this.authData.password = '';
      }
    );
  }

}
