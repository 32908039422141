import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';
import { of } from 'rxjs';

@Injectable()
export class SearchService {

  defaultHeaders: HttpHeaders;
  constructor(
    private http: HttpClient,
    private auth: AuthenticationService
  ) { }

  getResults(
    type: String = null,
    q: String = '',
    page: Number = 1,
    perPage: Number = 10,
    options: Array<{key: any, value: any}> = [],
    headers = this.auth.getHeaders()): any {
    if (type) {
      let url = `${environment.apiBase}/v2/${type}`;
      url += q ? `?q=${q}` : '?q=';
      options.forEach(opt => {
        if (opt.value !== null) {
          url += `&${opt.key}=${opt.value}`;
        }
      });
      url += `&page=${page}&perPage=${perPage}`;
      return this.http.get(url, {headers});
    } else {
      return of([]);
    }
  }
}
