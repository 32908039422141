import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OwnersService } from '../../../services/owners.service';

import { CommentComponent } from '../../modals/comment/comment.component';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-owner-comments',
  templateUrl: './owner-comments.component.html',
  styleUrls: ['./owner-comments.component.scss']
})
export class OwnerCommentsComponent implements OnInit {

  @Input() owner;
  meta: {
    loading: boolean,
    saving: boolean,
    results: {
      currentPage: number,
      pageSize: number,
      lastPage: number,
      totalResult: number
    }
  };

  constructor(
    private modalService: NgbModal,
    private notification: ToastrService,
    private route: ActivatedRoute,
    private location: Location,
    private ownersService: OwnersService,
    private eventsService: EventsService
  ) { }

  ngOnInit() {
    this.meta = {
      loading: false,
      saving: false,
      results: {
        currentPage: null,
        pageSize: null,
        lastPage: null,
        totalResult: null
      }
    };

    this.loadResults(this.owner.ownerId);
  }

  loadResults = (ownerId, page = 1) => {
    console.log(ownerId, page);
    this.meta.loading = true;
    this.owner.comments = [];
    this.ownersService.getOwnerComments({ownerId: ownerId}, page).subscribe(
      res => {
        this.owner.comments = res.data;
        this.meta.results = {
          currentPage: res.current_page,
          pageSize: res.per_page,
          lastPage: res.last_page,
          totalResult: res.total
        };
        this.meta.loading = false;
      },
      err => {

        this.notification.error(`Could't get the Owner Comments: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.loading = false;
      }
    );
  }

  openComment = (id) => {
    const modalWindow = this.modalService.open(CommentComponent, { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.comment = {id};
    modalWindow.result.then((result) => {
      console.log(`[ DEBUG ] Modal Closed with payload`, result);
    }, (reason) => {
      console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
      if (reason === 'comment deleted') {
        this.loadResults(this.owner.ownerId, this.meta.results.currentPage);
      }
    });
  }

}
