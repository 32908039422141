<app-navbar></app-navbar>
<!--<a class="back-link" [routerLink]="['/']"><fa name="exchange"></fa> Change Fixture</a>-->
<div class="container-fluid">
<div *ngIf="meta.loading">
  <div class="row">
    <div class="col-12 col-md-4 offset-md-4 text-center">
      <fa name="circle-o-notch" size="4x" animation="spin"></fa>
      <img src="assets/loader.svg" alt="spinner">
    </div>
  </div>
</div>
<div *ngIf="!meta.loading" class="row mb-5 mt-5 align-items-center">
  <div class="col-10">
    <div *ngIf="selectedRace">
      <h2 class="font-weight-bold">{{selectedFixture.courseName | uppercase}} - <span> {{selectedFixture.fixtureDate | date:'longDate'}}</span></h2>
    </div>
  </div>
  <div class="col-2 text-right">
    <div ngbDropdown>
      <button class="btn btn-danger btn-md" id="fixtureActions" ngbDropdownToggle>
        <a class="d-none d-md-block">Fixture actions <fa class="ml-1" name="chevron-down"></fa></a>
        <a class="d-block d-md-none"><fa name="bars"></fa></a>
      </button>
      <div placement="bottom-right" ngbDropdownMenu aria-labelledby="fixtureActions">
        <button (click)="openAddFixtureComment(selectedFixture)" class="dropdown-item">Add Fixture Comment</button>
        <button (click)="openUpdateOfficials(selectedFixture)" class="dropdown-item">Update Stewards</button>
        <button [routerLink]="['/fixtures', selectedFixture.fixtureYear, selectedFixture.fixtureId, 'sampling']" class="dropdown-item">Sampling Requests</button>
        <button [routerLink]="['/fixtures', selectedFixture.fixtureYear, selectedFixture.fixtureId, 'racecards']" class="dropdown-item">Print Racecards</button>
        <button [routerLink]="['/fixtures', selectedFixture.fixtureYear, selectedFixture.fixtureId, 'report']" class="dropdown-item">Print Report</button>
        <button (click)="openSuspensionDateInfo(selectedFixture)" class="dropdown-item">Fixture suspension dates</button>
      </div>
    </div>
  </div>
</div>
</div>

<!-- RACE LIST -->
<div class="container-fluid mb-5" *ngIf="!meta.loading">
  <div class="row">
    <div class="col-12">
      <ngb-tabset
        #raceTabs="ngbTabset"
        [activeId]="activeRace"
        (tabChange)="selectRace($event.nextId.replace('race-', ''))"
        style="flex:1;"
        id="timeTabs"
      >
        <!-- RACECARD -->
        <ngb-tab
          *ngFor="let race of racesList; index as racei"
          id="race-{{ racei }}"
          title="{{ race.raceDatetime | date: 'h:mm' }}"
          [disabled]="meta.raceEntries.loading"
          style="flex:1;"
        >
          <ng-template ngbTabContent *ngIf="meta.raceEntries.loading">
            <div class="col text-center pt-5">
<!--              <fa name="circle-o-notch" size="2x" animation="spin"></fa><br />-->
              <img src="assets/loader.svg" class="d-block ml-auto mr-auto" alt="spinner">
              Loading...
            </div>
          </ng-template>
          <ng-template ngbTabContent  class="tab-title" *ngIf="!meta.raceEntries.loading">
            <div class="row mb-5 mt-5 align-items-center">
              <div class="col-10">
                <h4 class="font-weight-bold"><fa name="clock-o"></fa> {{ selectedRace.raceDatetime | date: 'h:mm' }} - {{ selectedRace.raceName }}</h4>
              </div>
              <div class="col-2 text-right">
                <div *ngIf="selectedRace" ngbDropdown display="dynamic">
                  <button class="btn btn-primary btn-md" id="raceActions" ngbDropdownToggle>
                    <span class="d-none d-md-block">Race Actions <fa name="chevron-down"></fa></span>
                    <span class="d-block d-md-none"><fa name="bars"></fa></span>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="raceActions">
                    <button (click)="openAddComment(selectedRace)" class="dropdown-item">
                      Add Race Comment
                    </button>
                    <!-- <button (click)="openUpdateStatus(selectedRace)" class="dropdown-item">Update Status</button> -->
                    <button (click)="openFalseStart(selectedRace)" class="dropdown-item">
                      False Start
                    </button>
                    <button (click)="openVoidRace(selectedRace)" class="dropdown-item">
                      Void Race
                    </button>
                    <button (click)="openLateRace(selectedRace)" class="dropdown-item">
                      Late Race
                    </button>
                    <button (click)="openLeftParadeRing(selectedRace)" class="dropdown-item">
                      Left Parade Ring
                    </button>
                    <button (click)="openRequestedDelay(selectedRace)" class="dropdown-item">
                      Requested Delay
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- List entries -->
              <app-race-entry
                [entry]="entry"
                [even]="evenRow"
                [odd]="oddRow"
                [first]="firstRow"
                [last]="entryIndex >= selectedRace.entries.length - 2"
                *ngFor="
                  let entry of selectedRace.entries;
                  even as evenRow;
                  odd as oddRow;
                  first as firstRow;
                  index as entryIndex
                "
              >
              </app-race-entry>
            <!-- RACE INFO -->
            <div class="pt-4">
              <app-interactive-events
                *ngIf="selectedRace && selectedFixture"
                [selectedRace]="selectedRace"
                [selectedFixture]="selectedFixture"
              ></app-interactive-events>
              <app-race-info
                *ngIf="selectedRace && selectedFixture"
                [selectedRace]="selectedRace"
                [selectedFixture]="selectedFixture"
              ></app-race-info>
            </div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
</div>
