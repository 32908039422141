<app-navbar></app-navbar>

<div *ngIf="meta.loading">
  <div class="row">
    <div class="col-12 col-md-4 offset-md-4 text-center">
      <fa name="circle-o-notch" size="4x" animation="spin"></fa>
    </div>
  </div>
</div>
<div *ngIf="!meta.loading">
  <div class="row pb-2">
    <div class="col">
      <h5>Sampling Requests</h5>
    </div>
    <div class="col-2 text-center">
      <button [disabled]="meta.fixtureSampledEntries.loading" type="button" class="btn btn-block btn-primary" (click)="drawRandomEntry(selectedFixture.fixtureYear, selectedFixture.fixtureId)">
        <span *ngIf="!meta.randomEntry.loading">Draw Random Runner</span>
        <fa *ngIf="meta.randomEntry.loading" name="circle-o-notch" size="lg" animation="spin"></fa>
      </button>
    </div>
    <div class="col-2 text-center">
      <button type="button" class="btn btn-block btn-secondary" [routerLink]="['/races', selectedFixture.fixtureYear, selectedFixture.fixtureId]">
        <span>Back to Racecards</span>
      </button>
    </div>
  </div>
  <i class="lead" *ngIf="randomEntry">Random Runner signed up for the {{randomEntry.raceDateTime | date:'h:mm'}} race</i>
  <app-race-entry *ngIf="randomEntry" [entry]="randomEntry" [actions]="{none: true}" [borderTop]="true" [borderBottom]="true"></app-race-entry>
  <div class="row border-bottom pb-2" *ngIf="false">
    <div class="col-1 d-flex align-items-center justify-content-center">
      <button type="button" class="btn btn-primary" (click)="addSamplingRequest(randomEntry)">
        <span>Confirm</span>
      </button>
    </div>
    <div class="col">
      <span class="lead font-weight-bold">Running at {{randomEntry.raceDateTime | date:'h:mm'}}</span>
    </div>
  </div>
  <div class="row" *ngIf="meta.fixtureSampledEntries.loading">
    <div class="col text-center">
      <fa name="circle-o-notch" size="3x" animation="spin"></fa>
    </div>
  </div>
  <div class="row" *ngIf="!meta.fixtureSampledEntries.loading">
    <div class="col">
      <i class="lead" *ngIf="randomEntry && selectedFixture.entries.length">Active Sample Requests</i>
      <div *ngFor="let entry of selectedFixture.entries; index as entryIndex">
        <div class="mb-2 mt-4 border-bottom" *ngIf="(entryIndex-1 >= 0) && (selectedFixture.entries[entryIndex-1].raceDateTime !== selectedFixture.entries[entryIndex].raceDateTime)">
          <strong>{{ entry.raceDateTime | date:'HH:mm' }} - {{entry.raceName}}</strong>
        </div>
        <div class="mb-2 mt-4 border-bottom" *ngIf="entryIndex === 0">
          <strong>{{ entry.raceDateTime | date:'HH:mm' }} - {{entry.raceName}}</strong>
        </div>
        <app-race-entry
          [entry]="entry"
          [actions]="{sampling:true}"
          [first]="firstRow"
          [last]="lastRow"
        ></app-race-entry>
      </div>
    </div>
  </div>
</div>
