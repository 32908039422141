<form #scaleForm="ngForm">
    <div class="modal-header d-flex justify-content-center">
      <h4 class="modal-title">UPDATE SHOE DISPENSATION</h4>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col text-center">
          <div class="form-group">
            <label for="newShoeDispensation">Select a new status</label>
            <select class="custom-select" id="newShoeDispensation" name="newShoeDispensation" [(ngModel)]="selectedStatus">
              <option *ngFor="let dispensation of possibleStatuses" [value]="dispensation">{{dispensation}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!meta.saving" class="modal-footer d-flex justify-content-around">
      <button type="button" class="btn btn-outline-dark" (click)="cancel('cancel')">Close</button>
      <button type="button" class="btn btn-primary" [disabled]="!selectedStatus" (click)="confirm()">Confirm</button>
    </div>
    <div *ngIf="meta.saving" class="modal-footer d-flex justify-content-around">
      <h1>
        <fa name="circle-o-notch" animation="spin"></fa>
      </h1>
    </div>
  </form>
  