import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../../../services/events.service';
import { ModalActionsService } from '../../../../services/modalActions.service';

@Component({
  selector: 'app-race-false-start',
  templateUrl: './race-false-start.component.html',
  styleUrls: ['./race-false-start.component.scss']
})
export class RaceFalseStartComponent implements OnInit {

  @Input() race;
  meta: {
    loading: boolean,
    saving: boolean
  };
  userId: number;
  constructor(
    private activeModal: NgbActiveModal,
    private notification: ToastrService,
    private eventsService: EventsService,
    private modalActionsService: ModalActionsService,
  ) { }

  ngOnInit() {
    this.meta = {
      loading: false,
      saving: false
    };

    this.eventsService.observableUser.subscribe(data => {
      this.userId = data.id;
    });
  }

  confirm = () => {
    this.meta.saving = true;
    this.modalActionsService.updateRace(this.race, {falseStart: true, userId: this.userId}).subscribe(
      res => {
        this.activeModal.close(res);
        this.notification.success(`${this.race.raceName} has been flagged with False Start.`, 'Success!');
        this.meta.saving = false;
      },
      err => {
        this.notification.error(`Could't update the race: ` +
        `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
        this.meta.saving = false;
      }
    );
  }

  cancel = (action) => {
    this.activeModal.dismiss(action);
  }

}
