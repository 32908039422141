/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./interactive-events.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/angular-font-awesome/dist/angular-font-awesome.ngfactory";
import * as i3 from "angular-font-awesome";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "./interactive-events.component";
import * as i9 from "ngx-toastr";
import * as i10 from "../../../services/modalActions.service";
import * as i11 from "../../../services/events.service";
var styles_InteractiveEventsComponent = [i0.styles];
var RenderType_InteractiveEventsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InteractiveEventsComponent, data: {} });
export { RenderType_InteractiveEventsComponent as RenderType_InteractiveEventsComponent };
function View_InteractiveEventsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "fa", [["animation", "spin"], ["name", "circle-o-notch"]], null, null, null, i2.View_AngularFontAwesomeComponent_0, i2.RenderType_AngularFontAwesomeComponent)), i1.ɵdid(2, 114688, null, 0, i3.AngularFontAwesomeComponent, [], { name: [0, "name"], animation: [1, "animation"] }, null)], function (_ck, _v) { var currVal_0 = "circle-o-notch"; var currVal_1 = "spin"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_InteractiveEventsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" (", ") "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedRace.todoList.totals; _ck(_v, 1, 0, currVal_0); }); }
function View_InteractiveEventsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Additional information"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InteractiveEventsComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InteractiveEventsComponent_3)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.meta.raceTodoList.loading; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.meta.raceTodoList.loading; _ck(_v, 5, 0, currVal_1); }, null); }
function View_InteractiveEventsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "small", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "fa", [["class", "text-warning"], ["name", "exclamation-triangle"]], null, null, null, i2.View_AngularFontAwesomeComponent_0, i2.RenderType_AngularFontAwesomeComponent)), i1.ɵdid(2, 114688, null, 0, i3.AngularFontAwesomeComponent, [], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = "exclamation-triangle"; _ck(_v, 2, 0, currVal_0); }, null); }
function View_InteractiveEventsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["[ Missing Reason - Click to Add ]"]))], null, null); }
function View_InteractiveEventsComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" - ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.comment; _ck(_v, 1, 0, currVal_0); }); }
function View_InteractiveEventsComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" - ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.internalComment; _ck(_v, 1, 0, currVal_0); }); }
function View_InteractiveEventsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "small", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InteractiveEventsComponent_10)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InteractiveEventsComponent_11)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.comment; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.internalComment; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.reasonText; _ck(_v, 2, 0, currVal_0); }); }
function View_InteractiveEventsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "small", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "a", [["target", "_blank"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i4.LocationStrategy], { target: [0, "target"], routerLink: [1, "routerLink"] }, null), i1.ɵpad(5, 3), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Non Runner"])), (_l()(), i1.ɵeld(10, 0, null, null, 7, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 6, "button", [["class", "btn btn-link pt-0"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openNonRunner(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InteractiveEventsComponent_7)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InteractiveEventsComponent_8)), i1.ɵdid(15, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InteractiveEventsComponent_9)), i1.ɵdid(17, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "_blank"; var currVal_3 = _ck(_v, 5, 0, "/horses", _v.context.$implicit.horseId, "enquiries"); _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_5 = _co.meta.raceTodoList.failed; _ck(_v, 13, 0, currVal_5); var currVal_6 = !_v.context.$implicit.reasonText; _ck(_v, 15, 0, currVal_6); var currVal_7 = _v.context.$implicit.reasonText; _ck(_v, 17, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.horseName; _ck(_v, 6, 0, currVal_4); }); }
function View_InteractiveEventsComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "small", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "fa", [["class", "text-warning"], ["name", "exclamation-triangle"]], null, null, null, i2.View_AngularFontAwesomeComponent_0, i2.RenderType_AngularFontAwesomeComponent)), i1.ɵdid(2, 114688, null, 0, i3.AngularFontAwesomeComponent, [], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = "exclamation-triangle"; _ck(_v, 2, 0, currVal_0); }, null); }
function View_InteractiveEventsComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["[ Missing Reason - Click to Add ]"]))], null, null); }
function View_InteractiveEventsComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.reasonText; _ck(_v, 1, 0, currVal_0); }); }
function View_InteractiveEventsComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" - ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.comment; _ck(_v, 1, 0, currVal_0); }); }
function View_InteractiveEventsComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" - ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.internalComment; _ck(_v, 1, 0, currVal_0); }); }
function View_InteractiveEventsComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "small", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "a", [["target", "_blank"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i4.LocationStrategy], { target: [0, "target"], routerLink: [1, "routerLink"] }, null), i1.ɵpad(5, 3), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Withdrawn"])), (_l()(), i1.ɵeld(10, 0, null, null, 11, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 10, "button", [["class", "btn btn-link pt-0"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openWithdrawn(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InteractiveEventsComponent_13)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InteractiveEventsComponent_14)), i1.ɵdid(15, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InteractiveEventsComponent_15)), i1.ɵdid(17, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InteractiveEventsComponent_16)), i1.ɵdid(19, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InteractiveEventsComponent_17)), i1.ɵdid(21, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "_blank"; var currVal_3 = _ck(_v, 5, 0, "/horses", _v.context.$implicit.horseId, "enquiries"); _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_5 = _co.meta.raceTodoList.failed; _ck(_v, 13, 0, currVal_5); var currVal_6 = !_v.context.$implicit.reasonText; _ck(_v, 15, 0, currVal_6); var currVal_7 = _v.context.$implicit.reasonText; _ck(_v, 17, 0, currVal_7); var currVal_8 = _v.context.$implicit.comment; _ck(_v, 19, 0, currVal_8); var currVal_9 = _v.context.$implicit.internalComment; _ck(_v, 21, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.horseName; _ck(_v, 6, 0, currVal_4); }); }
function View_InteractiveEventsComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "small", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "fa", [["class", "text-warning"], ["name", "exclamation-triangle"]], null, null, null, i2.View_AngularFontAwesomeComponent_0, i2.RenderType_AngularFontAwesomeComponent)), i1.ɵdid(2, 114688, null, 0, i3.AngularFontAwesomeComponent, [], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = "exclamation-triangle"; _ck(_v, 2, 0, currVal_0); }, null); }
function View_InteractiveEventsComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["[ Missing Reason - Click to Add ]"]))], null, null); }
function View_InteractiveEventsComponent_22(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["..."]))], null, null); }
function View_InteractiveEventsComponent_21(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(0, i4.SlicePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InteractiveEventsComponent_22)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = (_v.parent.context.$implicit.comment.length > 100); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_v.parent.context.$implicit.comment, 0, 100)); _ck(_v, 1, 0, currVal_0); }); }
function View_InteractiveEventsComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "small", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "a", [["target", "_blank"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i4.LocationStrategy], { target: [0, "target"], routerLink: [1, "routerLink"] }, null), i1.ɵpad(5, 3), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Jockey Change"])), (_l()(), i1.ɵeld(10, 0, null, null, 7, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 6, "button", [["class", "btn btn-link pt-0"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openJockeyChange(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InteractiveEventsComponent_19)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InteractiveEventsComponent_20)), i1.ɵdid(15, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InteractiveEventsComponent_21)), i1.ɵdid(17, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "_blank"; var currVal_3 = _ck(_v, 5, 0, "/horses", _v.context.$implicit.horseId, "enquiries"); _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_5 = _co.meta.raceTodoList.failed; _ck(_v, 13, 0, currVal_5); var currVal_6 = !_v.context.$implicit.comment; _ck(_v, 15, 0, currVal_6); var currVal_7 = _v.context.$implicit.comment; _ck(_v, 17, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.horseName; _ck(_v, 6, 0, currVal_4); }); }
function View_InteractiveEventsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InteractiveEventsComponent_6)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InteractiveEventsComponent_12)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InteractiveEventsComponent_18)), i1.ɵdid(6, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedRace.todoList.nonRunner; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.selectedRace.todoList.withdrawn; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.selectedRace.todoList.jockeyChange; _ck(_v, 6, 0, currVal_2); }, null); }
function View_InteractiveEventsComponent_23(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "tr", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "td", [["colspan", "3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nothing found for this race"]))], null, null); }
function View_InteractiveEventsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "table", [["class", "table table-sm table-striped"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Horse"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Context"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Information"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InteractiveEventsComponent_5)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InteractiveEventsComponent_23)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedRace.todoList.totals; _ck(_v, 10, 0, currVal_0); var currVal_1 = !_co.selectedRace.todoList.totals; _ck(_v, 12, 0, currVal_1); }, null); }
export function View_InteractiveEventsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "ngb-accordion", [["class", "accordion"], ["role", "tablist"]], [[1, "aria-multiselectable", 0]], null, null, i6.View_NgbAccordion_0, i6.RenderType_NgbAccordion)), i1.ɵdid(1, 2146304, [["todoListAccordion", 4]], 1, i7.NgbAccordion, [i7.NgbAccordionConfig], { activeIds: [0, "activeIds"] }, null), i1.ɵqud(603979776, 1, { panels: 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 8, "ngb-panel", [["id", "todoList"]], null, null, null, null, null)), i1.ɵdid(4, 2113536, [[1, 4]], 3, i7.NgbPanel, [], { id: [0, "id"] }, null), i1.ɵqud(603979776, 2, { titleTpls: 1 }), i1.ɵqud(603979776, 3, { headerTpls: 1 }), i1.ɵqud(603979776, 4, { contentTpls: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_InteractiveEventsComponent_1)), i1.ɵdid(9, 16384, [[2, 4]], 0, i7.NgbPanelTitle, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_InteractiveEventsComponent_4)), i1.ɵdid(11, 16384, [[4, 4]], 0, i7.NgbPanelContent, [i1.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.meta.activeIds; _ck(_v, 1, 0, currVal_1); var currVal_2 = "todoList"; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = !i1.ɵnov(_v, 1).closeOtherPanels; _ck(_v, 0, 0, currVal_0); }); }
export function View_InteractiveEventsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-interactive-events", [], null, null, null, View_InteractiveEventsComponent_0, RenderType_InteractiveEventsComponent)), i1.ɵdid(1, 114688, null, 0, i8.InteractiveEventsComponent, [i7.NgbModal, i9.ToastrService, i10.ModalActionsService, i11.EventsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InteractiveEventsComponentNgFactory = i1.ɵccf("app-interactive-events", i8.InteractiveEventsComponent, View_InteractiveEventsComponent_Host_0, { selectedRace: "selectedRace", selectedFixture: "selectedFixture" }, {}, []);
export { InteractiveEventsComponentNgFactory as InteractiveEventsComponentNgFactory };
