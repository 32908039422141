<app-navbar *ngIf="meta.viewMode === 'view'"></app-navbar>
<form #scaleForm="ngForm">
  <div class="modal-header d-flex justify-content-center">
    <h4 class="modal-title">COMMENT DETAILS {{meta.title | uppercase}}</h4>
    &nbsp;<a *ngIf="meta.viewMode === 'modal'" [routerLink]="['/comments', comment.id]" target="_blank"><fa name="external-link"></fa></a>
  </div>
  <div class="modal-body" *ngIf="meta.loading">
    <div class="row">
      <div class="col text-center">
        <fa name="circle-o-notch" size="2x" animation="spin"></fa>
      </div>
    </div>
  </div>
  <div class="modal-body" *ngIf="!meta.loading">
    <div class="row" *ngIf="comment.rpcComments && comment.rpcComments.length">
      <div class="col text-center">
        <span *ngFor="let rpcComment of comment.rpcComments; last as lastRPC">
          {{rpcComment.racePerformanceComment}}
          <br *ngIf="!lastRPC"/>
        </span>
      </div>
    </div>
    <div class="row" [ngClass]="{'pt-4': comment.rpcComments}" *ngIf="comment.comment">
      <div class="col text-center">
        <p>{{comment.comment}}</p>
      </div>
    </div>
    <hr>
    <div class="row pt-4">
      <div class="col text-right">
        <div class="d-flex flex-md-column">
          <small *ngIf="comment.published"><strong>This comment is published on the Stewards Report</strong></small>
          <small *ngIf="comment.createdByUserName"><i>Created by {{comment.createdByUserName}} @ {{comment.createdAt | date:'dd MMMM, yy h:mm'}}</i></small>
          <small *ngIf="comment.isUpdated"><i>Updated by {{comment.updatedByUserName}} @ {{comment.updatedAt | date:'dd MMMM, yy h:mm'}}</i></small>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!meta.saving" class="modal-footer d-flex justify-content-around">
    <button type="button" class="btn btn-outline-danger" (click)="delete()">Delete</button>
    <button type="button" class="btn btn-outline-dark" *ngIf="meta.viewMode === 'modal'" (click)="cancel('cancel')">Close</button>
    <button type="button" class="btn btn-primary" [disabled]="!comment.comment && !comment.rpcComments" (click)="openEdit()">Edit</button>
  </div>
  <div *ngIf="meta.saving" class="modal-footer d-flex justify-content-around">
    <h1>
      <fa name="circle-o-notch" animation="spin"></fa>
    </h1>
  </div>
</form>
