

<div class="container-fluid">

  <div class="row">
    <div class="col col-md-4 offset-md-4">
      <img src="assets/brand_black.svg" class="d-block ml-auto mr-auto">
      <h1 class="text-center font-weight-bold mt-3 mb-3">STEWARDS</h1>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col col-md-4 offset-md-4">
      <h4>Authentication Required</h4>
    </div>
  </div>
  <div class="row">
    <div class="col col-md-4 offset-md-4">
      <form #loginForm="ngForm">
        <div class="form-group">
          <label class="font-weight-bold" for="username">Username</label>
          <input type="text" class="form-control" id="username" name="username" autocomplete="off" [autofocus]="true" [(ngModel)]="authData.username">
        </div>
        <div class="form-group">
          <label class="font-weight-bold"  for="password">RSA Auth</label>
          <input type="password" class="form-control" id="password" name="password" [(ngModel)]="authData.password">
        </div>
        <button [disabled]="!authData.username.length || !authData.password.length" type="submit" (click)="doLogin()" class="btn btn-primary btn-block">Login</button>
      </form>
    </div>
  </div>
</div>
