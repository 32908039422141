/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./jockey-change-history.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/angular-font-awesome/dist/angular-font-awesome.ngfactory";
import * as i3 from "angular-font-awesome";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./jockey-change-history.component";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "ngx-toastr";
import * as i9 from "../../../services/events.service";
import * as i10 from "../../../services/modals.service";
var styles_JockeyChangeHistoryComponent = [i0.styles];
var RenderType_JockeyChangeHistoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_JockeyChangeHistoryComponent, data: {} });
export { RenderType_JockeyChangeHistoryComponent as RenderType_JockeyChangeHistoryComponent };
function View_JockeyChangeHistoryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "fa", [["animation", "spin"], ["name", "circle-o-notch"], ["size", "2x"]], null, null, null, i2.View_AngularFontAwesomeComponent_0, i2.RenderType_AngularFontAwesomeComponent)), i1.ɵdid(4, 114688, null, 0, i3.AngularFontAwesomeComponent, [], { name: [0, "name"], size: [1, "size"], animation: [2, "animation"] }, null)], function (_ck, _v) { var currVal_0 = "circle-o-notch"; var currVal_1 = "2x"; var currVal_2 = "spin"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_JockeyChangeHistoryComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 2), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, "/jockeys", _v.parent.context.$implicit.newJockeyId); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = (_v.parent.context.$implicit.newJockeyKnownAs || "Not Available"); _ck(_v, 4, 0, currVal_3); }); }
function View_JockeyChangeHistoryComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Not Available"]))], null, null); }
function View_JockeyChangeHistoryComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent, 1), _v.parent.context.$implicit.updatedAt, "dd MMM, yyyy - h:mm")); _ck(_v, 1, 0, currVal_0); }); }
function View_JockeyChangeHistoryComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAddEnquiry(_v.parent.context.$implicit.newJockeyId, _v.parent.context.$implicit.newJockeyKnownAs) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Open Enquiry"]))], null, function (_ck, _v) { var currVal_0 = !_v.parent.context.$implicit.newJockeyId; _ck(_v, 0, 0, currVal_0); }); }
function View_JockeyChangeHistoryComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Enquiry Created successfully."])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Check the Jockey's profile for details"]))], null, null); }
function View_JockeyChangeHistoryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "li", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JockeyChangeHistoryComponent_4)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JockeyChangeHistoryComponent_5)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JockeyChangeHistoryComponent_6)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "i", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JockeyChangeHistoryComponent_7)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JockeyChangeHistoryComponent_8)), i1.ɵdid(14, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.newJockeyId; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.newJockeyId; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.updatedAt; _ck(_v, 6, 0, currVal_2); var currVal_4 = !_v.context.$implicit.enquiryCreated; _ck(_v, 12, 0, currVal_4); var currVal_5 = _v.context.$implicit.enquiryCreated; _ck(_v, 14, 0, currVal_5); }, function (_ck, _v) { var currVal_3 = (_v.context.$implicit.comment || "No Comment Available"); _ck(_v, 9, 0, currVal_3); }); }
function View_JockeyChangeHistoryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "modal-body"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "scrollable-fullpage": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "offset-3 col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "ul", [["class", "timeline"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JockeyChangeHistoryComponent_3)), i1.ɵdid(7, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "modal-body"; var currVal_1 = _ck(_v, 2, 0, (_co.meta.viewMode === "view")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.jockeyChanges; _ck(_v, 7, 0, currVal_2); }, null); }
function View_JockeyChangeHistoryComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-outline-dark"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel("cancel") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], null, null); }
function View_JockeyChangeHistoryComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-footer d-flex justify-content-around"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JockeyChangeHistoryComponent_10)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.meta.viewMode === "modal"); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_JockeyChangeHistoryComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.UpperCasePipe, []), i1.ɵpid(0, i5.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "modal-header d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["JOCKEY CHANGE HISTORY FOR ", ""])), i1.ɵppd(5, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JockeyChangeHistoryComponent_1)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JockeyChangeHistoryComponent_2)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JockeyChangeHistoryComponent_9)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.meta.loading; _ck(_v, 7, 0, currVal_1); var currVal_2 = !_co.meta.loading; _ck(_v, 9, 0, currVal_2); var currVal_3 = !_co.meta.saving; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), _co.meta.title)); _ck(_v, 4, 0, currVal_0); }); }
export function View_JockeyChangeHistoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-jockey-change-history", [], null, null, null, View_JockeyChangeHistoryComponent_0, RenderType_JockeyChangeHistoryComponent)), i1.ɵdid(1, 114688, null, 0, i6.JockeyChangeHistoryComponent, [i7.NgbActiveModal, i7.NgbModal, i8.ToastrService, i9.EventsService, i10.ModalsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var JockeyChangeHistoryComponentNgFactory = i1.ɵccf("app-jockey-change-history", i6.JockeyChangeHistoryComponent, View_JockeyChangeHistoryComponent_Host_0, { entry: "entry" }, {}, []);
export { JockeyChangeHistoryComponentNgFactory as JockeyChangeHistoryComponentNgFactory };
