import { Component, OnInit, Input } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../../services/events.service';
import { ModalsService } from '../../../services/modals.service';

import { AddEditEnquiryComponent } from '../actions/add-edit-enquiry/add-edit-enquiry.component';

@Component({
  selector: 'app-jockey-change-history',
  templateUrl: './jockey-change-history.component.html',
  styleUrls: ['./jockey-change-history.component.scss']
})
export class JockeyChangeHistoryComponent implements OnInit {

  @Input() entry;
  jockeyChanges: any[];
  meta: {
    title: string,
    viewMode: string,
    loading: boolean,
    saving: boolean
  };
  userId: number;

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private notification: ToastrService,
    private eventsService: EventsService,
    private modalsService: ModalsService,
  ) { }

  ngOnInit() {
    this.meta = {
      title: '',
      viewMode: 'modal',
      loading: false,
      saving: false
    };

    this.eventsService.observableUser.subscribe(data => {
      this.userId = data.id;
    });

    this.prepareModal();
  }

  prepareModal = () => {
    this.meta.title = this.entry.horseName;
    this.jockeyChanges = this.entry.jockeyChangeHistory.slice(0);
    this.jockeyChanges.unshift({
      newJockeyId: this.entry.jockeyChangeHistory[0].oldJockeyId,
      newJockeyKnownAs: this.entry.jockeyChangeHistory[0].oldJockeyKnownAs,
      reason: 'Was the initially declared Jockey'
    });
    console.log(this.entry, this.jockeyChanges);
  }

  openAddEnquiry = (jockeyId, jockeyKnownAs) => {
    const possibleOffenders = [
      {
        id: jockeyId,
        knownAs: jockeyKnownAs,
        type: 'jockey',
        jockeyMasterId: null,
        jockeyMasterName: null,
        jockeyMasterInvolved: false
      }
    ];
    const enquiry = {
      meta: {
        year: this.entry.year,
        raceId: this.entry.raceId,
        divisionSequence: this.entry.divisionSequence,
        horseId: this.entry.horseId
      },
      offender: possibleOffenders[0]
    };
    const modalWindow = this.modalService
    .open(AddEditEnquiryComponent, { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.enquiry = enquiry;
    modalWindow.componentInstance.possibleOffenders = possibleOffenders;
    modalWindow.result.then((result) => {
      this.notification.info(`Enquiry successfully created against ${jockeyKnownAs}`);
      this.jockeyChanges.find(jc => jc.newJockeyId === jockeyId).enquiryCreated = true;
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  cancel = (action) => {
    this.activeModal.dismiss(action);
  }

}

