<app-navbar></app-navbar>
<div *ngIf="meta.loading">
  <div class="row">
    <div class="col-12 col-md-4 offset-md-4 text-center">
      <img src="assets/loader.svg" class="d-block ml-auto mr-auto" alt="spinner">
    </div>
  </div>
</div>
<div *ngIf="!meta.loading">
  <div class="container-fluid">
  <div class="row mb-5 mt-5 align-middle">
    <div class="col">
      <h2 class="font-weight-bold">{{horse.name | uppercase}}</h2>
      <h5>{{horse.age}}YO {{horse.colour | uppercase}} {{horse.sex | uppercase}}</h5>
    </div>
    <div class="col-2 text-right">
      <button type="button" class="btn btn-primary" (click)="openAddEnquiry(horse)">
        <span class="d-none d-md-block">Generic Enquiry</span>
      </button>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col col-sm-4 col-md-2 ">
      <strong>Foaled on:</strong>
    </div>
    <div class="col col-sm-6 col-md-10">
      <span>{{horse.dateOfBirth | date:'mediumDate'}}</span>
      <span *ngIf="horse.dateOfDeath">(Died on {{horse.dateOfDeath | date:'mediumDate'}})</span>
      <span *ngIf="horse.dam"> from {{horse.dam}}</span>
      <span *ngIf="horse.sire"> - {{horse.sire}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col col-sm-4 col-md-2">
      <strong>Passport:</strong>
    </div>
    <div class="col col-sm-6 col-md-10">
      <span>{{horse.passportNumber || 'N/A'}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col col-sm-4 col-md-2">
      <strong>Microchip(s):</strong>
    </div>
    <div class="col col-sm-6 col-md-10">
      <span *ngFor="let microchip of horse.microchipNumbers; last as lastMicrochip">
        {{microchip}}
        <span *ngIf="!lastMicrochip">, </span>
      </span>
      <span *ngIf="!horse.microchipNumbers.length">N/A</span>
    </div>
  </div>
  <div class="row">
      <div class="col col-sm-4 col-md-2">
        <strong>Shoes:</strong>
      </div>
      <div class="col col-sm-6 col-md-10">
        <button
          class="btn btn-link text-tooltip p-0 m-0"
          placement="auto"
          container="body"
          triggers="click:blur"
          [ngbTooltip]="shoesDispensationHistory">
          <span *ngIf="horse.dispensation.length">{{horse.dispensation[0].dispensation}}</span>
          <span *ngIf="!horse.dispensation.length">Not Set</span>
        </button>
        <button (click)="openAddDispensation()" class="btn btn-link text-primary py-0 my-0">
          <fa name="plus-square"></fa>
        </button>
      </div>
    </div>
  <br>

  <!-- PreviousForms, Enquiries, Comments -->
  <ngb-tabset #horseAdditionals="ngbTabset" [activeId]="meta.selectedTab" (tabChange)="selectTab($event.nextId)">
    <ngb-tab id="previous-form" title="Previous Form" [disabled]="meta.loading">
      <ng-template ngbTabContent>
          <app-horse-previous-forms [horse]="horse"></app-horse-previous-forms>
      </ng-template>
    </ngb-tab>
    <ngb-tab id="enquiries" title="Enquiries" [disabled]="meta.loading">
      <ng-template ngbTabContent>
        <app-enquiry-search [entity]="horse" entityType="horse" (reloaded)="enquiriesReloaded($event)"></app-enquiry-search>
      </ng-template>
    </ngb-tab>
    <ngb-tab id="comments" title="Comments" [disabled]="meta.loading">
      <ng-template ngbTabContent>
        <app-horse-comments [horse]="horse"></app-horse-comments>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
  </div>
</div>

<ng-template #shoesDispensationHistory>
    <table class="table table-sm table-striped">
      <thead>
        <tr>
          <th scope="col">Date</th>
          <th class="text-left" scope="col">Dispensation</th>          
          <th class="text-left" scope="col">By</th>
        </tr>
      </thead>
      <tbody *ngIf="horse.dispensation.length">
        <tr *ngFor="let item of horse.dispensation">
          <td>{{item.createdAt | date:'dd/MM/yy'}}</td>
          <td class="text-left">{{item.dispensation}}</td>
          <td class="text-left">{{item.createdBy}}</td>
        </tr>
      </tbody>
    </table>
  </ng-template>
