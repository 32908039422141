import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { FixturesComponent } from './components/fixtures/fixtures.component';
import { FixtureSamplingComponent } from './components/fixtures/fixture-sampling/fixture-sampling.component';
import { FixtureRacecardsComponent } from './components/fixtures/fixture-racecards/fixture-racecards.component';
import { FixtureReportComponent } from './components/fixtures/fixture-report/fixture-report.component';
import { RacesComponent } from './components/races/races.component';
import { SearchComponent } from './components/search/search.component';
import { HorseComponent } from './components/horse/horse.component';
import { JockeyComponent } from './components/jockey/jockey.component';
import { TrainerComponent } from './components/trainer/trainer.component';
import { OwnerComponent } from './components/owner/owner.component';
import { CommentComponent } from './components/modals/comment/comment.component';
import { EnquiryComponent } from './components/modals/enquiry/enquiry.component';

import { CanActivateGuard } from './guards/can-activate.guard';

const routes: Routes = [
  { path: '', redirectTo: '/fixtures', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'search', component: SearchComponent, canActivate: [CanActivateGuard] },
  { path: 'logout', component: LogoutComponent, canActivate: [CanActivateGuard] },
  { path: 'fixtures', component: FixturesComponent, canActivate: [CanActivateGuard] },
  { path: 'fixtures/:fixtureYear/:fixtureId/sampling', component: FixtureSamplingComponent, canActivate: [CanActivateGuard] },
  { path: 'fixtures/:fixtureYear/:fixtureId/racecards', component: FixtureRacecardsComponent, canActivate: [CanActivateGuard] },
  { path: 'fixtures/:fixtureYear/:fixtureId/report', component: FixtureReportComponent, canActivate: [CanActivateGuard] },
  { path: 'races/:fixtureYear/:fixtureId', component: RacesComponent, canActivate: [CanActivateGuard] },
  { path: 'races/:fixtureYear/:fixtureId/:raceId/:divisionSequence', component: RacesComponent, canActivate: [CanActivateGuard] },
  { path: 'horses/:horseId', component: HorseComponent, canActivate: [CanActivateGuard] },
  { path: 'horses/:horseId/:tab', component: HorseComponent, canActivate: [CanActivateGuard] },
  { path: 'jockeys/:jockeyId', component: JockeyComponent, canActivate: [CanActivateGuard] },
  { path: 'jockeys/:jockeyId/:tab', component: JockeyComponent, canActivate: [CanActivateGuard] },
  { path: 'trainers/:trainerId', component: TrainerComponent, canActivate: [CanActivateGuard] },
  { path: 'trainers/:trainerId/:tab', component: TrainerComponent, canActivate: [CanActivateGuard] },
  { path: 'owners/:ownerId', component: OwnerComponent, canActivate: [CanActivateGuard] },
  { path: 'owners/:ownerId/:tab', component: OwnerComponent, canActivate: [CanActivateGuard] },
  { path: 'comments/:commentId', component: CommentComponent, canActivate: [CanActivateGuard] },
  { path: 'enquiries/:enquiryId', component: EnquiryComponent, canActivate: [CanActivateGuard] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})

export class AppRoutingModule { }
