<app-navbar *ngIf="meta.viewMode === 'view'"></app-navbar>
<div class="modal-header d-flex justify-content-center">
  <h4 class="modal-title">ENQUIRY #{{enquiry.enquiryId}} - {{enquiry.date | date:'dd/MM/yyyy'}}</h4>
  &nbsp;<a *ngIf="meta.viewMode === 'modal'" [routerLink]="['/enquiries', enquiry.enquiryId]" target="_blank"><fa name="external-link"></fa></a>
</div>
<div class="modal-body" *ngIf="meta.loading">
  <div class="row">
    <div class="col text-center">
      <fa name="circle-o-notch" size="2x" animation="spin"></fa>
    </div>
  </div>
</div>
<div class="modal-body" *ngIf="!meta.loading">
  <ngb-tabset [activeId]="meta.activeStage">
    <ngb-tab id="racecourse" title="Racecourse" [disabled]="!enquiry.stages.racecourse">
      <ng-template ngbTabContent>
        <div class="row">
          <div class="col text-center">
            Category<br>{{enquiry.offenceCategory | titlecase}}
          </div>
          <div class="col text-center">
            Status<br>
            <span *ngIf="enquiry.stages.racecourse.published === -1">Draft</span>
            <span *ngIf="enquiry.stages.racecourse.published === 0">Unpublished</span>
            <span *ngIf="enquiry.stages.racecourse.published === 1">Published</span>
          </div>
        </div>
        <div *ngIf="enquiry.race">
          <hr>
          <div class="row">
            <div class="col text-center">
              Race Date<br>
              {{enquiry.race.dateTime | date:'dd/MM/yyyy'}}
            </div>
            <div class="col text-center">
              Race Time<br>
              {{enquiry.race.dateTime | date:'h:mm'}}
            </div>
            <div class="col text-center">
              Race Course<br>
              {{enquiry.race.courseName}}
            </div>
            <div class="col text-center">
              Total Value<br>
              {{enquiry.race.totalRaceValue | currency:'GBP'}}
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col text-center">
              Race Name<br>
              <a [routerLink]="['/races', enquiry.race.year, enquiry.race.raceId, enquiry.race.divisionSequence]" target="_blank">{{enquiry.race.name}}</a>
            </div>
          </div>
          <hr>
        </div>
        <div class="row">
          <div class="col text-center">
            <span>Jockey</span>
            <strong *ngIf="enquiry.offender.type === 'jockey'"> (offender)</strong>
            <br>
            <a *ngIf="enquiry.jockey" [routerLink]="['/jockeys', enquiry.jockey.jockeyId]" target="_blank">{{enquiry.jockey.knownAs}}</a>
            <span *ngIf="!enquiry.jockey">N/A</span>
          </div>
          <div class="col text-center">
            <span>Horse</span>
            <strong *ngIf="enquiry.offender.type === 'horse'"> (offender)</strong>
            <br>
            <a *ngIf="enquiry.horse" [routerLink]="['/horses', enquiry.horse.horseId]" target="_blank">{{enquiry.horse.name}}</a>
            <span *ngIf="!enquiry.horse">N/A</span>
          </div>
          <div class="col text-center">
            <span>Trainer</span>
            <strong *ngIf="enquiry.offender.type === 'trainer'"> (offender)</strong>
            <br>
            <a *ngIf="enquiry.trainer" [routerLink]="['/trainers', enquiry.trainer.trainerId]" target="_blank">{{enquiry.trainer.knownAs}}</a>
            <span *ngIf="!enquiry.trainer">N/A</span>
          </div>
          <div class="col text-center">
            <span>Owner</span>
            <strong *ngIf="enquiry.offender.type === 'owner'"> (offender)</strong>
            <br>
            <a *ngIf="enquiry.owner" [routerLink]="['/owners', enquiry.owner.ownerId]" target="_blank">{{enquiry.owner.knownAs}}</a>
            <span *ngIf="!enquiry.owner">N/A</span>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col text-center">
            <strong>Rule(s)</strong>
            <br>
            <span *ngIf="enquiry.stages.racecourse.offence.rule">{{enquiry.stages.racecourse.offence.rule.rule}}</span>
            <span *ngIf="enquiry.stages.racecourse.offence.rule.relatedRule"><br>{{enquiry.stages.racecourse.offence.rule.relatedRule}}</span>
          </div>
          <div class="col text-center">
            <strong>Offence</strong>
            <br>
            <span *ngIf="enquiry.stages.racecourse.offence.offence">{{enquiry.stages.racecourse.offence.offence}}</span>
            <span *ngIf="enquiry.stages.racecourse.offence.subOffence">({{enquiry.stages.racecourse.offence.subOffence}})</span>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col d-flex flex-column align-items-center justify-content-center">
            <strong>Outcome(s)</strong>
            <strong>{{enquiry.stages.racecourse.breachStatus | titlecase}}</strong>
            <app-enquiry-outcomes
              [enquiry]="enquiry.stages.racecourse"
              [detailed]="true">
            </app-enquiry-outcomes>
          </div>
        </div>
        <div class="row" *ngIf="enquiry.stages.racecourse.ptcComment && enquiry.stages.racecourse.ptcComment.comment">
          <div class="col text-center">
            <br>
            <strong>Internal Comment</strong>
            <p class="text-center">{{enquiry.stages.racecourse.ptcComment.comment}}</p>
          </div>
        </div>
        <div class="row" *ngIf="enquiry.stages.racecourse.notice">
          <div class="col text-center">
            <br>
            <strong>Notice</strong>
            <p class="text-center">{{enquiry.stages.racecourse.notice}}</p>
          </div>
        </div>
        <div class="row" *ngIf="enquiry.stages.racecourse.cameraStartTime">
          <div class="col text-center">
            <strong>Camera Timeframe: {{enquiry.stages.racecourse.cameraStartTime}} - {{enquiry.stages.racecourse.cameraStopTime}}</strong>
          </div>
        </div>
        <div class="row" *ngIf="enquiry.stages.racecourse.cameraAngles && enquiry.stages.racecourse.cameraAngles.length">
          <div class="col text-center">
            <span *ngFor="let angle of enquiry.stages.racecourse.cameraAngles; last as lastAngle">
              {{angle.description}} ({{angle.title}})
              <span *ngIf="!lastAngle">, </span>
            </span>
          </div>
        </div>
        <hr>
        <div class="row pt-4">
          <div class="col text-right">
            <div class="d-flex flex-md-column">
              <small *ngIf="enquiry.stages.racecourse.createdByUserName"><i>Created by {{enquiry.stages.racecourse.createdByUserName}} @ {{enquiry.stages.racecourse.createdAt | date:'dd MMMM, yy h:mm'}}</i></small>
              <small *ngIf="enquiry.stages.racecourse.isUpdated"><i>Updated by {{enquiry.stages.racecourse.updatedByUserName}} @ {{enquiry.stages.racecourse.updatedAt | date:'dd MMMM, yy h:mm'}}</i></small>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-tab>
    <ngb-tab id="dp_committee" title="Disciplinary" [disabled]="!enquiry.stages.dp_committee">
      <ng-template ngbTabContent>
        <div class="row">
          <div class="col text-center">
            Category<br>{{enquiry.offenceCategory | titlecase}}
          </div>
          <div class="col text-center">
            Status<br>
            <span *ngIf="enquiry.stages.dp_committee.published === -1">Draft</span>
            <span *ngIf="enquiry.stages.dp_committee.published === 0">Unpublished</span>
            <span *ngIf="enquiry.stages.dp_committee.published === 1">Published</span>
          </div>
        </div>
        <div *ngIf="enquiry.race">
          <hr>
          <div class="row">
            <div class="col text-center">
              Race Date<br>
              {{enquiry.race.dateTime | date:'dd/MM/yyyy'}}
            </div>
            <div class="col text-center">
              Race Time<br>
              {{enquiry.race.dateTime | date:'h:mm'}}
            </div>
            <div class="col text-center">
              Race Course<br>
              {{enquiry.race.courseName}}
            </div>
            <div class="col text-center">
              Total Value<br>
              {{enquiry.race.totalRaceValue | currency:'GBP'}}
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col text-center">
              Race Name<br>
              <a [routerLink]="['/races', enquiry.race.year, enquiry.race.raceId, enquiry.race.divisionSequence]" target="_blank">{{enquiry.race.name}}</a>
            </div>
          </div>
          <hr>
        </div>
        <div class="row">
          <div class="col text-center">
            <span>Jockey</span>
            <strong *ngIf="enquiry.type === 'jockey'"> (offender)</strong>
            <br>
            <a *ngIf="enquiry.jockey" [routerLink]="['/jockeys', enquiry.jockey.jockeyId]" target="_blank">{{enquiry.jockey.knownAs}}</a>
            <span *ngIf="!enquiry.jockey">N/A</span>
          </div>
          <div class="col text-center">
            <span>Horse</span>
            <strong *ngIf="enquiry.type === 'horse'"> (offender)</strong>
            <br>
            <a *ngIf="enquiry.horse" [routerLink]="['/horses', enquiry.horse.horseId]" target="_blank">{{enquiry.horse.name}}</a>
            <span *ngIf="!enquiry.horse">N/A</span>
          </div>
          <div class="col text-center">
            <span>Trainer</span>
            <strong *ngIf="enquiry.type === 'trainer'"> (offender)</strong>
            <br>
            <a *ngIf="enquiry.trainer" [routerLink]="['/trainers', enquiry.trainer.trainerId]" target="_blank">{{enquiry.trainer.knownAs}}</a>
            <span *ngIf="!enquiry.trainer">N/A</span>
          </div>
          <div class="col text-center">
            <span>Owner</span>
            <strong *ngIf="enquiry.type === 'owner'"> (offender)</strong>
            <br>
            <a *ngIf="enquiry.owner" [routerLink]="['/owners', enquiry.owner.ownerId]" target="_blank">{{enquiry.owner.knownAs}}</a>
            <span *ngIf="!enquiry.owner">N/A</span>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col text-center">
            <strong>Rule(s)</strong>
            <br>
            <span *ngIf="enquiry.stages.dp_committee.offence.rule">{{enquiry.stages.dp_committee.offence.rule.rule}}</span>
            <span *ngIf="enquiry.stages.dp_committee.offence.rule.relatedRule"><br>{{enquiry.stages.dp_committee.offence.rule.relatedRule}}</span>
          </div>
          <div class="col text-center">
            <strong>Offence</strong>
            <br>
            <span *ngIf="enquiry.stages.dp_committee.offence.offence">{{enquiry.stages.dp_committee.offence.offence}}</span>
            <span *ngIf="enquiry.stages.dp_committee.offence.subOffence">({{enquiry.stages.dp_committee.offence.subOffence}})</span>
          </div>
        </div>
        <hr>
        <div class="row" *ngIf="enquiry.stages.dp_committee.breachStatus === 'in breach'">
          <div class="col text-center">
            <strong>Outcome(s)</strong>
            <br>
            <app-enquiry-outcomes [enquiry]="enquiry.stages.dp_committee" [detailed]="true"></app-enquiry-outcomes>
          </div>
        </div>
        <div class="row" *ngIf="enquiry.stages.dp_committee.breachStatus === 'not in breach'">
          <div class="col text-center">
            <strong>Outcome(s)</strong>
            <p>{{enquiry.stages.dp_committee.breachStatus | titlecase}}</p>
          </div>
        </div>
        <div class="row" *ngIf="enquiry.stages.dp_committee.ptcComment && enquiry.stages.dp_committee.ptcComment.comment">
          <div class="col text-center">
            <br>
            <strong>Internal Comment</strong>
            <p class="text-center">{{enquiry.stages.dp_committee.ptcComment.comment}}</p>
          </div>
        </div>
        <div class="row" *ngIf="enquiry.stages.dp_committee.notice">
          <div class="col text-center">
            <br>
            <strong>Notice</strong>
            <p class="text-center">{{enquiry.stages.dp_committee.notice}}</p>
          </div>
        </div>
        <div class="row" *ngIf="enquiry.stages.dp_committee.cameraStartTime">
          <div class="col text-center">
            <strong>Camera Timeframe: {{enquiry.stages.dp_committee.cameraStartTime}} - {{enquiry.stages.dp_committee.cameraStopTime}}</strong>
          </div>
        </div>
        <div class="row" *ngIf="enquiry.stages.dp_committee.cameraAngles && enquiry.stages.dp_committee.cameraAngles.length">
          <div class="col text-center">
            <span *ngFor="let angle of enquiry.stages.dp_committee.cameraAngles; last as lastAngle">
              {{angle.description}} ({{angle.title}})
              <span *ngIf="!lastAngle">, </span>
            </span>
          </div>
        </div>
        <hr>
        <div class="row pt-4">
          <div class="col text-right">
            <div class="d-flex flex-md-column">
              <small *ngIf="enquiry.stages.dp_committee.createdByUserName"><i>Created by {{enquiry.stages.dp_committee.createdByUserName}} @ {{enquiry.stages.dp_committee.createdAt | date:'dd MMMM, yy h:mm'}}</i></small>
              <small *ngIf="enquiry.stages.dp_committee.isUpdated"><i>Updated by {{enquiry.stages.dp_committee.updatedByUserName}} @ {{enquiry.stages.dp_committee.updatedAt | date:'dd MMMM, yy h:mm'}}</i></small>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-tab>
    <ngb-tab id="appeal" title="Appeal" [disabled]="!enquiry.stages.appeal">
      <ng-template ngbTabContent>
        <div class="row">
          <div class="col text-center">
            Category<br>{{enquiry.offenceCategory | titlecase}}
          </div>
          <div class="col text-center">
            Status<br>
            <span *ngIf="enquiry.stages.appeal.published === -1">Draft</span>
            <span *ngIf="enquiry.stages.appeal.published === 0">Unpublished</span>
            <span *ngIf="enquiry.stages.appeal.published === 1">Published</span>
          </div>
        </div>
        <div *ngIf="enquiry.race">
          <hr>
          <div class="row">
            <div class="col text-center">
              Race Date<br>
              {{enquiry.race.dateTime | date:'dd/MM/yyyy'}}
            </div>
            <div class="col text-center">
              Race Time<br>
              {{enquiry.race.dateTime | date:'h:mm'}}
            </div>
            <div class="col text-center">
              Race Course<br>
              {{enquiry.race.courseName}}
            </div>
            <div class="col text-center">
              Total Value<br>
              {{enquiry.race.totalRaceValue | currency:'GBP'}}
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col text-center">
              Race Name<br>
              <a [routerLink]="['/races', enquiry.race.year, enquiry.race.raceId, enquiry.race.divisionSequence]" target="_blank">{{enquiry.race.name}}</a>
            </div>
          </div>
          <hr>
        </div>
        <div class="row">
          <div class="col text-center">
            <span>Jockey</span>
            <strong *ngIf="enquiry.offender.type === 'jockey'"> (offender)</strong>
            <br>
            <a *ngIf="enquiry.jockey" [routerLink]="['/jockeys', enquiry.jockey.jockeyId]" target="_blank">{{enquiry.jockey.knownAs}}</a>
            <span *ngIf="!enquiry.jockey">N/A</span>
          </div>
          <div class="col text-center">
            <span>Horse</span>
            <strong *ngIf="enquiry.offender.type === 'horse'"> (offender)</strong>
            <br>
            <a *ngIf="enquiry.horse" [routerLink]="['/horses', enquiry.horse.horseId]" target="_blank">{{enquiry.horse.name}}</a>
            <span *ngIf="!enquiry.horse">N/A</span>
          </div>
          <div class="col text-center">
            <span>Trainer</span>
            <strong *ngIf="enquiry.offender.type === 'trainer'"> (offender)</strong>
            <br>
            <a *ngIf="enquiry.trainer" [routerLink]="['/trainers', enquiry.trainer.trainerId]" target="_blank">{{enquiry.trainer.knownAs}}</a>
            <span *ngIf="!enquiry.trainer">N/A</span>
          </div>
          <div class="col text-center">
            <span>Owner</span>
            <strong *ngIf="enquiry.offender.type === 'owner'"> (offender)</strong>
            <br>
            <a *ngIf="enquiry.owner" [routerLink]="['/owners', enquiry.owner.ownerId]" target="_blank">{{enquiry.owner.knownAs}}</a>
            <span *ngIf="!enquiry.owner">N/A</span>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col text-center">
            <strong>Rule(s)</strong>
            <br>
            <span *ngIf="enquiry.stages.appeal.offence.rule">{{enquiry.stages.appeal.offence.rule.rule}}</span>
            <span *ngIf="enquiry.stages.appeal.offence.rule.relatedRule"><br>{{enquiry.stages.appeal.offence.rule.relatedRule}}</span>
          </div>
          <div class="col text-center">
            <strong>Offence</strong>
            <br>
            <span *ngIf="enquiry.stages.appeal.offence.offence">{{enquiry.stages.appeal.offence.offence}}</span>
            <span *ngIf="enquiry.stages.appeal.offence.subOffence">({{enquiry.stages.appeal.offence.subOffence}})</span>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col text-center">
            <strong>Appeal Conclusion</strong>
            <p *ngIf="enquiry.stages.appeal.appealConclusion">{{enquiry.stages.appeal.appealText | titlecase}}</p>
            <p *ngIf="!enquiry.stages.appeal.appealConclusion"> - </p>
          </div>
          <div class="col text-center">
            <strong>Appeal Deposit</strong>
            <p *ngIf="enquiry.stages.appeal.deposit">{{enquiry.stages.appeal.deposit | titlecase}}</p>
            <p *ngIf="!enquiry.stages.appeal.deposit"> - </p>
          </div>
        </div>
        <hr>
        <div class="row" *ngIf="enquiry.stages.appeal.breachStatus === 'in breach'">
          <div class="col text-center">
            <strong>Outcome(s)</strong>
            <br>
            <app-enquiry-outcomes [enquiry]="enquiry.stages.appeal" [detailed]="true"></app-enquiry-outcomes>
          </div>
        </div>
        <div class="row" *ngIf="enquiry.stages.appeal.breachStatus === 'not in breach'">
          <div class="col text-center">
            <strong>Outcome(s)</strong>
            <p>{{enquiry.stages.appeal.breachStatus | titlecase}}</p>
          </div>
        </div>
        <div class="row" *ngIf="enquiry.stages.appeal.ptcComment && enquiry.stages.appeal.ptcComment.comment">
          <div class="col text-center">
            <br>
            <strong>Internal Comment</strong>
            <p class="text-center">{{enquiry.stages.appeal.ptcComment.comment}}</p>
          </div>
        </div>
        <div class="row" *ngIf="enquiry.stages.appeal.notice">
          <div class="col text-center">
            <br>
            <strong>Notice</strong>
            <p class="text-center">{{enquiry.stages.appeal.notice}}</p>
          </div>
        </div>
        <div class="row" *ngIf="enquiry.stages.appeal.cameraStartTime">
          <div class="col text-center">
            <strong>Camera Timeframe: {{enquiry.stages.appeal.cameraStartTime}} - {{enquiry.stages.appeal.cameraStopTime}}</strong>
          </div>
        </div>
        <div class="row" *ngIf="enquiry.stages.appeal.cameraAngles && enquiry.stages.appeal.cameraAngles.length">
          <div class="col text-center">
            <span *ngFor="let angle of enquiry.stages.appeal.cameraAngles; last as lastAngle">
              {{angle.description}} ({{angle.title}})
              <span *ngIf="!lastAngle">, </span>
            </span>
          </div>
        </div>
        <hr>
        <div class="row pt-4">
          <div class="col text-right">
            <div class="d-flex flex-md-column">
              <small *ngIf="enquiry.stages.appeal.createdByUserName"><i>Created by {{enquiry.stages.appeal.createdByUserName}} @ {{enquiry.stages.appeal.createdAt | date:'dd MMMM, yy h:mm'}}</i></small>
              <small *ngIf="enquiry.stages.appeal.isUpdated"><i>Updated by {{enquiry.stages.appeal.updatedByUserName}} @ {{enquiry.stages.appeal.updatedAt | date:'dd MMMM, yy h:mm'}}</i></small>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-tab>
    <ngb-tab id="legal" title="Court" [disabled]="!enquiry.stages.legal">
      <ng-template ngbTabContent>
        <div class="row">
          <div class="col text-center">
            Category<br>{{enquiry.offenceCategory | titlecase}}
          </div>
          <div class="col text-center">
            Status<br>
            <span *ngIf="enquiry.stages.legal.published === -1">Draft</span>
            <span *ngIf="enquiry.stages.legal.published === 0">Unpublished</span>
            <span *ngIf="enquiry.stages.legal.published === 1">Published</span>
          </div>
        </div>
        <div *ngIf="enquiry.race">
          <hr>
          <div class="row">
            <div class="col text-center">
              Race Date<br>
              {{enquiry.race.dateTime | date:'dd/MM/yyyy'}}
            </div>
            <div class="col text-center">
              Race Time<br>
              {{enquiry.race.dateTime | date:'h:mm'}}
            </div>
            <div class="col text-center">
              Race Course<br>
              {{enquiry.race.courseName}}
            </div>
            <div class="col text-center">
              Total Value<br>
              {{enquiry.race.totalRaceValue | currency:'GBP'}}
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col text-center">
              Race Name<br>
              <a [routerLink]="['/races', enquiry.race.year, enquiry.race.raceId, enquiry.race.divisionSequence]" target="_blank">{{enquiry.race.name}}</a>
            </div>
          </div>
          <hr>
        </div>
        <div class="row">
          <div class="col text-center">
            <span>Jockey</span>
            <strong *ngIf="enquiry.offender.type === 'jockey'"> (offender)</strong>
            <br>
            <a *ngIf="enquiry.jockey" [routerLink]="['/jockeys', enquiry.jockey.jockeyId]" target="_blank">{{enquiry.jockey.knownAs}}</a>
            <span *ngIf="!enquiry.jockey">N/A</span>
          </div>
          <div class="col text-center">
            <span>Horse</span>
            <strong *ngIf="enquiry.offender.type === 'horse'"> (offender)</strong>
            <br>
            <a *ngIf="enquiry.horse" [routerLink]="['/horses', enquiry.horse.horseId]" target="_blank">{{enquiry.horse.name}}</a>
            <span *ngIf="!enquiry.horse">N/A</span>
          </div>
          <div class="col text-center">
            <span>Trainer</span>
            <strong *ngIf="enquiry.offender.type === 'trainer'"> (offender)</strong>
            <br>
            <a *ngIf="enquiry.trainer" [routerLink]="['/trainers', enquiry.trainer.trainerId]" target="_blank">{{enquiry.trainer.knownAs}}</a>
            <span *ngIf="!enquiry.trainer">N/A</span>
          </div>
          <div class="col text-center">
            <span>Owner</span>
            <strong *ngIf="enquiry.offender.type === 'owner'"> (offender)</strong>
            <br>
            <a *ngIf="enquiry.owner" [routerLink]="['/owners', enquiry.owner.ownerId]" target="_blank">{{enquiry.owner.knownAs}}</a>
            <span *ngIf="!enquiry.owner">N/A</span>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col text-center">
            <strong>Rule(s)</strong>
            <br>
            <span *ngIf="enquiry.stages.legal.offence.rule">{{enquiry.stages.legal.offence.rule.rule}}</span>
            <span *ngIf="enquiry.stages.legal.offence.rule.relatedRule"><br>{{enquiry.stages.legal.offence.rule.relatedRule}}</span>
          </div>
          <div class="col text-center">
            <strong>Offence</strong>
            <br>
            <span *ngIf="enquiry.stages.legal.offence.offence">{{enquiry.stages.legal.offence.offence}}</span>
            <span *ngIf="enquiry.stages.legal.offence.subOffence">({{enquiry.stages.legal.offence.subOffence}})</span>
          </div>
        </div>
        <hr>
        <div class="row" *ngIf="enquiry.stages.legal.breachStatus === 'in breach'">
          <div class="col text-center">
            <strong>Outcome(s)</strong>
            <br>
            <app-enquiry-outcomes [enquiry]="enquiry.stages.legal" [detailed]="true"></app-enquiry-outcomes>
          </div>
        </div>
        <div class="row" *ngIf="enquiry.stages.legal.breachStatus === 'not in breach'">
          <div class="col text-center">
            <strong>Outcome(s)</strong>
            <p>{{enquiry.stages.legal.breachStatus | titlecase}}</p>
          </div>
        </div>
        <div class="row" *ngIf="enquiry.stages.legal.ptcComment && enquiry.stages.legal.ptcComment.comment">
          <div class="col text-center">
            <br>
            <strong>Internal Comment</strong>
            <p class="text-center">{{enquiry.stages.legal.ptcComment.comment}}</p>
          </div>
        </div>
        <div class="row" *ngIf="enquiry.stages.legal.notice">
          <div class="col text-center">
            <br>
            <strong>Notice</strong>
            <p class="text-center">{{enquiry.stages.legal.notice}}</p>
          </div>
        </div>
        <div class="row" *ngIf="enquiry.stages.legal.cameraStartTime">
          <div class="col text-center">
            <strong>Camera Timeframe: {{enquiry.stages.legal.cameraStartTime}} - {{enquiry.stages.legal.cameraStopTime}}</strong>
          </div>
        </div>
        <div class="row" *ngIf="enquiry.stages.legal.cameraAngles && enquiry.stages.legal.cameraAngles.length">
          <div class="col text-center">
            <span *ngFor="let angle of enquiry.stages.legal.cameraAngles; last as lastAngle">
              {{angle.description}} ({{angle.title}})
              <span *ngIf="!lastAngle">, </span>
            </span>
          </div>
        </div>
        <hr>
        <div class="row pt-4">
          <div class="col text-right">
            <div class="d-flex flex-md-column">
              <small *ngIf="enquiry.stages.legal.createdByUserName"><i>Created by {{enquiry.stages.legal.createdByUserName}} @ {{enquiry.stages.legal.createdAt | date:'dd MMMM, yy h:mm'}}</i></small>
              <small *ngIf="enquiry.stages.legal.isUpdated"><i>Updated by {{enquiry.stages.legal.updatedByUserName}} @ {{enquiry.stages.legal.updatedAt | date:'dd MMMM, yy h:mm'}}</i></small>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>
<div *ngIf="!meta.saving" class="modal-footer d-flex justify-content-around">
  <button type="button" class="btn btn-outline-dark" *ngIf="meta.viewMode === 'modal'" (click)="cancel('cancel')">Close</button>
  <button type="button" class="btn btn-outline-danger" (click)="delete()">Delete</button>
  <button
    type="button"
    class="btn btn-primary"
    [disabled]="!enquiry.offence || (enquiry.stage !== 'racecourse' && !userIsDisciplinary)"
    (click)="openEdit()"
  >Edit</button>
  <button
    *ngIf="userIsDisciplinary"
    type="button"
    class="btn btn-warning"
    [disabled]="!enquiry.offence || enquiry.stage === 'legal'"
    (click)="openEdit(true)"
  >Next Stage</button>
</div>
<div *ngIf="meta.saving" class="modal-footer d-flex justify-content-around">
  <h1><fa name="circle-o-notch" animation="spin"></fa></h1>
</div>
