import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

import { ModalsService } from '../../../services/modals.service';
import { EventsService } from '../../../services/events.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-going-history',
  templateUrl: './going-history.component.html',
  styleUrls: ['./going-history.component.scss']
})
export class GoingHistoryComponent implements OnInit {

  @Input() fixture: any;
  goingHistory: Array<any>;
  declarationGoing: any;
  meta: {
    loading: Boolean;
    declarationNotFound: Boolean;
    showDifferences: Boolean;
  };
  constructor(
    private modalsService: ModalsService,
    private eventsService: EventsService,
    private activeModal: NgbActiveModal,
    private notification: ToastrService
  ) { }

  ngOnInit() {
    this.meta = {
      loading: false,
      declarationNotFound: false,
      showDifferences: false
    };
    this.getHistory();
  }

  switchMode(forceToValue) {
    if (forceToValue === undefined) {
      this.meta.showDifferences = !this.meta.showDifferences;
    } else {
      this.meta.showDifferences = forceToValue;
    }
  }

  getHistory() {
    this.meta.loading = true;
    this.modalsService.getGoingHistory(this.fixture).subscribe(
      res => {
        this.goingHistory = res ? res.map(item => item) : [];
        this.goingHistory.forEach(item => {
          item.going.conditions.groundDescription = this.getGroundDescription(item.going.conditions.ground).label;
          item.going.conditions.conditionInPlacesDescription = this.getGroundDescription(item.going.conditions.conditionInPlaces).label;
          item.going.tracks.forEach(track => {
            track.available = track.ground || track.conditionInPlaces || track.goingStickAvailable ? true : false;
            track.groundDescription = this.getGroundDescription(track.ground).label;
            track.conditionInPlacesDescription = this.getGroundDescription(track.conditionInPlaces).label;
          });
          item.going.tracks = item.going.tracks.filter(t => t.available);
        });
        const declarationGoingIndex = this.goingHistory.findIndex(item =>
          moment(item.createdAt).isBefore(moment(this.fixture.overnightDate))
        );
        if (declarationGoingIndex !== -1) {
          this.goingHistory[declarationGoingIndex].declarationGoing = true;
          this.declarationGoing = this.goingHistory[declarationGoingIndex];
          this.meta.declarationNotFound = false;
        } else {
          this.meta.declarationNotFound = true;
        }
        this.meta.loading = false;
        console.log('GOING Ready:', this.goingHistory);

      },
      err => {
        this.notification.error(`Error while requesting the Going History: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.loading = false;
      }
    );
  }

  cancel(action) {
    this.activeModal.dismiss(action);
  }

  getGroundDescription(id: Number): {id: Number, label: String} {
    const grounds = [
      // TURF:
      { id:  1, label: 'Hard', short: 'H'},
      { id:  2, label: 'Firm', short: 'F'},
      { id:  3, label: 'Good to Firm', short: 'G/F'},
      { id:  4, label: 'Good', short: 'G'},
      { id:  5, label: 'Good to Soft', short: 'G/S'},
      { id:  6, label: 'Soft', short: 'S'},
      { id:  7, label: 'Heavy', short: 'HY'},
      // AWT:
      { id: 10, label: 'Standard', short: 'ST'},
      { id: 8,  label: 'Fast', short: 'FS'},
      { id: 12, label: 'Slow', short: 'SL'},
      { id: 29, label: 'Standard to Fast', short: 'ST/FS'},
      { id: 30, label: 'Standard to Slow', short: 'ST/SL'},
      // BOTH:
      { id: 23, label: 'Frozen', short: 'FROZ'},
      // UNUSED:
      {id: 20, label: 'Yielding', short: 'Y'},
      {id: 21, label: 'Holding', short: 'HG'},
      {id: 22, label: 'Snow', short: 'SNOW'},
      {id: 24, label: 'Muddy', short: 'MUD'},
      {id: 25, label: 'Sloppy', short: 'SLOP'},
      {id: 26, label: 'Wet Fast', short: 'WFAST'},
      {id: 27, label: 'Sand', short: 'SAND'},
      {id: 28, label: 'Good to Yielding', short: 'G/Y'},
      {id: 31, label: 'Dead', short: 'D'},
      {id: 32, label: 'Wet', short: 'WET'},
      {id: 33, label: 'Easy', short: 'EASY'},
      {id: 34, label: 'Soft to Heavy', short: 'S/HY'},
      {id: 35, label: 'Yielding to Soft', short: 'Y/S'}
    ];
    if (typeof id === 'number') {
      return grounds.find(g => g.id === id);
    } else {
      return {id: Number(id), label: null};
    }
  }

}
