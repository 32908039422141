/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../../node_modules/angular-font-awesome/dist/angular-font-awesome.ngfactory";
import * as i5 from "angular-font-awesome";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "./navbar.component";
import * as i8 from "../../services/events.service";
import * as i9 from "../../services/authentication.service";
var styles_NavbarComponent = [i0.styles];
var RenderType_NavbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarComponent, data: {} });
export { RenderType_NavbarComponent as RenderType_NavbarComponent };
function View_NavbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [["class", "nav-item mr-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 1, "fa", [["class", "mr-3"], ["name", "search"]], null, null, null, i4.View_AngularFontAwesomeComponent_0, i4.RenderType_AngularFontAwesomeComponent)), i1.ɵdid(5, 114688, null, 0, i5.AngularFontAwesomeComponent, [], { name: [0, "name"] }, null), (_l()(), i1.ɵted(-1, null, ["Search"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 3, 0, "/search"); _ck(_v, 2, 0, currVal_2); var currVal_3 = "search"; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_NavbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["class", "nav-item mr-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "d-none d-md-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openExternalLink(((("https://crate.horseracing.software/media/pdf/stewardsreport/?fixtureId=" + _co.selectedFixture.fixtureId) + "&year=") + _co.selectedFixture.fixtureYear)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "fa", [["class", "mr-3"], ["name", "file"]], null, null, null, i4.View_AngularFontAwesomeComponent_0, i4.RenderType_AngularFontAwesomeComponent)), i1.ɵdid(3, 114688, null, 0, i5.AngularFontAwesomeComponent, [], { name: [0, "name"] }, null), (_l()(), i1.ɵted(-1, null, ["Stewards Report"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["class", "d-block d-md-none"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "fa", [["name", "file"]], null, null, null, i4.View_AngularFontAwesomeComponent_0, i4.RenderType_AngularFontAwesomeComponent)), i1.ɵdid(7, 114688, null, 0, i5.AngularFontAwesomeComponent, [], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = "file"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "file"; _ck(_v, 7, 0, currVal_1); }, null); }
function View_NavbarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["class", "nav-item mr-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "d-none d-md-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resetFixture() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "fa", [["class", "mr-3"], ["name", "exchange"]], null, null, null, i4.View_AngularFontAwesomeComponent_0, i4.RenderType_AngularFontAwesomeComponent)), i1.ɵdid(3, 114688, null, 0, i5.AngularFontAwesomeComponent, [], { name: [0, "name"] }, null), (_l()(), i1.ɵted(-1, null, ["Change Fixture"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["class", "d-block d-md-none"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "fa", [["name", "exchange"]], null, null, null, i4.View_AngularFontAwesomeComponent_0, i4.RenderType_AngularFontAwesomeComponent)), i1.ɵdid(7, 114688, null, 0, i5.AngularFontAwesomeComponent, [], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = "exchange"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "exchange"; _ck(_v, 7, 0, currVal_1); }, null); }
function View_NavbarComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openExternalLink(_v.context.$implicit.url) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
function View_NavbarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "d-inline-block"], ["display", "dynamic"], ["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(1, 737280, null, 3, i6.NgbDropdown, [i1.ChangeDetectorRef, i6.NgbDropdownConfig, i3.DOCUMENT, i1.NgZone, i1.ElementRef, i1.Renderer2, [2, i6.ɵm]], { display: [0, "display"] }, null), i1.ɵqud(335544320, 1, { _menu: 0 }), i1.ɵqud(335544320, 2, { _menuElement: 0 }), i1.ɵqud(335544320, 3, { _anchor: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 9, "li", [["aria-haspopup", "true"], ["class", "nav-item mr-5 dropdown-toggle"], ["id", "externalLinks"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i6.NgbDropdownToggle, [i6.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[3, 4]], i6.NgbDropdownAnchor, null, [i6.NgbDropdownToggle]), (_l()(), i1.ɵeld(8, 0, null, null, 3, "a", [["class", "d-none d-md-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["External Links "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "fa", [["class", "ml-1"], ["name", "chevron-down"]], null, null, null, i4.View_AngularFontAwesomeComponent_0, i4.RenderType_AngularFontAwesomeComponent)), i1.ɵdid(11, 114688, null, 0, i5.AngularFontAwesomeComponent, [], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "a", [["class", "d-block d-md-none"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "fa", [["name", "external-link"]], null, null, null, i4.View_AngularFontAwesomeComponent_0, i4.RenderType_AngularFontAwesomeComponent)), i1.ɵdid(14, 114688, null, 0, i5.AngularFontAwesomeComponent, [], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(15, 0, [[2, 0]], null, 4, "div", [["aria-labelledby", "externalLinks"], ["ngbDropdownMenu", ""], ["placement", "bottom-right"]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 16).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i1.ɵnov(_v, 16).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i1.ɵnov(_v, 16).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i1.ɵnov(_v, 16).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i1.ɵnov(_v, 16).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 16384, [[1, 4]], 1, i6.NgbDropdownMenu, [i6.NgbDropdown], null, null), i1.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_5)), i1.ɵdid(19, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "dynamic"; _ck(_v, 1, 0, currVal_1); var currVal_3 = "chevron-down"; _ck(_v, 11, 0, currVal_3); var currVal_4 = "external-link"; _ck(_v, 14, 0, currVal_4); var currVal_8 = _co.externalLinks; _ck(_v, 19, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isOpen(); _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 6).dropdown.isOpen(); _ck(_v, 5, 0, currVal_2); var currVal_5 = true; var currVal_6 = i1.ɵnov(_v, 16).dropdown.isOpen(); var currVal_7 = i1.ɵnov(_v, 16).placement; _ck(_v, 15, 0, currVal_5, currVal_6, currVal_7); }); }
function View_NavbarComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "fa", [["class", "mr-3"], ["name", "sign-out"]], null, null, null, i4.View_AngularFontAwesomeComponent_0, i4.RenderType_AngularFontAwesomeComponent)), i1.ɵdid(3, 114688, null, 0, i5.AngularFontAwesomeComponent, [], { name: [0, "name"] }, null), (_l()(), i1.ɵted(-1, null, ["Logout "]))], function (_ck, _v) { var currVal_0 = "sign-out"; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_NavbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "nav", [["class", "navbar navbar-dark bg-primary fixed-top p-2"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i6.ɵm, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 20, "div", [["class", "container-fluid pt-3 pb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "navbar-brand align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "d-inline"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "mr-4"], ["src", "assets/brand_white.svg"], ["width", "100px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "d-inline"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(9, 1), (_l()(), i1.ɵeld(10, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["STEWARDS"])), (_l()(), i1.ɵeld(12, 0, null, null, 10, "ul", [["class", "nav"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_1)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_2)), i1.ɵdid(16, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_3)), i1.ɵdid(18, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_4)), i1.ɵdid(20, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_6)), i1.ɵdid(22, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 9, 0, "/"); _ck(_v, 8, 0, currVal_2); var currVal_3 = !_co.selectedFixture.courseName; _ck(_v, 14, 0, currVal_3); var currVal_4 = _co.selectedFixture.courseName; _ck(_v, 16, 0, currVal_4); var currVal_5 = _co.selectedFixture.courseName; _ck(_v, 18, 0, currVal_5); var currVal_6 = _co.selectedFixture.courseName; _ck(_v, 20, 0, currVal_6); var currVal_7 = _co.isLoggedIn; _ck(_v, 22, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).target; var currVal_1 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_0, currVal_1); }); }
export function View_NavbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, null, null, View_NavbarComponent_0, RenderType_NavbarComponent)), i1.ɵdid(1, 114688, null, 0, i7.NavbarComponent, [i8.EventsService, i9.AuthenticationService, i2.Router, i6.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavbarComponentNgFactory = i1.ɵccf("app-navbar", i7.NavbarComponent, View_NavbarComponent_Host_0, {}, {}, []);
export { NavbarComponentNgFactory as NavbarComponentNgFactory };
