import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import * as moment from 'moment';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FixturesService } from '../../../services/fixtures.service';
import { EventsService } from '../../../services/events.service';

import { EntrySamplingRequestComponent } from '../../modals/actions/entry-sampling-request/entry-sampling-request.component';

@Component({
  selector: 'app-fixture-sampling',
  templateUrl: './fixture-sampling.component.html',
  styleUrls: ['./fixture-sampling.component.scss']
})
export class FixtureSamplingComponent implements OnInit {

  meta: {
    loading: boolean,
    saving: boolean,
    fixtureSampledEntries: {
      loading: boolean,
      saving: boolean
    },
    randomEntry: {
      loading: boolean,
      saving: boolean
    }
  };
  selectedFixture: any;
  randomEntry: any;


  constructor(
    private notification: ToastrService,
    private route: ActivatedRoute,
    private location: Location,
    private fixturesService: FixturesService,
    private eventsService: EventsService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.meta = {
      loading: false,
      saving: false,
      fixtureSampledEntries: {
        loading: false,
        saving: false
      },
      randomEntry: {
        loading: false,
        saving: false
      }
    };

    this.selectedFixture = {
      fixtureId: Number(this.route.snapshot.paramMap.get('fixtureId')),
      fixtureYear: this.route.snapshot.paramMap.get('fixtureYear')
    };

    this.randomEntry = this.fixturesService.getPotentialRandomRunner(this.selectedFixture.fixtureId, this.selectedFixture.fixtureYear);
    // console.log(this.randomEntry);

    this.route.params.subscribe(params => {
      if (Object.keys(params).length) {
        this.getFixtureInfo(this.selectedFixture.fixtureYear, this.selectedFixture.fixtureId);
      }
    });
  }

  getFixtureInfo = (fixtureYear, fixtureId) => {
    this.meta.loading = true;
    this.fixturesService.getFixtureInfo({ fixtureYear, fixtureId }).subscribe(
      res => {
        this.selectedFixture = res;
        this.selectedFixture.fixtureYear = this.selectedFixture.year;
        this.eventsService.changeFixtureData(this.selectedFixture);
        this.getFixtureSamplingRequests(this.selectedFixture.fixtureYear, this.selectedFixture.fixtureId);
        // console.log('Fixture Info:', this.selectedFixture.entries);
        this.meta.loading = false;
      },
      err => {
        this.notification.error(`Could't get the Fixture information: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.loading = false;
      }
    );
  }

  getFixtureSamplingRequests = (fixtureYear, fixtureId): void => {
    this.meta.fixtureSampledEntries.loading = true;
    this.fixturesService.getFixtureSamplingRequests({ fixtureYear, fixtureId }).subscribe(
      res => {
        this.selectedFixture.entries = res.sort((a, b) => {
          const dateA = moment(a.raceDateTime).valueOf();
          const dateB = moment(b.raceDateTime).valueOf();
          return dateA - dateB;
        });
        // console.log('Fixture Entries with Sampling Requests:', this.selectedFixture.entries);
        this.meta.fixtureSampledEntries.loading = false;
      },
      err => {
        this.notification.error(`Could't get the Fixture Entries with Sampling Requests: ` +
        `${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.fixtureSampledEntries.loading = false;
      }
    );
  }

  addSamplingRequest = (entry) => {
    const modalWindow = this.modalService.open(EntrySamplingRequestComponent, { centered: true, backdrop: 'static', size: 'lg' });
    modalWindow.componentInstance.entry = entry;
    modalWindow.result.then((result) => {
      console.log(`[ DEBUG ] Modal Closed with response`, result);
      const entryIndex = this.selectedFixture.entries.findIndex(e => e.horseId === entry.horseId);
      entry.indicators.hasBeenSelectedForSampling = true;
      entry.samplingId = result.samplingId;
      this.selectedFixture.entries.unshift(entry);
      // Update random runner status
      this.fixturesService.cleanOtherRandomRunner();
      this.randomEntry = null;
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  drawRandomEntry = (fixtureYear, fixtureId) => {
    this.meta.randomEntry.loading = true;
    // Clean up other fixtures random runners

    this.fixturesService.getFixtureRandomRunner({ fixtureYear, fixtureId }).subscribe(
      res => {
        this.randomEntry = res;
        this.fixturesService.setPotentialRandomRunner(this.randomEntry);
        this.meta.randomEntry.loading = false;
      },
      err => {
        this.notification.error(`Could't get a random entry for this fixture: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.randomEntry.loading = false;
      }
    );
  }
}
