<form #errorForm="ngForm">
  <div class="modal-header d-flex justify-content-center">
    <h4 class="modal-title">Ops! Something went wrong!</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col text-center">
        <small>
          <strong>Please copy the following text and send it to our developer team</strong>.<br>
          If you can provide some context to what was being done before this error, it would help the investigation.
        </small>
      </div>
    </div>
    <div class="row px-3">
      <div class="col text-left">
        <pre [innerHTML]="stack" class="text-danger"></pre>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-around">
    <button type="button" class="btn btn-outline-dark" (click)="cancel('cancel')">Close</button>
  </div>
</form>
