import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class EventsService {

  fixtureData = new BehaviorSubject<any>({});
  raceData = new BehaviorSubject<any>({});
  racecardData = new BehaviorSubject<any>({});
  commentsData = new BehaviorSubject<any>({});
  enquiriesData = new BehaviorSubject<any>({});
  userData =  new BehaviorSubject<any>({ id: null, user: null, token_type: null, access_token: null, roles: [] });
  genericEnquiry = new BehaviorSubject<boolean>(false);

  observableFixture = this.fixtureData.asObservable();
  observableRace = this.raceData.asObservable();
  observableRacecard = this.racecardData.asObservable();
  observableComments = this.commentsData.asObservable();
  observableEnquiries = this.enquiriesData.asObservable();
  observableGenericEnquiry = this.genericEnquiry.asObservable();
  observableUser = this.userData.asObservable();

  constructor() { }

  changeFixtureData(data): any {
    this.fixtureData.next(data);
  }

  changeRaceData(data): any {
    this.raceData.next(data);
  }

  changeRacecardData(data): any {
    this.racecardData.next(data);
  }

  changeCommentsData(data): any {
    this.commentsData.next(data);
  }

  changeEnquiriesData(data): any {
    this.enquiriesData.next(data);
  }

  changeGenericEnquiry(data): any {
    this.genericEnquiry.next(data);
  }

  changeUserData(data): any {
    this.userData.next(data);
  }

  getApiErrorMessage(response) {
    console.error('[ DEBUG ] Intercepted Error:', response);
    if (response.status !== undefined) {
      if (response.status === 422) {
        // Validation Error
        let validationErrors = ' ';
        Reflect.ownKeys(response.error).forEach(key => {
          validationErrors += ' - ' + response.error[key][0];
        });
        return validationErrors;
      }
      if (response.status === 0) {
        return `Something went wrong with the internet connection. ` +
        `The action request could have been completed anyway. Please refresh the content by pressing together SHIFT+CTRL+R`;
      }
      return ` '${response.statusText}' (${response.status})`;
    } else {
      return response[Object.keys(response)[0]];
    }
  }

}
