import { Component, OnInit } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../services/events.service';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  meta: {
    isValid: Boolean,
    loading: Boolean,
    typeLabel: String,
    page: Number,
    perPage: Number,
    total: Number
  };
  searchParams: {
    type: String,
    name: String,
    options: Array<{ key: String, value: any }>
  };
  results: Array<any>;

  constructor(
    private notification: ToastrService,
    private searchService: SearchService,
    private eventService: EventsService
  ) { }

  ngOnInit() {
    this.meta = {
      isValid: false,
      loading: false,
      typeLabel: '...',
      page: 1,
      perPage: 8,
      total: null
    };
    this.searchParams = {
      type: null,
      name: null,
      options: [
        {
          key: 'dispensation',
          value: null
        }
      ]
    };
    this.results = null;
  }

  updateType(newValue) {
    this.reset();
    this.searchParams.type = !newValue ? null : newValue;
    this.meta.typeLabel = !newValue ? '...' : newValue.slice(0, -1);
    this.updateValidation();
  }

  updateValidation() {
    let validity = false;
    if (this.searchParams.type) {
      validity = true;
    }
    if (this.searchParams.name) {
      validity  = true;
    }
    this.searchParams.options.forEach(opt => {
      if (opt.value !== null) {
        validity = true;
      }
    });
    this.meta.isValid =  validity;
  }

  search(page = null) {
    console.log(this.searchParams);
    this.meta.loading = true;
    this.results = null;
    this.searchService.getResults(
      this.searchParams.type,
      this.searchParams.name,
      page ? page : 1,
      this.meta.perPage,
      this.searchParams.options
    ).subscribe(
      res => {
        this.results = res.data;
        this.meta.page = res.current_page;
        this.meta.total = res.total;
        this.meta.loading = false;
        console.log(this.meta);
      },
      err => {
        this.notification.error(this.eventService.getApiErrorMessage(err), 'Error!');
        this.meta.loading = false;
      }
    );
  }

  reset() {
    this.results = null;
    this.searchParams = {
      type: null,
      name: null,
      options:  [
        {
          key: 'dispensation',
          value: null
        }
      ]
    };
  }
}
