import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/authentication.service";
import * as i2 from "@angular/router";
var CanActivateGuard = /** @class */ (function () {
    function CanActivateGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    CanActivateGuard.prototype.canActivate = function (next, state) {
        var authUser = this.authService.getFromSession();
        console.log("[ DEBUG ] ROUTE GUARD: User from Session", authUser);
        if (authUser && authUser.roles && authUser.roles.length) {
            return true;
        }
        else {
            this.router.navigate(['/login'], {
                queryParams: {
                    return: state.url
                }
            });
            return false;
        }
    };
    CanActivateGuard.ngInjectableDef = i0.defineInjectable({ factory: function CanActivateGuard_Factory() { return new CanActivateGuard(i0.inject(i1.AuthenticationService), i0.inject(i2.Router)); }, token: CanActivateGuard, providedIn: "root" });
    return CanActivateGuard;
}());
export { CanActivateGuard };
