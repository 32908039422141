/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.router.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./app.router.component";
var styles_AppRouterComponent = [i0.styles];
var RenderType_AppRouterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppRouterComponent, data: {} });
export { RenderType_AppRouterComponent as RenderType_AppRouterComponent };
export function View_AppRouterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "main", [["class", "container-fluid"], ["role", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_AppRouterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppRouterComponent_0, RenderType_AppRouterComponent)), i1.ɵdid(1, 114688, null, 0, i3.AppRouterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppRouterComponentNgFactory = i1.ɵccf("app-root", i3.AppRouterComponent, View_AppRouterComponent_Host_0, {}, {}, []);
export { AppRouterComponentNgFactory as AppRouterComponentNgFactory };
