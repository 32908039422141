<div class="modal-header text-center">
  Going History for {{fixture.courseName | titlecase}} {{fixture.fixtureDate | date:'dd/MM/yyyy'}}
</div>
<div *ngIf="meta.loading" class="modal-body text-center">
  <fa name="circle-o-notch" animation="spin"></fa>
</div>
<div *ngIf="!meta.loading" class="modal-body">
  <div *ngIf="goingHistory">
    <p *ngIf="meta.declarationNotFound" class=" text-center lead"><br> A declaration GOING couldn't be found</p>
    <ngb-accordion [closeOthers]="true" (panelChange)="switchMode(false)" activeIds="static-1">
      <ngb-panel id="goingHistory_{{indexItem}}" *ngFor="let item of goingHistory; index as indexItem">
        <ng-template ngbPanelTitle>
          <span [ngClass]="{'text-success': item.declarationGoing}">{{item.createdAt | date:'dd/MM/yyyy'}} at {{item.createdAt | date:'h:mm a'}}</span>
          <span *ngIf="item.going.conditions.ground" [ngClass]="{'text-success': item.declarationGoing}"> - {{item.going.conditions.groundDescription}}</span>
          <span *ngIf="item.going.conditions.conditionInPlaces" [ngClass]="{'text-success': item.declarationGoing}">, {{item.going.conditions.conditionInPlacesDescription}} in places</span>
          <span *ngIf="item.going.conditions.goingStick" [ngClass]="{'text-success': item.declarationGoing}"> (Gs {{item.going.conditions.goingStick}})</span>
          <span *ngIf="!item.going.conditions.ground && item.going.tracks.length" [ngClass]="{'text-success': item.declarationGoing}">
            <span> - </span>
            <span *ngFor="let track of item.going.tracks">
              <strong *ngIf="track.ground || track.conditionInPlaces">&nbsp;{{track.raceType}}:</strong>
              <span *ngIf="track.ground"> {{track.groundDescription}}</span>
              <span *ngIf="track.conditionInPlaces">,  {{track.conditionInPlacesDescription}} in places</span>
            </span>
          </span>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="container-fluid py-3">
            <div class="row">
              <div class="col">
                <p>
                  <strong>Overall</strong><br>
                  <span *ngIf="meta.showDifferences || item.going.conditions.ground">Ground condition is <strong>{{item.going.conditions.groundDescription || 'N/A'}}</strong></span>
                  <span *ngIf="meta.showDifferences && item.going.conditions.ground" [ngClass]="{'bg-success': item.going.conditions.ground === declarationGoing.going.conditions.ground, 'bg-warning': item.going.conditions.ground !== declarationGoing.going.conditions.ground}">&nbsp;{{declarationGoing.going.conditions.groundDescription || 'N/A'}}</span>
                  <span *ngIf="meta.showDifferences || item.going.conditions.conditionInPlaces">, {{item.going.conditions.conditionInPlacesDescription || 'N/A'}} in places</span>
                  <span *ngIf="meta.showDifferences && item.going.conditions.conditionInPlaces" [ngClass]="{'bg-success': item.going.conditions.conditionInPlaces === declarationGoing.going.conditions.conditionInPlaces, 'bg-warning': item.going.conditions.conditionInPlaces !== declarationGoing.going.conditions.conditionInPlaces}">&nbsp;{{declarationGoing.going.conditions.conditionInPlacesDescription || 'N/A'}} in places</span>
                  <span *ngIf="item.going.conditions.groundComment"><br>Note: {{item.going.conditions.groundComment}}</span>
                  <span *ngIf="item.going.conditions.goingStickAvailable">
                    <br *ngIf="item.going.conditions.ground">
                    <span *ngIf="meta.showDifferences || item.going.conditions.goingStick">GoingStick <strong>{{item.going.conditions.goingStick || 'N/A'}}</strong></span>
                    <span *ngIf="meta.showDifferences && item.going.conditions.goingStick" [ngClass]="{'bg-success': item.going.conditions.goingStick === declarationGoing.going.conditions.goingStick, 'bg-warning': item.going.conditions.goingStick !== declarationGoing.going.conditions.goingStick}">&nbsp;{{declarationGoing.going.conditions.goingStick || 'N/A'}}</span>
                    <span *ngIf="item.going.conditions.goingStickUpdatedAt"> on {{item.going.conditions.goingStickUpdatedAt | date:'dd/MM/yyyy'}} at {{item.going.conditions.goingStickUpdatedAt | date:'h:mm'}}</span>
                    <span *ngIf="item.going.conditions.goingStickComment"><br>Note: {{item.going.conditions.goingStickComment}}</span>
                  </span>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <p *ngFor="let track of item.going.tracks; index as trackIndex">
                  <span *ngIf="track.available">
                    <strong>{{track.raceType}}</strong><br>
                    <span *ngIf="meta.showDifferences || track.ground">Ground condition is <strong>{{track.groundDescription || 'N/A'}}</strong></span>
                    <span *ngIf="meta.showDifferences && track.ground" [ngClass]="{'bg-success': track.ground === declarationGoing.going.tracks[trackIndex].ground, 'bg-warning': track.ground !== declarationGoing.going.tracks[trackIndex].ground}">&nbsp;{{declarationGoing.going.tracks[trackIndex].groundDescription || 'N/A'}}</span>
                    <span *ngIf="meta.showDifferences || track.conditionInPlaces">, {{track.conditionInPlacesDescription || 'N/A'}} in places</span>
                    <span *ngIf="meta.showDifferences && track.conditionInPlaces" [ngClass]="{'bg-success': track.conditionInPlaces === declarationGoing.going.tracks[trackIndex].conditionInPlaces, 'bg-warning': track.conditionInPlaces !== declarationGoing.going.tracks[trackIndex].conditionInPlaces}">&nbsp;{{declarationGoing.going.tracks[trackIndex].conditionInPlacesDescription || 'N/A'}} in places</span>
                    <span *ngIf="track.groundComment"><br>Note: {{track.groundComment}}</span>
                    <span *ngIf="track.goingStickAvailable">
                      <br *ngIf="track.ground">
                      <span *ngIf="meta.showDifferences || track.goingStick">GoingStick <strong>{{track.goingStick || 'N/A'}}</strong></span>
                      <span *ngIf="meta.showDifferences && track.goingStick" [ngClass]="{'bg-success': track.goingStick === declarationGoing.going.tracks[trackIndex].goingStick, 'bg-warning': track.goingStick !== declarationGoing.going.tracks[trackIndex].goingStick}">&nbsp;{{declarationGoing.going.tracks[trackIndex].goingStick || 'N/A'}}</span>
                      <span *ngIf="track.goingStickUpdatedAt"> on {{track.goingStickUpdatedAt | date:'dd/MM/yyyy'}} at {{track.goingStickUpdatedAt | date:'h:mm'}}</span>
                      <span *ngIf="track.goingStickComment"><br>Note: {{track.goingStickComment}}</span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div class="row" *ngIf="!item.declarationGoing">
              <div class="col text-right">
                <button *ngIf="declarationGoing" class="btn btn-link" (click)="switchMode()">
                  <span *ngIf="!meta.showDifferences">show</span>
                  <span *ngIf="meta.showDifferences">hide</span>
                  <span>&nbsp;differences</span>
                </button>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="cancel('cancel')">Close</button>
</div>
