import { ErrorHandler, Injectable, Injector} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorComponent } from '../components/commons/error/error.component';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {

  private modalService: NgbModal;
  constructor(
    private injector: Injector
  ) { }

  handleError(error: Error) {
    this.modalService = this.injector.get(NgbModal);
    const modalWindow = this.modalService.open(ErrorComponent, { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.error = error;
    modalWindow.result.then((result) => { }, (reason) => { });
    throw error;
  }
}

