<nav class="navbar navbar-dark bg-primary fixed-top p-2">
  <div class="container-fluid pt-3 pb-3">
  <div class="navbar-brand align-middle">
    <div class="d-inline">
      <img src="assets/brand_white.svg" width="100px" class="mr-4">
    </div>
    <div class="d-inline">
      <a [routerLink]="['/']"><strong>STEWARDS</strong></a>
    </div>
  </div>

  <ul class="nav">
    <li class="nav-item mr-5"  *ngIf="!selectedFixture.courseName">
      <a  [routerLink]="['/search']"><fa name="search" class="mr-3"></fa>Search</a>
    </li>
    <li class="nav-item mr-5"  *ngIf="selectedFixture.courseName">
      <a class="d-none d-md-block" (click)="openExternalLink('https://crate.horseracing.software/media/pdf/stewardsreport/?fixtureId=' + selectedFixture.fixtureId + '&year=' + selectedFixture.fixtureYear)"><fa name="file" class="mr-3"></fa>Stewards Report</a>
      <a class="d-block d-md-none"><fa name="file"></fa></a>
    </li>
    <li class="nav-item mr-5"  *ngIf="selectedFixture.courseName">
      <a class="d-none d-md-block" (click)="resetFixture()"><fa name="exchange" class="mr-3"></fa>Change Fixture</a>
      <a class="d-block d-md-none"><fa name="exchange"></fa></a>
    </li>
    <div *ngIf="selectedFixture.courseName" ngbDropdown display="dynamic" class="d-inline-block">
      <li class="nav-item mr-5" id="externalLinks" ngbDropdownToggle>
        <a class="d-none d-md-block">External Links <fa class="ml-1" name="chevron-down"></fa></a>
        <a class="d-block d-md-none"><fa name="external-link"></fa></a>
      </li>
      <div placement="bottom-right"  ngbDropdownMenu aria-labelledby="externalLinks">
        <a *ngFor="let link of externalLinks" (click)="openExternalLink(link.url)" class="dropdown-item">{{link.name}}</a>
      </div>
    </div>
    <li class="nav-item"  *ngIf="isLoggedIn">
      <a (click)="logout()"><fa name="sign-out" class="mr-3"></fa>Logout </a>
    </li>
  </ul>
  </div>
</nav>
