import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { FixturesService } from '../../services/fixtures.service';
import { EventsService } from '../../services/events.service';

@Component({
  selector: 'app-fixtures',
  templateUrl: './fixtures.component.html',
  styleUrls: ['./fixtures.component.scss']
})
export class FixturesComponent implements OnInit {

  loading: boolean;
  fixtureDate: {
    year: number,
    month: number,
    day: number
  };
  fixturesList: any[];

  constructor(
    private router: Router,
    private notification: ToastrService,
    private fixturesService: FixturesService,
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    const today = moment();
    this.fixtureDate = {
      year: today.year(),
      month: today.month() + 1,
      day: today.date()
    };

    this.getFixtures(this.fixtureDate);
  }

  // Force datepicker to close if click outside of it
  datepickerFix = (event, datePicker) => {
    if (event.target.offsetParent == null) {
      datePicker.close();
    } else if (event.target.offsetParent.nodeName !== 'NGB-DATEPICKER') {
      datePicker.close();
    }
  }

  getFixtures = (date: { year, month, day }): void => {
    this.loading = true;
    const requestDate = Object.assign({}, date);
    requestDate.month -= 1;
    this.fixturesService.getFixtures(moment(requestDate).format('YYYYMMDD'))
    .subscribe(
      res => {
        this.fixturesList = res.map(f => ({
          racesLink: `/races/${moment(f.fixtureDate).year()}/${f.fixtureId}`,
          courseName: f.courseName,
          abandonedReasonCode: f.abandonedReasonCode
        }));
        // console.log('Fixtures List', this.fixturesList);
        this.loading = false;
      },
      err => {
        console.error(err);
        this.notification.error(`Could't get the Fixtures List: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.loading = false;
        if (err.status === 401) {
          this.router.navigate(['/logout']);
        }
      }
    );
  }

  goSearch() {
    this.router.navigate(['/search']);
  }
}
