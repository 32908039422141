<div class="results-list">
  <div class="col text-center pt-5" *ngIf="meta.loading">
    <img src="assets/loader.svg" class="d-block ml-auto mr-auto" alt="spinner">
    <br>Loading...
  </div>
  <div *ngIf="!meta.loading">
    <div class="row">
      <div class="col">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Pos</th>
              <th scope="col">Wt</th>
              <th scope="col">RO</th>
              <th scope="col">Race</th>
              <th scope="col">Jockey</th>
              <th scope="col">Trainer</th>
              <th scope="col">P. Figure</th>
              <th scope="col">P. Code</th>
              <th scope="col">Distance</th>
              <th scope="col">SP</th>
            </tr>
          </thead>
          <tbody *ngIf="horse.previousForm.length">
            <tr *ngFor="let pf of horse.previousForm">
              <td><a [routerLink]="['/races', pf.raceYear, pf.fixtureId, pf.raceId, pf.divisionSequence]">{{pf.raceDateTime | date:'dd/MM/yy h:mm'}} - {{pf.courseShortName}}</a></td>
              <td>{{pf.finishingPosition}}/{{pf.runnersCount}}</td>
              <td>{{pf.weightCarriedPounds | toStones}}</td>
              <td>{{pf.runOffRating ? pf.runOffRating : '-'}}</td>
              <td>{{pf.courseShortName}} {{pf.raceDistanceText}} C{{pf.raceClass}} {{pf.raceType | slice:0:1}}
                <i *ngIf="pf.going">{{pf.going}}</i>
              </td>
              <td><a class="clickable" [routerLink]="['/jockeys', pf.jockeyId || 0]">{{pf.jockeyName}}</a></td>
              <td><a class="clickable" [routerLink]="['/trainers', pf.trainerId || 0]">{{pf.trainerName}}</a></td>
              <td>{{pf.performanceFigure}}</td>
              <td><div class="clickable" placement="auto" container="body" triggers="hover:blur" ngbTooltip="{{pf.performanceCodeDescription}}">{{pf.performanceCode}}</div></td>
              <td>{{pf.distanceBetween}}</td>
              <td>{{pf.startingPriceNumerator}}/{{pf.startingPriceDenominator}}</td>
            </tr>
          </tbody>
          <tbody *ngIf="!horse.previousForm.length">
            <tr class="text-center">
              <td colspan="11">No previous form found for this horse</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col text-center">
    <ngb-pagination *ngIf="meta.results.lastPage > 1" class="d-flex justify-content-center" [collectionSize]="meta.results.totalResult" [pageSize]="meta.results.pageSize" [rotate]="true" [maxSize]="3" [(page)]="meta.results.currentPage" (pageChange)="loadResults(horse.horseId, $event)" aria-label="pagination"></ngb-pagination>
  </div>
</div>
