<div class="modal-header d-flex flex-column align-items-center justify-content-center">
  <h4 class="modal-title text-truncate">{{meta.edit ? 'EDIT' : 'CREATE'}} ENQUIRY {{meta.title | uppercase}}</h4>
  <span>{{enquiry.stage === 'dp_committee' ? 'DISCIPLINARY' : enquiry.stage | uppercase}} STAGE</span>
</div>
<div class="modal-body" *ngIf="meta.loading">
  <div class="row">
    <div class="col text-center">
      <fa name="circle-o-notch" size="2x" animation="spin"></fa>
    </div>
  </div>
</div>
<div class="modal-body" *ngIf="!meta.loading">
  <form #enquiryForm="ngForm">
    <!-- OFFENDER -->
    <div class="row" *ngIf="possibleOffenders && possibleOffenders.length">
      <div class="text-left" [ngClass]="{'col-12': !enquiry.offender.jockeyMasterId, 'col-8': enquiry.offender.jockeyMasterId}">
        <div class="form-group">
          <label for="commentSubject">Select a Subject</label>
          <select [disabled]="enquiry.enquiryId" class="custom-select" id="commentSubject" name="commentSubject" [(ngModel)] = "enquiry.offender" (ngModelChange)="prepareModal()">
            <option *ngFor="let offender of possibleOffenders" [ngValue]="offender" [selected]="offender.id === enquiry.offender.id">{{offender.type | titlecase}} - {{offender.knownAs}}</option>
          </select>
        </div>
      </div>
      <div class="col-4 text-center" *ngIf="enquiry.offender.jockeyMasterId">
        <label for="involveMaster">Jockey's Master</label><br>
        <div class="btn-group btn-group-toggle" ngbRadioGroup name="involveMaster" id="involveMaster" [(ngModel)]="enquiry.offender.jockeyMasterInvolved">
          <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': !enquiry.offender.jockeyMasterInvolved}">
            <input ngbButton type="radio" [value]="false"> No
          </label>
          <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': enquiry.offender.jockeyMasterInvolved}">
            <input ngbButton type="radio" [value]="true"> Yes
          </label>
        </div>
      </div>
    </div>
    <!-- OFFENSE CATEGORY -->
    <div *ngIf="!enquiry.enquiryId && userIsDisciplinary" class="row">
      <div class="col">
        <div class="form-group">
          <label for="possibleOffences">Select the Offence's Category</label>
          <select class="custom-select" name="selectCategory" [(ngModel)]="enquiry.category" (ngModelChange)="updateStage($event)">
            <option *ngFor="let category of meta.possibleCategories" [selected]="category.value === enquiry.category" [ngValue]="category.value">{{category.text}}</option>
          </select>
        </div>
      </div>
    </div>
    <!-- OFFENCE -->
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="possibleOffences">Select an Offence</label>
          <input name="possibleOffences" id="possibleOffences"
            type="text"
            class="form-control"
            container="body"
            [class.is-invalid]="meta.possibleOffences.loadingFailed"
            [(ngModel)]="enquiry.offence"
            (selectItem)="getOutcomes($event)"
            [ngbTypeahead]="searchOffences"
            [resultTemplate]="offenceSuggestion"
            [inputFormatter]="offenceFormat"
            [editable]="false"
            [focusFirst]="false"
            [showHint]="true"/>
          <div class="text-info" *ngIf="meta.possibleOffences.loading">searching...</div>
          <div class="text-warning" *ngIf="meta.possibleOffences.loadingFailed">Sorry, couldn't find any suggestion.</div>
          <div *ngIf="!meta.possibleOffences.loading && !meta.possibleOffences.loadingFailed">&nbsp;</div>
          <ng-template #offenceSuggestion let-r="result" let-t="term">
            <span>{{r.offenceDescription}}</span>
            <span *ngIf="r.subOffenceDescription"> - {{r.subOffenceDescription}}</span>
            <span *ngIf="r.rule || r.relatedRule">
              [
                {{r.rule}}
                <span *ngIf="r.rule && r.relatedRule"> / {{r.relatedRule}}</span>
              ]
            </span>
          </ng-template>
        </div>
      </div>
    </div>
    <!-- Additional Information -->
    <div class="row pt-4 pb-4" *ngIf="meta.enquiryMeta">
      <div class="col text-center">
        <div class="alert alert-primary" role="alert" *ngIf="meta.enquiryMeta.itemNote">
          {{meta.enquiryMeta.itemNote}}
        </div>
        <div class="alert alert-warning" role="alert" *ngIf="enquiry.enquiryId && meta.enquiryMeta.tottedUpOccurrence > 1">
          <span>Under the totting up rules this is the {{meta.enquiryMeta.tottedUpOccurrence | ordinal}} time {{ enquiry.offence.rule === 'Interference' ? '(overall ' + meta.enquiryMeta.tottedUpDays + ' ' + (meta.enquiryMeta.tottedUpDays > 1 ? 'days)' : 'day)') : '' }} this offence occurred in the past {{meta.enquiryMeta.tottingUpPeriod}} months.</span>
        </div>
        <div class="alert alert-warning" role="alert" *ngIf="!enquiry.enquiryId && meta.enquiryMeta.tottedUpOccurrence">
          <span>Under the totting up rules this is the {{meta.enquiryMeta.tottedUpOccurrence | ordinal}} time {{ enquiry.offence.rule === 'Interference' ? '(overall ' + meta.enquiryMeta.tottedUpDays + ' ' + (meta.enquiryMeta.tottedUpDays > 1 ? 'days)' : 'day)') : '' }} this offence occurred in the past {{meta.enquiryMeta.tottingUpPeriod}} months.</span>
        </div>
        <!-- <div class="alert alert-info" role="alert" *ngIf="!enquiry.enquiryId && meta.enquiryMeta.tottedUpDays">
          <span class="pl-1" *ngIf="meta.enquiryMeta.tottedUpDays">Already totted up {{meta.enquiryMeta.tottedUpDays}} days.</span>
        </div> -->
      </div>
    </div>
    <!-- INTERNAL COMMENT -->
    <div class="row pt-4 pb-4 border-top" *ngIf="enquiry.offence">
      <div class="col text-center">
        <div class="form-group">
          <label for="commentText">Internal Comment</label>
          <textarea class="form-control textarea-height" id="commentText" name="commentText" [(ngModel)]="enquiry.ptcComment"></textarea>
        </div>
      </div>
    </div>
    <!-- OUTCOMES -->
    <div class="row" *ngIf="meta.possibleOutcomes.loading">
      <div class="col text-center">
        <fa name="circle-o-notch" size="2x" animation="spin"></fa>
      </div>
    </div>
    <div *ngIf="!meta.possibleOutcomes.loading && formOutcomes.inBreach">
      <div *ngFor="let outcomeType of formOutcomes | objProperties">
        <div *ngIf="outcomeType === 'inBreach'" class="row pt-4 pb-4 border-top">
          <div class="col text-center">
            <label for="breachValue">{{formOutcomes[outcomeType].label | titlecase}}</label><br>
            <div class="btn-group btn-group-toggle" ngbRadioGroup name="breachValue" id="breachValue" [(ngModel)]="formOutcomes[outcomeType].value" (ngModelChange)="meta.saveEnabled = isSaveAllowed()">
              <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].value === 'not_in_breach'}">
                <input ngbButton type="radio" value="not_in_breach"> No
              </label>
              <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].value === 'in_breach'}">
                <input ngbButton type="radio" value="in_breach"> Yes
              </label>
              <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].value === 'adjourned'}">
                <input ngbButton type="radio" value="adjourned"> Adjourned
              </label>
              <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].value === 'further_investigation'}">
                <input ngbButton type="radio" value="further_investigation"> Further Investigations
              </label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="formOutcomes['inBreach'].value === 'in_breach'">
          <div *ngIf="outcomeType === 'refer'" class="row pt-4 pb-4 border-top">
            <div class="col text-center">
              <label for="refer">{{formOutcomes[outcomeType].label | titlecase}}</label><br>
              <div class="btn-group btn-group-toggle" ngbRadioGroup name="refer" id="refer" [(ngModel)]="formOutcomes[outcomeType].isApplied">
                <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].isApplied === false}">
                  <input ngbButton type="radio" [value]="false"> No
                </label>
                <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].isApplied === true}">
                  <input ngbButton type="radio" [value]="true"> Yes
                </label>
              </div>
            </div>
          </div>
          <ng-container *ngIf="formOutcomes['refer'].isApplied === false">
            <div *ngIf="outcomeType === 'sst'" class="row pt-4 pb-4 border-top">
              <div class="col text-center">
                <label for="sst">{{formOutcomes[outcomeType].label | titlecase}}</label><br>
                <div class="btn-group btn-group-toggle" ngbRadioGroup name="sst" id="sst" [(ngModel)]="formOutcomes[outcomeType].isApplied">
                  <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].isApplied === false}">
                    <input ngbButton type="radio" [value]="false"> No
                  </label>
                  <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].isApplied === true}">
                    <input ngbButton type="radio" [value]="true"> Yes
                  </label>
                </div>
              </div>
            </div>
            <div *ngIf="outcomeType === 'horseWithdrawn'" class="row pt-4 pb-4 border-top">
              <div class="col text-center">
                <label for="horseWithdrawn">{{formOutcomes[outcomeType].label | titlecase}}</label><br>
                <div class="btn-group btn-group-toggle" ngbRadioGroup name="horseWithdrawn" id="horseWithdrawn" [(ngModel)]="formOutcomes[outcomeType].isApplied">
                  <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].isApplied === false}">
                    <input ngbButton type="radio" [value]="false"> No
                  </label>
                  <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].isApplied === true}">
                    <input ngbButton type="radio" [value]="true"> Yes
                  </label>
                </div>
              </div>
            </div>
            <div *ngIf="outcomeType === 'personDisqualification' || outcomeType === 'horseDisqualification'" class="row pt-4 pb-4 border-top">
              <div class="col text-center">
                <label for="disqualification">{{formOutcomes[outcomeType].label | titlecase}}</label><br>
                <div class="btn-group btn-group-toggle" ngbRadioGroup name="disqualification" id="disqualification" [(ngModel)]="formOutcomes[outcomeType].isApplied">
                  <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].isApplied === false}">
                    <input ngbButton type="radio" [value]="false"> No
                  </label>
                  <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].isApplied === true}">
                    <input ngbButton type="radio" [value]="true"> Yes
                  </label>
                </div>
              </div>
            </div>
            <div *ngIf="outcomeType === 'advisory'" class="row pt-4 pb-4 border-top">
              <div class="col text-center">
                <label for="advisory">
                  {{formOutcomes[outcomeType].label | titlecase}}
                  <i>{{formOutcomes[outcomeType].attributes.type}}</i>
                </label>
                <br>
                <div class="btn-group btn-group-toggle" ngbRadioGroup name="advisory" id="advisory" [(ngModel)]="formOutcomes[outcomeType].isApplied">
                  <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].isApplied === false}">
                    <input ngbButton type="radio" [value]="false"> No
                  </label>
                  <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].isApplied === true}">
                    <input ngbButton type="radio" [value]="true"> Yes
                  </label>
                </div>
              </div>
            </div>
            <div *ngIf="outcomeType === 'fine'" class="row pt-4 pb-4 border-top">
              <div class="text-center" [ngClass]="{'col': !formOutcomes[outcomeType].isApplied, 'col-4 offset-2': formOutcomes[outcomeType].isApplied}">
                <label for="fine">{{formOutcomes[outcomeType].label | titlecase}}</label><br>
                <div class="btn-group btn-group-toggle" ngbRadioGroup name="fine" id="fine" [(ngModel)]="formOutcomes[outcomeType].isApplied" (ngModelChange)="fineListener($event, outcomeType)">
                  <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].isApplied === false}">
                    <input ngbButton type="radio" [value]="false"> No
                  </label>
                  <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].isApplied === true}">
                    <input ngbButton type="radio" [value]="true"> Yes
                  </label>
                </div>
              </div>
              <div *ngIf="formOutcomes[outcomeType].isApplied" class="col-4 text-center">
                <div class="form-group">
                  <label for="fineAmount">Amount</label>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="fine-pound-sign">£</span>
                    </div>
                    <input type="number" class="form-control" id="fineAmount" name="fineAmount" [(ngModel)]="formOutcomes[outcomeType].value" aria-describedby="fine-pound-sign">
                  </div>
                  <small *ngIf="formOutcomes[outcomeType].suggestion">
                    <span *ngIf="formOutcomes[outcomeType].suggestion.lower !== null">
                      Advised between {{formOutcomes[outcomeType].suggestion.lower | currency:'GBP'}} and {{formOutcomes[outcomeType].suggestion.upper | currency:'GBP'}}
                    </span>
                  </small>
                </div>
              </div>
            </div>
            <div *ngIf="outcomeType === 'personSuspension' || outcomeType === 'horseSuspension'" class="row pt-4 pb-4 border-top">
              <div class="col text-center">
                <label for="otherSuspension">{{formOutcomes[outcomeType].label | titlecase}}</label><br>
                <div class="btn-group btn-group-toggle" ngbRadioGroup name="otherSuspension" id="otherSuspension" [(ngModel)]="formOutcomes[outcomeType].isApplied">
                  <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].isApplied === false}">
                    <input ngbButton type="radio" [value]="false"> No
                  </label>
                  <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].isApplied === true}">
                    <input ngbButton type="radio" [value]="true"> Yes
                  </label>
                </div>
              </div>
            </div>
            <div *ngIf="outcomeType === 'suspension'">
              <div class="row py-4 border-top">
                <div class="text-center" [ngClass]="{'col-6': formOutcomes[outcomeType].isApplied, 'col': !formOutcomes[outcomeType].isApplied}">
                  <label for="suspension">{{formOutcomes[outcomeType].label | titlecase}}</label><br>
                  <div class="btn-group btn-group-toggle" ngbRadioGroup name="suspension" id="suspension" [(ngModel)]="formOutcomes[outcomeType].isApplied">
                    <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].isApplied === false}">
                      <input ngbButton type="radio" [value]="false"> No
                    </label>
                    <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].isApplied === true}">
                      <input ngbButton type="radio" [value]="true"> Yes
                    </label>
                  </div>
                </div>
                <div *ngIf="formOutcomes[outcomeType].isApplied" class="col-6 text-center">
                  <label for="datesKnown">Pending Dates</label><br>
                  <div class="btn-group btn-group-toggle" ngbRadioGroup name="datesPending" id="datesPending" [(ngModel)]="formOutcomes[outcomeType].datesPending" (ngModelChange)="suspensionListener(outcomeType)">
                    <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].datesPending === false}">
                      <input ngbButton type="radio" [value]="false"> No
                    </label>
                    <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': formOutcomes[outcomeType].datesPending === true}">
                      <input ngbButton type="radio" [value]="true"> Yes
                    </label>
                  </div>
                </div>
              </div>
              <div class="row py-4" *ngIf="formOutcomes[outcomeType].isApplied && !formOutcomes[outcomeType].datesPending">
                <div class="col-4 offset-4 text-center">
                  <div class="form-group">
                    <label for="suspensionAmount"># {{formOutcomes[outcomeType].suggestion.period}}</label>
                    <input type="number" class="form-control" id="suspensionAmount" name="suspensionAmount" [(ngModel)]="formOutcomes[outcomeType].value" (input)="getSuspensionDates($event, outcomeType, formOutcomes[outcomeType].suggestion.period)">
                    <small *ngIf="formOutcomes[outcomeType].suggestion">
                      <span *ngIf="formOutcomes[outcomeType].suggestion.lower">
                        Advised between {{formOutcomes[outcomeType].suggestion.lower}} and {{formOutcomes[outcomeType].suggestion.upper}} {{formOutcomes[outcomeType].suggestion.period}}
                      </span>
                    </small>
                  </div>
                </div>
              </div>
              <div *ngIf="meta.possibleSuspensionDates.loading">
                <div class="row pb-4">
                  <div class="col text-center">
                    <fa name="circle-o-notch" size="2x" animation="spin"></fa>
                  </div>
                </div>
              </div>
              <div *ngIf="!meta.possibleSuspensionDates.loading && formOutcomes[outcomeType].isApplied && !formOutcomes[outcomeType].datesPending">
                <div *ngIf="formOutcomes[outcomeType].suspensionDates && formOutcomes[outcomeType].suspensionDates.length" class="row pb-4">
                  <div class="col text-center">
                    <label>Suspension Dates</label>
                  </div>
                </div>
                <div *ngIf="formOutcomes[outcomeType].suspensionDates" class="row d-flex justify-content-around pb-4">
                  <div class="col-4 col-lg-3 text-center" *ngFor="let suspensionDate of formOutcomes[outcomeType].suspensionDates; index as dateIndex;">
                    <div class="form-group">
                      <label *ngIf="formOutcomes[outcomeType].suggestion.period !== 'Day(s)' && dateIndex === 0">From</label>
                      <label *ngIf="formOutcomes[outcomeType].suggestion.period !== 'Day(s)' && dateIndex === 1">To</label>
                      <input type="date" required="required" class="form-control input-small-font" id="suspensionDate-{{dateIndex + 1}}" name="suspensionDate-{{dateIndex + 1}}" [(ngModel)]="formOutcomes[outcomeType].suspensionDates[dateIndex].date">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <!-- APPEALS INFO -->
    <div *ngIf="enquiry.enquiryId && enquiry.stage === 'appeal'" class="row pt-4 pb-4 border-top">
      <div class="col text-center">
        <div class="form-group">
          <label for="appealConclusionSelect">Appeal Conclusion</label>
          <select class="custom-select" name="appealConclusionSelect" [(ngModel)]="enquiry.appealConclusion">
            <option *ngFor="let appealOutcome of meta.appealOutcomes" [selected]="appealOutcome.value === enquiry.appealConclusion" [ngValue]="appealOutcome.value">{{appealOutcome.text}}</option>
          </select>
        </div>
      </div>
      <div class="col text-center">
        <div class="form-group">
          <label for="appealDepositSelect">Deposit</label>
          <select class="custom-select" name="appealDepositSelect" [(ngModel)]="enquiry.deposit">
            <option *ngFor="let depositOutcome of meta.depositOutcomes" [selected]="depositOutcome.value === enquiry.deposit" [ngValue]="depositOutcome.value">{{depositOutcome.text}}</option>
          </select>
        </div>
      </div>
    </div>
    <!-- CAMERAS -->
    <div class="row pt-4 pb-4 border-top" *ngIf="enquiry.offence">
      <div class="col">
        <div class="form-group">
          <label>Camera Angles</label>
          <ng-select name="cameraAngles"
            [items]="possibleCameras"
            [loading]="meta.cameraAngles.loading"
            bindLabel="name"
            bindValue="id"
            [multiple]="true"
            appendTo="body"
            [closeOnSelect]="false"
            placeholder="Select one or more camera angles"
            [(ngModel)]="enquiry.cameraAngles">
        </ng-select>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="enquiry.offence">
      <div class="col">
        <div class="form-group">
          <label for="cameraStart">Camera Start</label>
          <input type="text" class="form-control" id="cameraStart" name="cameraStart" [(ngModel)]="enquiry.cameraStartTime">
        </div>
      </div>
      <div class="col" *ngIf="enquiry.offence">
        <div class="form-group">
          <label for="cameraStop">Camera Stop</label>
          <input type="text" class="form-control" id="cameraStop" name="cameraStop" [(ngModel)]="enquiry.cameraStopTime">
        </div>
      </div>
    </div>
    <!-- NOTICE -->
    <div class="row pt-4 pb-4 border-top" *ngIf="enquiry.offence">
      <div class="col text-center">
        <div class="form-group">
          <label for="noticeText">Notice</label>
          <textarea class="form-control textarea-height" id="noticeText" name="noticeText" [(ngModel)]="enquiry.notice"></textarea>
          <small class="text-danger">Please be aware that text entered into this field will appear live on the Stewards Reports which is publicly available.</small>
        </div>
      </div>
    </div>
    <!-- RIR Comment -->
    <div *ngIf="enquiry.offence" class="row pt-4 pb-4 border-top">
      <div class="text-center" [ngClass]="{'col-12': !enquiry.rir, 'col-2': enquiry.rir}">
        <label for="rirText">RIR</label><br>
        <div class="btn-group btn-group-toggle" ngbRadioGroup name="rirToggle" id="rirToggle" [(ngModel)]="enquiry.rir">
          <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': !enquiry.rir}">
            <input ngbButton type="radio" [value]="false"> No
          </label>
          <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': enquiry.rir}">
            <input ngbButton type="radio" [value]="true"> Yes
          </label>
        </div>
      </div>
      <div class="col-10 text-center" *ngIf="enquiry.rir">
        <div class="form-group">
          <label for="rirText">RIR Comment</label>
          <textarea class="form-control textarea-height" id="rirText" name="rirText" [(ngModel)]="enquiry.rirComment"></textarea>
        </div>
      </div>
    </div>
    <div class="row pt-4 pb-4 border-top" *ngIf="enquiry.offence">
      <div class="col text-center">
        <label for="publishedStatus">Publish Status</label><br>
        <div class="btn-group btn-group-toggle" ngbRadioGroup name="publishedStatus" id="publishedStatus" [(ngModel)]="enquiry.published">
          <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': enquiry.published === -1}">
            <input ngbButton type="radio" [value]="-1"> Draft
          </label>
          <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': enquiry.published === 0}">
            <input ngbButton type="radio" [value]="0"> Unpublished
          </label>
          <label ngbButtonLabel class="btn-secondary" [ngClass]="{'btn-success': enquiry.published === 1}">
            <input ngbButton type="radio" [value]="1"> Published
          </label>
        </div>
      </div>
    </div>
  </form>
</div>
<div *ngIf="!meta.saving" class="modal-footer d-flex justify-content-around">
  <button type="button" class="btn btn-outline-dark" (click)="cancel('cancel')">Close</button>
  <button type="button" class="btn btn-primary" [disabled]="!meta.saveEnabled" (click)="save()">Save</button>
</div>
<div *ngIf="meta.saving" class="modal-footer d-flex justify-content-around">
  <h1>
    <fa name="circle-o-notch" animation="spin"></fa>
  </h1>
</div>
