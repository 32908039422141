import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-fixture-suspension-dates',
  templateUrl: './fixture-suspension-dates.component.html',
  styleUrls: ['./fixture-suspension-dates.component.scss']
})
export class FixtureSuspensionDatesComponent implements OnInit {
  @Input() fixture;
  suspensionDates: string;
  suspensionG1RacesDates: Array <string>;

  constructor(
    private activeModal: NgbActiveModal,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.suspensionDates = this.getSuspensionDates(this.fixture.possibleSuspensionDates);
    this.suspensionG1RacesDates = this.getSuspensionG1Dates(this.fixture.possibleSuspensionDatesWithG1Races);
  }

  getSuspensionDates(dates): string {
    let result = [];

    for(let i = 0; i < dates.length; i++) {
      result.push(this.datePipe.transform(dates[i].fixtureDate, 'dd/MM'));
    }

    return result.join(', ');
  }

  getSuspensionG1Dates(dates): Array<string> {
    let result = [];

    for(let i = 0; i < dates.length; i++) {
      result.push(`${dates[i].name} - ${dates[i].shortRacingName}(${dates[i].raceCriteriaRaceType}) - ${this.datePipe.transform(dates[i].raceDate, 'dd/MM')}`);
    }

    return result;
  }

  close = () => {
    this.activeModal.close();
  }
}
