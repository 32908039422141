<ngb-accordion #todoListAccordion="ngbAccordion" [activeIds]="meta.activeIds">
  <!-- TODO LIST -->
  <ngb-panel id="todoList">
    <ng-template ngbPanelTitle>
      <span>Additional information</span>
      <span *ngIf="meta.raceTodoList.loading">
        <fa name="circle-o-notch" animation="spin"></fa>
      </span>
      <span *ngIf="!meta.raceTodoList.loading">
        ({{ selectedRace.todoList.totals }})
      </span>
    </ng-template>
    <ng-template ngbPanelContent>
      <table class="table table-sm table-striped">
        <thead>
          <tr>
            <th scope="col">Horse</th>
            <th scope="col">Context</th>
            <th scope="col">Information</th>
          </tr>
        </thead>
        <tbody *ngIf="selectedRace.todoList.totals">
          <tr *ngFor="let entry of selectedRace.todoList.nonRunner">
            <td>
              <small><a [routerLink]="['/horses', entry.horseId, 'enquiries']" target="_blank">{{ entry.horseName }}</a></small>
            </td>
            <td><small>Non Runner</small></td>
            <td>
              <button class="btn btn-link pt-0" (click)="openNonRunner(entry)">
                <small *ngIf="meta.raceTodoList.failed"
                  ><fa class="text-warning" name="exclamation-triangle"></fa
                ></small>
                <small *ngIf="!entry.reasonText"
                  >[ Missing Reason - Click to Add ]</small
                >
                <small *ngIf="entry.reasonText">
                  <span>{{ entry.reasonText }}</span>
                  <span *ngIf="entry.comment"> - {{entry.comment}}</span>
                  <span *ngIf="entry.internalComment"> - {{entry.internalComment}}</span>
                </small>
              </button>
            </td>
          </tr>
          <tr *ngFor="let entry of selectedRace.todoList.withdrawn">
            <td>
              <small><a [routerLink]="['/horses', entry.horseId, 'enquiries']" target="_blank">{{ entry.horseName }}</a></small>
            </td>
            <td><small>Withdrawn</small></td>
            <td>
              <button class="btn btn-link pt-0" (click)="openWithdrawn(entry)">
                <small *ngIf="meta.raceTodoList.failed"
                  ><fa class="text-warning" name="exclamation-triangle"></fa
                ></small>
                <small *ngIf="!entry.reasonText"
                  >[ Missing Reason - Click to Add ]</small
                >
                <small *ngIf="entry.reasonText">{{ entry.reasonText }}</small>
                <span *ngIf="entry.comment"> - {{entry.comment}}</span>
                <span *ngIf="entry.internalComment"> - {{entry.internalComment}}</span>
              </button>
            </td>
          </tr>
          <tr *ngFor="let entry of selectedRace.todoList.jockeyChange">
            <td>
              <small><a [routerLink]="['/horses', entry.horseId, 'enquiries']" target="_blank">{{ entry.horseName }}</a></small>
            </td>
            <td><small>Jockey Change</small></td>
            <td>
              <button class="btn btn-link pt-0" (click)="openJockeyChange(entry)">
                <small *ngIf="meta.raceTodoList.failed"><fa class="text-warning" name="exclamation-triangle"></fa></small>
                <small *ngIf="!entry.comment">[ Missing Reason - Click to Add ]</small>
                <small *ngIf="entry.comment">{{ entry.comment | slice: 0:100}}<span *ngIf="entry.comment.length > 100">...</span></small>
              </button>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!selectedRace.todoList.totals">
          <tr class="text-center">
            <td colspan="3">Nothing found for this race</td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
