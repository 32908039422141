/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./trainer-comments.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "./trainer-comments.component";
import * as i7 from "ngx-toastr";
import * as i8 from "../../../services/trainers.service";
import * as i9 from "../../../services/events.service";
var styles_TrainerCommentsComponent = [i0.styles];
var RenderType_TrainerCommentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TrainerCommentsComponent, data: {} });
export { RenderType_TrainerCommentsComponent as RenderType_TrainerCommentsComponent };
function View_TrainerCommentsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "col text-center pt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "spinner"], ["class", "d-block ml-auto mr-auto"], ["src", "assets/loader.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading... "]))], null, null); }
function View_TrainerCommentsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["..."]))], null, null); }
function View_TrainerCommentsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(0, i2.SlicePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrainerCommentsComponent_6)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = (_v.parent.context.$implicit.comment.length > 225); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_v.parent.context.$implicit.comment, 0, 225)); _ck(_v, 1, 0, currVal_0); }); }
function View_TrainerCommentsComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [", "]))], null, null); }
function View_TrainerCommentsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrainerCommentsComponent_10)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = !_v.context.last; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.comment; _ck(_v, 1, 0, currVal_0); }); }
function View_TrainerCommentsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrainerCommentsComponent_9)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.racePerformanceComments; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TrainerCommentsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "small", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrainerCommentsComponent_8)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.racePerformanceComments && _v.parent.context.$implicit.racePerformanceComments.length); _ck(_v, 2, 0, currVal_0); }, null); }
function View_TrainerCommentsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "small", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.openComment(_v.context.$implicit.id) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], null, null), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 2), (_l()(), i1.ɵeld(7, 0, null, null, 4, "td", [["class", "text-justify"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrainerCommentsComponent_5)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrainerCommentsComponent_7)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_3 = _v.context.$implicit.comment; _ck(_v, 9, 0, currVal_3); var currVal_4 = !_v.context.$implicit.comment; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit.createdAt, "dd/MM/yy")); _ck(_v, 5, 0, currVal_2); }); }
function View_TrainerCommentsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrainerCommentsComponent_4)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.trainer.comments; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TrainerCommentsComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "tr", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "td", [["class", "p-5"], ["colspan", "2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No Comments found for this trainer"]))], null, null); }
function View_TrainerCommentsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "table", [["class", "table table-bordered table-striped"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Date"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Comment"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrainerCommentsComponent_3)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrainerCommentsComponent_11)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.trainer.comments.length; _ck(_v, 11, 0, currVal_0); var currVal_1 = !_co.trainer.comments.length; _ck(_v, 13, 0, currVal_1); }, null); }
function View_TrainerCommentsComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ngb-pagination", [["aria-label", "pagination"], ["class", "d-flex justify-content-center"], ["role", "navigation"]], null, [[null, "pageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = ((_co.meta.results.currentPage = $event) !== false);
        ad = (pd_0 && ad);
    } if (("pageChange" === en)) {
        var pd_1 = (_co.loadResults(_co.trainer.trainerId, $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_NgbPagination_0, i4.RenderType_NgbPagination)), i1.ɵdid(1, 573440, null, 6, i5.NgbPagination, [i5.NgbPaginationConfig], { rotate: [0, "rotate"], collectionSize: [1, "collectionSize"], maxSize: [2, "maxSize"], page: [3, "page"], pageSize: [4, "pageSize"] }, { pageChange: "pageChange" }), i1.ɵqud(335544320, 1, { tplEllipsis: 0 }), i1.ɵqud(335544320, 2, { tplFirst: 0 }), i1.ɵqud(335544320, 3, { tplLast: 0 }), i1.ɵqud(335544320, 4, { tplNext: 0 }), i1.ɵqud(335544320, 5, { tplNumber: 0 }), i1.ɵqud(335544320, 6, { tplPrevious: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.meta.results.totalResult; var currVal_2 = 3; var currVal_3 = _co.meta.results.currentPage; var currVal_4 = _co.meta.results.pageSize; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_TrainerCommentsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "results-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrainerCommentsComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrainerCommentsComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "col text-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrainerCommentsComponent_12)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.meta.loading; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.meta.loading; _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.meta.results.lastPage > 1); _ck(_v, 9, 0, currVal_2); }, null); }
export function View_TrainerCommentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-trainer-comments", [], null, null, null, View_TrainerCommentsComponent_0, RenderType_TrainerCommentsComponent)), i1.ɵdid(1, 114688, null, 0, i6.TrainerCommentsComponent, [i5.NgbModal, i7.ToastrService, i8.TrainersService, i9.EventsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TrainerCommentsComponentNgFactory = i1.ɵccf("app-trainer-comments", i6.TrainerCommentsComponent, View_TrainerCommentsComponent_Host_0, { trainer: "trainer" }, {}, []);
export { TrainerCommentsComponentNgFactory as TrainerCommentsComponentNgFactory };
