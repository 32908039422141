<form #scaleForm="ngForm">
  <div class="modal-header d-flex justify-content-center">
    <h4 class="modal-title text-truncate">JOCKEY CHANGE FOR {{meta.title | uppercase}}</h4>
  </div>
  <div class="modal-body" *ngIf="meta.loading">
    <div class="row">
      <div class="col text-center">
        <fa name="circle-o-notch" size="2x" animation="spin"></fa>
      </div>
    </div>
  </div>
  <div class="modal-body" *ngIf="!meta.loading && jockeyChangeInfo">
    <div class="row" [hidden]="true">
      <div class="col">
        <div class="form-group">
          <label for="jockeyNameTypeahead">Jockey</label>
          <input [(ngModel)]="jockeyChangeInfo.currentJockey" [ngbTypeahead]="searchJockey" [inputFormatter]="jockeyNameFormatter"  [resultTemplate]="jockeyListFormat" [disabled]="!jockeyChangeInfo.newChange" name="jockeyNameTypeahead" id="jockeyNameTypeahead" type="text" class="form-control" />
        </div>
      </div>
      <div class="col-3 d-flex justify-content-center align-items-end mb-4 pb-2">
        <div ngbRadioGroup [(ngModel)]="jockeyChangeInfo.newChange" [disabled]="!jockeyChangeInfo.allowUpdate" (ngModelChange)="changedMode($event)" class="btn-group btn-group-toggle" name="newJockeyChange">
          <label ngbButtonLabel class="btn-primary">
            <input ngbButton valueChecked="" type="radio" [value]="true"> Change
          </label>
          <label ngbButtonLabel class="btn-primary">
            <input ngbButton type="radio" [value]="false"> Update
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="jockeyReason">Reason</label>
          <textarea class="form-control textarea-height" name="jockeyComment" id="jockeyComment" name="comment" [(ngModel)]="jockeyChangeInfo.comment"></textarea>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!meta.saving && jockeyChangeInfo" class="modal-footer d-flex justify-content-around">
    <button type="button" class="btn btn-outline-dark" (click)="cancel('cancel')">Close</button>
    <button type="button" class="btn btn-primary" [disabled]="!jockeyChangeInfo.currentJockey || !jockeyChangeInfo.comment" (click)="save()">Save</button>
  </div>
  <div *ngIf="meta.saving" class="modal-footer d-flex justify-content-around">
    <h1>
      <fa name="circle-o-notch" animation="spin"></fa>
    </h1>
  </div>
</form>

<ng-template #jockeyListFormat let-r="result" let-t="term">
  <ngb-highlight [result]="r.jockeyKnownAs" [term]="t"></ngb-highlight>
  <span class="pull-right"> {{r.dateOfBirth | date:'dd/MM/yy'}}</span>
</ng-template>