import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestOptions } from '@angular/http';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';

@Injectable()
export class ModalsService {

  defaultHeaders: HttpHeaders;
  constructor(
    private http: HttpClient,
    private auth: AuthenticationService
  ) { }

  getEnquiry(enquiry, headers = this.auth.getHeaders()): any {
    return this.http.get(`${environment.apiBase}/v2/enquiries/${enquiry.enquiryId}`, {headers});
  }

  getComment(comment, headers = this.auth.getHeaders()): any {
    return this.http.get(`${environment.apiBase}/v2/comments/${comment.id}`, {headers});
  }

  getGoingHistory(fixture, headers = this.auth.getHeaders()): any {
    return this.http.get(`${environment.apiBase}/v2/fixtures/${fixture.year}/${fixture.fixtureId}/going`, {headers});
  }

  deleteComment(commentId, userId, headers = this.auth.getHeaders()): any {
    return this.http.delete(`${environment.apiBase}/v2/comments/${commentId}/?userId=${userId}`, { headers });
  }

  deleteEnquiry(enquiryId, userId, headers = this.auth.getHeaders()): any {
    return this.http.delete(`${environment.apiBase}/v2/enquiries/${enquiryId}/?userId=${userId}`, {headers});
  }

}
