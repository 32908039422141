<form name="form" #formRequestedDelay="ngForm">
  <div class="modal-header d-flex justify-content-center">
    <h4 class="modal-title">Requested Delay</h4>
  </div>
  <div class="modal-body">
    <div *ngIf="meta.loading">
      <div class="row">
        <div class="col-12">
          <img src="assets/loader.svg" class="d-block ml-auto mr-auto" alt="spinner">
        </div>
      </div>
    </div>
    <div  *ngIf="!meta.loading">
      <div class="row" *ngIf="requestedDelayRaces.inMinutes">
        <div class="col">
          <div class="late-race-list">
            <label class="font-weight-bold d-inline">Advertised Time of Race</label>
            <p class="d-inline float-right">{{requestedDelayRaces.publishedRaceTime | racetime}}</p>
          </div>
          <div class="late-race-list">
            <label class="font-weight-bold d-inline">New Race Time</label>
            <p class="d-inline float-right">{{requestedDelayRaces.requestedDelayTime | racetime}}</p>
          </div>
          <div class="late-race-list">
            <label class="font-weight-bold d-inline">Minutes Difference</label>
            <p class="d-inline float-right">{{requestedDelayRaces.inMinutes}}</p>
          </div>
          <div class="late-race-list">
            <label class="font-weight-bold d-inline">Further comments</label>
            <p class="d-inline float-right">{{requestedDelayRaces.action}}</p>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!requestedDelayRaces.inMinutes">
        <div class="col">
          <!--<div class="form-group">
            <label class="font-weight-bold">New race time (HH:MM:SS): *</label>
            <ngb-timepicker name="requestedDelayTime" [(ngModel)]="time" (ngModelChange)="onTimeChange()" [seconds]="true" [required]="true"></ngb-timepicker>
          </div>-->
          <div>
            <label class="font-weight-bold d-inline">Original Race time: </label>
            <label class="d-inline">{{requestedDelayRaces.publishedRaceTime | racetime}}</label>
          </div>
          <div *ngIf="requestedDelayRaces.raceTime">
            <label class="font-weight-bold d-inline">Requested Race time: </label>
            <label class="d-inline">{{requestedDelayRaces.raceTime}}</label>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">Delay in Minutes:</label>
            <input class="form-control" type="NUMBER" min="1" max="40" step="1" value="5" size="4" name="inMinutes" [(ngModel)]="inMinutes" (ngModelChange)="onTimeChange()">
          </div>
          <!-- ACTIONS -->
          <div class="form-group">
            <label class="font-weight-bold">Further comments:</label>
            <textarea class="form-control" name="action" [(ngModel)]="action"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!meta.loading">
    <div *ngIf="!meta.saving" class="modal-footer d-flex justify-content-around">
      <button type="button" class="btn btn-outline-dark" (click)="cancel('cancel')">Close</button>
      <button type="button" class="btn btn-primary" (click)="confirm()" [disabled]="formRequestedDelay.invalid" [hidden]="requestedDelayRaces.requestedDelayTime">Confirm</button>
      <!--    <button type="button" class="btn btn-primary" (click)="editLateRace()">edit</button>-->
      <button type="button" class="btn btn-danger" (click)="deleteRace()" *ngIf="requestedDelayRaces.requestedDelayTime">Delete</button>
    </div>
    <div *ngIf="meta.saving" class="modal-footer d-flex justify-content-around">
      <h1>
        <fa name="circle-o-notch" animation="spin"></fa>
      </h1>
    </div>
  </div>
</form>
