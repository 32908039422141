import * as tslib_1 from "tslib";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
var NgbDateUKParserFormatter = /** @class */ (function (_super) {
    tslib_1.__extends(NgbDateUKParserFormatter, _super);
    function NgbDateUKParserFormatter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NgbDateUKParserFormatter.prototype.parse = function (value) {
        if (value) {
            var dateParts = value.trim().split('/');
            if (dateParts.length === 1 && isNumber(dateParts[0])) {
                return { year: toInteger(dateParts[0]), month: null, day: null };
            }
            else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
                return { year: toInteger(dateParts[1]), month: toInteger(dateParts[0]), day: null };
            }
            else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
                return { year: toInteger(dateParts[2]), month: toInteger(dateParts[1]), day: toInteger(dateParts[0]) };
            }
        }
        return null;
    };
    NgbDateUKParserFormatter.prototype.format = function (date) {
        var stringDate = "";
        if (date) {
            stringDate += isNumber(date.day) ? padNumber(date.day) + "-" : "";
            stringDate += isNumber(date.month) ? padNumber(date.month) + "-" : "";
            stringDate += date.year;
        }
        return stringDate;
    };
    return NgbDateUKParserFormatter;
}(NgbDateParserFormatter));
export { NgbDateUKParserFormatter };
function padNumber(value) {
    if (isNumber(value)) {
        return ("0" + value).slice(-2);
    }
    else {
        return "";
    }
}
function isNumber(value) {
    return !isNaN(toInteger(value));
}
function toInteger(value) {
    return parseInt("" + value, 10);
}
