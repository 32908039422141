<form #scaleForm="ngForm">
  <div class="modal-header d-flex justify-content-center">
    <h4 class="modal-title">FIXTURE STEWARDS</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col text-center">
        <div class="form-group">
          <label for="fixtureOfficials">Stewards</label>
          <textarea class="form-control textarea-height" id="fixtureOfficials" name="fixtureOfficials" [(ngModel)]="officialsText"></textarea>
        </div>
        <br>
      </div>
    </div>
  </div>
  <div *ngIf="!meta.saving" class="modal-footer d-flex justify-content-around">
    <button type="button" class="btn btn-outline-dark" (click)="cancel('cancel')">Close</button>
    <button type="button" class="btn btn-primary" [disabled]="!officialsText.length" (click)="confirm()">Confirm</button>
  </div>
  <div *ngIf="meta.saving" class="modal-footer d-flex justify-content-around">
    <h1>
      <fa name="circle-o-notch" animation="spin"></fa>
    </h1>
  </div>
</form>
