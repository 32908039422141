import { Component, OnInit, Input } from '@angular/core';

import { forkJoin ,  of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import * as moment from 'moment';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { RacesService } from '../../../services/races.service';
import { EventsService } from '../../../services/events.service';

import { EnquiryComponent } from '../../modals/enquiry/enquiry.component';
import { CommentComponent } from '../../modals/comment/comment.component';
import { GoingHistoryComponent } from '../../modals/going-history/going-history.component';

@Component({
  selector: 'app-race-info',
  templateUrl: './race-info.component.html',
  styleUrls: ['./race-info.component.scss']
})
export class RaceInfoComponent implements OnInit {

  meta: {
    loading: boolean,
    saving: boolean,
    raceInfo: {
      loading: boolean,
      saving: boolean
    },
    raceEnquiries: {
      loading: boolean,
      saving: boolean
    },
    horsesComments: {
      loading: boolean,
      saving: boolean,
      failed: boolean,
    },
    raceComments: {
      loading: boolean,
      saving: boolean
    },
    fixtureComments: {
      loading: boolean,
      saving: boolean
    }
  };
  @Input() selectedRace: any;
  @Input() selectedFixture: any;

  constructor(
    private modalService: NgbModal,
    private notification: ToastrService,
    private racesService: RacesService,
    private eventsService: EventsService
  ) { }

  ngOnInit() {
    this.meta = {
      loading: false,
      saving: false,
      raceInfo: {
        loading: false,
        saving: false
      },
      raceEnquiries: {
        loading: false,
        saving: false
      },
      horsesComments: {
        loading: false,
        saving: false,
        failed: false,
      },
      raceComments: {
        loading: false,
        saving: false
      },
      fixtureComments: {
        loading: false,
        saving: false
      }
    };

    this.eventsService.observableComments.subscribe(
      payload => {
        // console.log('triggered from comments changes');
        if (payload.section === 'raceComments') {
          this.updateRaceComments(payload);
        }
        if (payload.section === 'fixtureComments') {
          this.updateFixtureComments(payload);
        }
        if (payload.section === 'horseComments') {
          this.updateHorseComments(payload);
        }
      },
      error => console.log(error),
      () => console.log('done')
    );

    this.eventsService.observableEnquiries.subscribe(
      payload => {
        // console.log('triggered from enquiries changes');
        this.updateEnquiries(payload);
      },
      error => console.log(error),
      () => console.log('done')
    );

    this.getRaceInfo(this.selectedRace);
    this.getRaceComments(this.selectedRace);
    this.getRaceEnquiries(this.selectedRace);
    this.getHorsesComments(this.selectedRace);
  }

  getRaceInfo = (race) => {
    this.meta.raceInfo.loading = true;
    this.racesService.getRaceInfo(this.selectedRace).subscribe(
      res => {
        this.selectedRace.info = res;
        if (this.selectedRace.info.ratingBandLowest === -1) {
          this.selectedRace.info.ratings = null;
        } else {
          this.selectedRace.info.ratings = `${this.selectedRace.info.ratingBandLowest} - ${this.selectedRace.info.ratingBandHighest}`;
        }
        if (this.selectedRace.info.prizes) {
          this.selectedRace.info.prizes = this.selectedRace.info.prizes.filter(p => p !== '0.00');
        }
        this.selectedRace.info.tvCoverage = this.selectedRace.info.tvCoverage.replace(/_/g, ' ');
        this.selectedRace.info.jumpType = this.selectedRace.info.jumpType.replace(/_/g, ' ');

        // console.log('Race Info:', this.selectedRace.info);
        this.meta.raceInfo.loading = false;
      },
      err => {

        this.notification.error(`Could't get the ` +
        `${moment(this.selectedRace.raceDatetime).format('HH:mm')} race's info: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.raceInfo.loading = false;
      }
    );
  }

  updateFixtureComments(payload) {
    if (payload.section === 'fixtureComments') {
      console.log('fixture comments changed', payload);
      const commentIndex = this.selectedFixture.comments.findIndex(e => e.id === Number(payload.result.id));
      if (commentIndex !== -1) {
        if (payload.result.deleted) {
          this.selectedFixture.comments.splice(commentIndex, 1);
        } else {
          this.selectedFixture.comments[commentIndex] = payload.result;
        }
      } else {
        this.selectedFixture.comments.unshift(payload.result);
      }
    }
  }

  getRaceComments = (race) => {
    this.meta.raceComments.loading = true;
    this.racesService.getRaceComments(this.selectedRace).subscribe(
      res => {
        this.selectedRace.comments = res.data;
        // console.log('Race Comments:', this.selectedRace.comments);
        this.meta.raceComments.loading = false;
      },
      err => {

        this.notification.error(`Could't get the ` +
        `${moment(this.selectedRace.raceDatetime).format('HH:mm')} race's comments: ` +
        `${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.raceComments.loading = false;
      }
    );
  }

  updateRaceComments = (payload) => {
    if (payload.section === 'raceComments') {
      console.log('race comments changed', payload);
      const commentIndex = this.selectedRace.comments.findIndex(e => e.id === Number(payload.result.id));
      if (commentIndex !== -1) {
        if (payload.result.deleted) {
          this.selectedRace.comments.splice(commentIndex, 1);
        } else {
          this.selectedRace.comments[commentIndex] = payload.result;
        }
      } else {
        this.selectedRace.comments.unshift(payload.result);
      }
    }
  }

  getHorsesComments = (race) => {
    this.meta.horsesComments.loading = true;
    this.selectedRace.horsesComments = [];
    this.meta.horsesComments.loading = false;
    // this.racesService.getRaceComments(this.selectedRace).subscribe(
    //   res => {
    //     this.selectedRace.comments = res.data;
    //     // console.log('Race Comments:', this.selectedRace.comments);
    //     this.meta.horsesComments.loading = false;
    //   },
    //   err => {
    //
    //     this.notification.error(`Could't get the ` +
    //     `${moment(this.selectedRace.raceDatetime).format('HH:mm')} race's comments: ` +
    //     `${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
    //     this.meta.horsesComments.loading = false;
    //   }
    // );
  }

  updateHorseComments = (payload) => {
    if (payload.section === 'horseComments') {
      // if (payload.result.rpcComments) {
        // console.log('horses comments changed', payload);
        const commentIndex = this.selectedRace.horsesComments.findIndex(e => e.id === Number(payload.result.id));
        if (commentIndex !== -1) {
          if (payload.result.deleted) {
            this.selectedRace.horsesComments.splice(commentIndex, 1);
          } else {
            this.selectedRace.horsesComments[commentIndex] = payload.result;
          }
        } else {
          this.selectedRace.horsesComments.unshift(payload.result);
        }
      // }
    }
  }

  getRaceEnquiries = (race) => {
    this.meta.raceEnquiries.loading = true;
    this.racesService.getRaceEnquiries(this.selectedRace).subscribe(
      res => {
        this.selectedRace.enquiries = res.data;
        this.selectedRace.enquiries.forEach(enquiry => {
          const breachStatus = enquiry.outcomes.find(eo => eo.objectType === 'inBreach');
          if (breachStatus !== undefined && breachStatus.isApplied) {
            enquiry.breachStatus = breachStatus.value.replace(/_/g, ' '); } else { enquiry.breachStatus = 'not in breach';
          }
        });
        // console.log('Race Enquiries:', this.selectedRace.enquiries);
        this.meta.raceEnquiries.loading = false;
      },
      err => {

        this.notification.error(`Could't get the ` +
        `${moment(this.selectedRace.raceDatetime).format('HH:mm')} race's enquiries: ` +
        `${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.raceEnquiries.loading = false;
      }
    );
  }

  updateEnquiries = (payload) => {
    if (payload.section === 'enquiries') {
      console.log('enquiries changed', payload);
      const breachStatus = payload.result.outcomes.find(eo => eo.objectType === 'inBreach');
      if (breachStatus !== undefined && breachStatus.isApplied) {
        payload.result.breachStatus = breachStatus.value.replace(/_/g, ' ');
      } else {
        payload.result.breachStatus = 'not in breach';
      }
      const enquiryIndex = this.selectedRace.enquiries.findIndex(e => e.enquiryId === payload.result.enquiryId);
      if (enquiryIndex !== -1) {
        if (payload.result.deleted) {
          this.selectedRace.enquiries.splice(enquiryIndex, 1);
        } else {
          this.selectedRace.enquiries[enquiryIndex] = payload.result;
        }
      } else {
        this.selectedRace.enquiries.unshift(payload.result);
      }
    }
  }

  openEnquiry = (enquiryId) => {
    const modalWindow = this.modalService.open(EnquiryComponent,
      { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.enquiry = {enquiryId};
    modalWindow.result.then((result) => {
      console.log(`[ DEBUG ] Modal Closed with response`, result);
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  openComment = (id) => {
    const modalWindow = this.modalService.open(CommentComponent,
      { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.comment = {id};
    modalWindow.result.then((result) => {
      console.log(`[ DEBUG ] Modal Closed with response`, result);
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  openGoingHistory() {
    const modalWindow = this.modalService.open(GoingHistoryComponent, { centered: true, backdrop: 'static', size: 'lg' });
    modalWindow.componentInstance.fixture = this.selectedFixture;
    modalWindow.result.then(
      result => {
        // console.log(`[ DEBUG ] Modal dismissed: ${result}`);
      },
      reason => {
        // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
      }
    );
  }

}
