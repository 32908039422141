<app-navbar></app-navbar>
<div *ngIf="loading">
  <div class="row">
    <div class="col-12 col-md-4 offset-md-4 text-center">
      <img src="assets/loader.svg" class="d-block ml-auto mr-auto" alt="spinner">
    </div>
  </div>
</div>
<div *ngIf="!loading">
  <div class="row mt-5 mb-2">
    <div class="col-12 col-md-4 offset-md-4">
      <h2 class="font-weight-bold mb-5">Select a fixture</h2>
      <form class="form">
        <div class="form-group">
          <label class="font-weight-bold" for="fixtureDate">Select date of fixture:</label>
          <div class="input-group">
            <input class="form-control" placeholder="dd-mm-yyyy" id="fixtureDate" name="fixtureDate" [(ngModel)]="fixtureDate" (ngModelChange)="getFixtures($event)" ngbDatepicker #fixturesDate="ngbDatepicker" (click)="fixturesDate.toggle(); $event.stopPropagation();" (document:click)="datepickerFix($event, fixturesDate)" readonly>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="fixturesDate.toggle(); $event.stopPropagation();" type="button">
                <fa name="calendar"></fa>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row pt-3">
    <div class="col-12 col-md-4 offset-md-4">
      <ul class="list-group">
        <li  *ngFor="let fixture of fixturesList" class="btn list-group-item fixture-list-item" [ngClass]="{'fixture-abandoned': fixture.abandonedReasonCode}" routerLink="{{fixture.racesLink}}">{{fixture.courseName}}</li>
      </ul>
    </div>
  </div>
<!--  <div class="row pt-5">-->
<!--      <div class="col col-md-4 offset-md-4 text-center">-->
<!--        <button type="button" class="btn btn-block btn-primary" (click)="goSearch()">Search</button>-->
<!--      </div>-->
<!--    </div>-->
</div>
