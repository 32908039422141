import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestOptions } from '@angular/http';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';

@Injectable()
export class EntriesService {

  defaultHeaders: HttpHeaders;
  constructor(
    private http: HttpClient,
    private auth: AuthenticationService
  ) { }

  getEntryOldComments(horseId, rpcOnly = true, fromDate, headers = this.auth.getHeaders()): any {
    return this.http.get(`${environment.apiBase}/v2/comments?` +
    `horseId=${horseId}&rpcOnly=${rpcOnly ? 1 : 0}&toDate=${fromDate}`, {headers});
  }

  getEntryTodayComments(horseId, rpcOnly = true, fromDate, toDate, headers = this.auth.getHeaders()): any {
    return this.http.get(`${environment.apiBase}/v2/comments?` +
    `horseId=${horseId}&rpcOnly=${rpcOnly ? 1 : 0}&fromDate=${fromDate}&toDate=${toDate}`, {headers});
  }

  getEntryOldEnquiries(horseId, fromDate, headers = this.auth.getHeaders()): any {
    return this.http.get(`${environment.apiBase}/v2/enquiries?horseId=${horseId}&toDate=${fromDate}`, {headers});
  }

}
