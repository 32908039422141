<div class="results-list">
  <div class="col text-center pt-5" *ngIf="meta.loading">
    <img src="assets/loader.svg" class="d-block ml-auto mr-auto" alt="spinner">
    <br>Loading...
  </div>
  <div *ngIf="!meta.loading">
    <div class="row">
      <div class="col">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Comment</th>
            </tr>
          </thead>
          <tbody *ngIf="trainer.comments.length">
            <tr *ngFor="let hc of trainer.comments">
              <td><small><a [routerLink] (click)="openComment(hc.id)">{{hc.createdAt | date:'dd/MM/yy'}}</a></small></td>
              <td class="text-justify">
                <small *ngIf="hc.comment">{{hc.comment | slice:0:225}}<span *ngIf="hc.comment.length > 225">...</span></small>
                <small *ngIf="!hc.comment">
                  <span *ngIf="hc.racePerformanceComments && hc.racePerformanceComments.length">
                    <span *ngFor="let rpc of hc.racePerformanceComments; last as lastRPC">
                      {{rpc.comment}} <span *ngIf="!lastRPC">, </span>
                    </span>
                  </span>
                </small>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!trainer.comments.length">
            <tr class="text-center">
              <td class="p-5" colspan="2">No Comments found for this trainer</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col text-center">
    <ngb-pagination *ngIf="meta.results.lastPage > 1" class="d-flex justify-content-center" [collectionSize]="meta.results.totalResult" [pageSize]="meta.results.pageSize" [rotate]="true" [maxSize]="3" [(page)]="meta.results.currentPage" (pageChange)="loadResults(trainer.trainerId, $event)" aria-label="pagination"></ngb-pagination>
  </div>
</div>
