<form #scaleForm="ngForm">
  <div class="modal-header d-flex justify-content-center">
    <h4 class="modal-title text-truncate">{{meta.edit ? 'EDIT' : 'CREATE'}} SAMPLING REQUEST FOR {{meta.title | uppercase}}</h4>
  </div>
  <div class="modal-body" *ngIf="meta.loading">
    <div class="row">
      <div class="col text-center">
        <fa name="circle-o-notch" size="2x" animation="spin"></fa>
      </div>
    </div>
  </div>
  <div class="modal-body" *ngIf="!meta.loading">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label>Add one or more Reasons</label>
          <ng-select name="requestReasons"
            [items]="possibleReasons"
            [loading]="meta.possibleReasons.loading"
             bindLabel="reason"
             bindValue="id"
             [multiple]="true"
             [closeOnSelect]="false"
             placeholder="Select one or more Reasons"
             [(ngModel)]="samplingRequest.reasons">
        </ng-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <div class="form-group">
          <label for="commentText">Optional Comment</label>
          <textarea class="form-control textarea-height" id="commentText" name="commentText" [(ngModel)]="samplingRequest.additionalComment"></textarea>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!meta.saving" class="modal-footer d-flex justify-content-around">
    <button type="button" class="btn btn-outline-dark" (click)="cancel('cancel')">Close</button>
    <button *ngIf="samplingRequest" type="button" class="btn btn-danger" [disabled]="!entry.samplingId" (click)="remove()">Remove</button>
    <button *ngIf="samplingRequest" type="button" class="btn btn-primary" [disabled]="!samplingRequest.reasons || !samplingRequest.reasons.length" (click)="save()">Save</button>
  </div>
  <div *ngIf="meta.saving" class="modal-footer d-flex justify-content-around">
    <h1>
      <fa name="circle-o-notch" animation="spin"></fa>
    </h1>
  </div>
</form>
