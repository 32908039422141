import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { EventsService } from '../../services/events.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private router: Router,
    private eventsService: EventsService,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    // console.log('LOGOUT FROM SESSION', this.authService.getFromSession());
    this.authService.destroySession();
    this.authService.setHeaders(null, null);
    this.eventsService.changeUserData(null);
    // console.log('LOGOUT FROM SESSION COMPLETE', this.authService.getFromSession());
    this.router.navigate(['/login']);
  }

}
