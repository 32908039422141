import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../../../services/events.service';
import { ModalActionsService } from '../../../../services/modalActions.service';

@Component({
  selector: 'app-add-edit-comment',
  templateUrl: './add-edit-comment.component.html',
  styleUrls: ['./add-edit-comment.component.scss']
})
export class AddEditCommentComponent implements OnInit {

  @Input() comment;
  @Input() possibleSubjects;
  possibleRpcComments: any[];
  meta: {
    title: string,
    edit: boolean,
    loading: boolean,
    saving: boolean,
    possibleRpcComments: {
      loading: boolean,
      saving: boolean
    }
  };
  userId: number;

  constructor(
    private activeModal: NgbActiveModal,
    private notification: ToastrService,
    private eventsService: EventsService,
    private modalActionsService: ModalActionsService,
  ) { }

  ngOnInit() {
    console.log('Opening comment edit/create', this.comment);
    this.meta = {
      title: '',
      edit: this.comment && this.comment.id ? true : false,
      loading: false,
      saving: false,
      possibleRpcComments: {
        loading: false,
        saving: false
      }
    };

    this.eventsService.observableUser.subscribe(data => {
      this.userId = data.id;
    });

    this.prepareModal();
  }

  prepareModal = () => {
    // console.log(this.comment.subject.dateTime);
    switch (this.comment.subject.type) {
      case 'fixture': this.meta.title = `for ${this.comment.subject.courseName}`; break;
      case 'race': this.meta.title = `for the ${moment(this.comment.subject.dateTime).format('HH:mm')} race`; break;
      default: this.meta.title = `for '${this.comment.subject.knownAs}'`;
    }
    if (this.comment.published === undefined) {
      this.comment.published = false;
    }
    this.getRpcComments();
  }

  getRpcComments = () => {
    this.meta.possibleRpcComments.loading = true;
    if (this.comment.subject.type === 'horse') {
      this.modalActionsService.getRpcComments(this.comment.subject.type).subscribe(
        res => {
          this.possibleRpcComments = res;
          if (this.comment.rpcComments && this.comment.rpcComments.length) {
            this.comment.rpcComments = this.comment.rpcComments.map(rpc => rpc.id || rpc);
          }
          this.meta.possibleRpcComments.loading = false;
        },
        err => {
          this.notification.error(`Could't retrieve the list of RPC comments: ` +
          `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
          this.meta.possibleRpcComments.loading = false;
        }
      );
    }
  }

  save = () => {
    this.meta.saving = true;
    this.comment.userId = this.userId;
    console.log('saving comment', this.comment);
    let request = this.modalActionsService.createComment(this.comment);
    if (this.comment.id) {
      request = this.modalActionsService.updateComment(this.comment);
    }
    request.subscribe(
      res => {
        console.log('Comment saved', res);
        this.eventsService.changeCommentsData({section: res.subject.type + 'Comments', result: res});
        this.notification.success(`Comment saved.`, 'Success!');
        this.activeModal.close(res);
        this.meta.saving = false;
      },
      err => {
        this.notification.error(`Could't save this comment: ` +
        `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
        this.meta.saving = false;
      }
    );
  }

  cancel = (action) => {
    this.activeModal.dismiss(action);
  }

}
