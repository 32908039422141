import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import * as moment from 'moment';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FixturesService } from '../../services/fixtures.service';
import { RacesService } from '../../services/races.service';
import { EventsService } from '../../services/events.service';
import { HermesService } from '../../services/hermes.service';

import { RaceFalseStartComponent } from '../modals/actions/race-false-start/race-false-start.component';
import { RaceVoidRaceComponent } from '../modals/actions/race-void-race/race-void-race.component';
import { RaceUpdateStatusComponent } from '../modals/actions/race-update-status/race-update-status.component';
import { AddEditCommentComponent } from '../modals/actions/add-edit-comment/add-edit-comment.component';
import {FixtureUpdateOfficialsComponent} from '../modals/actions/fixture-update-officials/fixture-update-officials.component';
import {RaceLateRaceComponent} from '../modals/actions/race-late-race/race-late-race.component';
import {RaceLeftParadeRingComponent} from '../modals/actions/race-left-parade-ring/race-left-parade-ring.component';
import {RaceRequestedDelayComponent} from '../modals/actions/race-requested-delay/race-requested-delay.component';
import {FixtureSuspensionDatesComponent} from '../modals/actions/fixture-suspension-dates/fixture-suspension-dates.component';

@Component({
  selector: 'app-races',
  templateUrl: './races.component.html',
  styleUrls: ['./races.component.scss']
})
export class RacesComponent implements OnInit {
  hermesConnection: any;
  meta: {
    loading: boolean,
    saving: boolean,
    fixtureInfo: {
      loading: boolean,
      saving: boolean
    },
    fixtureComments: {
      loading: boolean,
      saving: boolean
    },
    races: {
      loading: boolean,
      saving: boolean
    },
    raceInfo: {
      loading: boolean,
      saving: boolean
    },
    raceComments: {
      loading: boolean,
      saving: boolean
    },
    raceEntries: {
      loading: boolean,
      saving: boolean
    }
  };
  racesList: any;
  selectedFixture: any;
  selectedRace: any;
  activeRace: string;


  constructor(
    private notification: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private fixturesService: FixturesService,
    private racesService: RacesService,
    private eventsService: EventsService,
    private hermes: HermesService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.meta = {
      loading: false,
      saving: false,
      fixtureInfo: {
        loading: false,
        saving: false
      },
      fixtureComments: {
        loading: false,
        saving: false
      },
      races: {
        loading: false,
        saving: false
      },
      raceInfo: {
        loading: false,
        saving: false
      },
      raceComments: {
        loading: false,
        saving: false
      },
      raceEntries: {
        loading: false,
        saving: false
      }
    };
    this.selectedFixture = {
      fixtureId: Number(this.route.snapshot.paramMap.get('fixtureId')),
      fixtureYear: this.route.snapshot.paramMap.get('fixtureYear')
    };

    this.route.params.subscribe(params => {
      if (Object.keys(params).length) {
        this.getFixtureInfo(this.selectedFixture.fixtureYear, this.selectedFixture.fixtureId)
        .then(res => {
          this.getFixtureComments(this.selectedFixture.fixtureYear, this.selectedFixture.fixtureId);
          this.getFixtureRaces(this.selectedFixture.fixtureYear, this.selectedFixture.fixtureId, params.raceId, params.divisionSequence);
        }).catch(err => {
          console.error('[ DEBUG ]', err);
          if (err.status === 401) {
            this.router.navigate(['/logout']);
          }
        });
      }
    });
  }

  getFixtureInfo = (fixtureYear, fixtureId): Promise<any> => {

    return new Promise((resolve, reject) => {
      this.meta.fixtureInfo.loading = true;
      this.fixturesService.getFixtureInfo({fixtureYear, fixtureId}).subscribe(
        res => {
          this.selectedFixture = res;
          this.selectedFixture.fixtureYear = this.selectedFixture.year;
          this.eventsService.changeFixtureData(this.selectedFixture);

          // console.log('Fixture Info:', this.selectedFixture);
          this.meta.fixtureInfo.loading = false;
          resolve(res);
        },
        err => {

          this.notification.error(`Could't get the Fixture Details: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
          this.meta.fixtureInfo.loading = false;
          reject(err);
        }
      );
    });
  }

  getFixtureComments = (fixtureYear, fixtureId): void => {
    this.meta.fixtureComments.loading = true;
    this.fixturesService.getFixtureComments({fixtureYear, fixtureId}).subscribe(
      res => {
        this.selectedFixture.comments = res.data;
        // console.log('Fixture Comments:', this.selectedFixture.comments);
        this.meta.fixtureComments.loading = false;
      },
      err => {

        this.notification.error(`Could't get the Fixture Comments: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.fixtureComments.loading = false;
      }
    );
  }

  // Get race list to build the tabs
  getFixtureRaces = (fixtureYear, fixtureId, raceId, divisionSequence): void => {
    this.meta.races.loading = true;
    this.fixturesService.getFixtureRaces({fixtureYear, fixtureId}).subscribe(
      res => {
        this.racesList = res.map(race => ({
          raceDatetime: moment(race.raceDateTime).toISOString(),
          raceName: race.name,
          raceId: race.raceId,
          raceYear: race.year,
          fixtureId: race.fixtureId,
          divisionSequence: race.divisionSequence,
          info: {},
          comments: [],
          enquiries: []
        }));

        // Load race details
        this.initSelectRace(raceId, divisionSequence);
        // console.log('Fixture Races:', this.racesList);
        this.meta.races.loading = false;
      },
      err => {

        this.notification.error(`Could't get the Races List: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.races.loading = false;
      }
    );
  }

  // Used on page loading to bring up the right race given the URL params
  initSelectRace = (raceId, divisionSequence) => {
    const initialRaceIndex = this.racesList.findIndex(r => r.raceId === Number(raceId) && r.divisionSequence === Number(divisionSequence));
    if (initialRaceIndex !== -1) {
      this.activeRace = `race-${initialRaceIndex}`;
      this.selectRace(initialRaceIndex);
    } else {
      this.selectRace(0);
    }
  }

  // Load race detailed information
  selectRace = (raceIndex = 0) => {
    this.selectedRace = this.racesList[raceIndex];
    this.location.replaceState(`/races/${this.selectedFixture.fixtureYear}/` +
    `${this.selectedFixture.fixtureId}/${this.racesList[raceIndex].raceId}/${this.racesList[raceIndex].divisionSequence}`);

    this.getRaceEntries(this.selectedRace);
  }

  getRaceEntries = (race) => {
    this.meta.raceEntries.loading = true;
    this.selectedRace.horsesComments = [];
    this.racesService.getRaceEntries(this.selectedRace).subscribe(
      res => {
        this.startListener();
        this.selectedRace.entries = res.sort((a, b) => a.saddleClothNumber - b.saddleClothNumber);
        this.selectedRace.entries.forEach(entry => {
          entry.mostRecentRPC = entry.mostRecentRPC && entry.mostRecentRPC.length > 150
            ? entry.mostRecentRPC.slice(0, 100) + ' ...'
            : entry.mostRecentRPC;
          entry.mostRecentEnquiry = entry.mostRecentEnquiry && entry.mostRecentEnquiry.length > 150
          ? entry.mostRecentEnquiry.slice(0, 100) + ' ...'
          : entry.mostRecentEnquiry;
        });
        // console.log('Race Entries:', this.selectedRace.entries);
        this.meta.raceEntries.loading = false;
      },
      err => {

        this.notification.error(`Could't get the ${moment(this.selectedRace.raceDatetime).format('HH:mm')}` +
        ` race's data: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.raceEntries.loading = false;
      }
    );
  }

  startListener() {
    if (this.hermesConnection) {
      this.hermesConnection.unsubscribe();
    }
    this.hermesConnection = this.hermes.updates().subscribe(data => {
      // Determine if the event occurred in the current race
      if (
        this.selectedRace.raceYear === data['year'] &&
        this.selectedRace.fixtureId === data['fixtureId'] &&
        this.selectedRace.raceId === data['raceId'] &&
        this.selectedRace.divisionSequence === data['divisionSequence']
      ) {
        // Determine if the entry exists in the current race
        // this.selectedRace.todoList = {
        //   withdrawn: this.selectedRace.entries.filter(e => e.indicators.isWithdrawn),
        //   jockeyChange: this.selectedRace.entries.filter(e => e.indicators.hasJockeyChange),
        //   nonRunner: this.selectedRace.entries.filter(e => e.nonRunnerDeclaredDate)
        // };
        const entryIndex = this.selectedRace.entries.findIndex(entry => entry.horseId === data['horseId']);
        console.log('[ DEBUG ] Hermes sent data for this race', data);

        // this is coming from cos(api06) when a horse has been disqualified
        if (data['type'] === 'positions') {
          if (Array.isArray(data['entries'])) {
            data['entries'].forEach(entry => {
              const entryToUpdate = this.selectedRace.entries.find(
                runner => runner.horseId === entry['animalId']
              );

              if (!entryToUpdate) return;

              entryToUpdate.positionCos = entry['positionCos'];
              entryToUpdate.glow = true;
              setTimeout(() => {
                entryToUpdate.glow = false;
              }, 2000);

              this.notification.info('New positions have been updated.', 'Information');
            });
          } else {
            console.log('Entries array is missing or invalid.');
          }
        // this is coming from tetra pfo
        } else if (data['type'] === 'finishingPositions') {
          if (Array.isArray(data['entries'])) {
            data['entries'].forEach(entry => {
              const entryToUpdate = this.selectedRace.entries.find(
                runner => runner.horseId === entry['animalId']
              );

              if (!entryToUpdate) return;

              entryToUpdate.positionPfo = entry['finishingPosition'];
              entryToUpdate.distance = entry['distance'];
              entryToUpdate.glow = true;
              setTimeout(() => {
                entryToUpdate.glow = false;
              }, 2000);

              this.notification.info('New finishing positions have been updated.', 'Information');
            });
          } else {
            console.log('Entries array is missing or invalid.');
          }
        } else if (entryIndex !== -1) {
          switch (data['type']) {
            case 'nonRunner': {
              this.selectedRace.entries[entryIndex].nonRunnerDeclaredDate = data['nonRunnerDeclaredDate'];
              this.selectedRace.entries[entryIndex].nonRunnerDeclaredTime = data['nonRunnerDeclaredTime'];
              this.selectedRace.entries[entryIndex].nonRunnerDeclaredReason = data['nonRunnerDeclaredReason'];
              this.notification.info(`A new non-runner has been received.`, 'Information');
              this.eventsService.changeRacecardData({section: 'todoList', context: 'nonRunner',
                result: Object.assign({}, this.selectedRace.entries[entryIndex])
              });
            } break;
            case 'withdrawn': {
              this.selectedRace.entries[entryIndex].indicators.isWithdrawn = data['isWithdrawn'];
              if (data['isWithdrawn'] === true) {
                this.notification.info(`A new withdrawal has been received.`, 'Information');
              }
              this.eventsService.changeRacecardData({section: 'todoList', context: 'withdrawn',
                result: Object.assign({}, this.selectedRace.entries[entryIndex])
              });
            } break;
            case 'jockeyChange': {
              this.selectedRace.entries[entryIndex].indicators.hasJockeyChange = true;
              this.selectedRace.entries[entryIndex].jockeyChangeHistory.push(data);
              this.selectedRace.entries[entryIndex].jockeyId = data['newJockeyId'];
              this.selectedRace.entries[entryIndex].jockeyKnownAs = data['newJockeyKnownAs'];
              this.selectedRace.entries[entryIndex].jockeyClaim = data['newJockeyClaim'];
              this.notification.info(`A new Jockey Change has been received.`, 'Information');
              this.eventsService.changeRacecardData({section: 'todoList', context: 'jockeyChange',
                result: Object.assign({}, this.selectedRace.entries[entryIndex])
              });
            } break;
            case 'disqualification': {
              this.selectedRace.entries[entryIndex].disqualifiedTimestamp = data['disqualifiedTimestamp'];
              this.selectedRace.entries[entryIndex].glow = true;
              setTimeout(() => {
                this.selectedRace.entries[entryIndex].glow = false;
              }, 2000);

              this.notification.info('A new disqualification has been received.', 'Information');
              this.eventsService.changeRacecardData({section: 'todoList', context: 'disqualification',
                result: Object.assign({}, this.selectedRace.entries[entryIndex])
              });
            }
              break;
            case 'nonFinisher': {
              this.selectedRace.entries[entryIndex].nonFinishingCode = data['nonFinishingCode'];
              this.selectedRace.entries[entryIndex].glow = true;
              setTimeout(() => {
                this.selectedRace.entries[entryIndex].glow = false;
              }, 2000);

              this.notification.info('A new DNF has been received.', 'Information');
              this.eventsService.changeRacecardData({section: 'todoList', context: 'nonFinisher',
                result: Object.assign({}, this.selectedRace.entries[entryIndex])
              });
            }
              break;
            case 'position': {
              console.log(this.selectedRace.entries[entryIndex]);
              this.selectedRace.entries[entryIndex].positionCos = data['position'];
              this.selectedRace.entries[entryIndex].glow = true;
              setTimeout(() => {
                this.selectedRace.entries[entryIndex].glow = false;
              }, 2000);

              this.notification.info('A new position has been received.', 'Information');
              this.eventsService.changeRacecardData({section: 'todoList', context: 'position',
                result: Object.assign({}, this.selectedRace.entries[entryIndex])
              });
            }
              break;
            default:
              console.log('[ DEBUG ] Unexpected Event:', data);
              break;
          }
        } else {
          console.log('[ DEBUG ] Hermes sent data but can\'t find the entry in this race', data);
        }
      } else {
        console.log('[ DEBUG ] Hermes sent data for another race/fixture', data);
      }
    });
  }

  openAddComment = (race) => {
    const comment = {
      subject: {
        id: race.raceId,
        year: race.raceYear,
        divisionSequence: race.divisionSequence,
        name: race.raceName,
        dateTime: race.raceDatetime,
        type: 'race'
      }
    };
    const modalWindow = this.modalService.open(AddEditCommentComponent, { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.comment = comment;
    modalWindow.result.then((result) => {
      console.log(`[ DEBUG ] Modal Closed with response`, result);
      // this.selectedRace.comments.push = result;
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  openUpdateStatus = (race) => {
    const modalWindow = this.modalService.open(RaceUpdateStatusComponent, { centered: true, backdrop: 'static'});
    modalWindow.componentInstance.race = race;
    modalWindow.result.then((result) => {
      console.log(`[ DEBUG ] Modal Closed with payload`, result);
      this.selectedRace.info.status = result.status;
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  openFalseStart = (race) => {
    const modalWindow = this.modalService.open(RaceFalseStartComponent, { centered: true, backdrop: 'static'});
    modalWindow.componentInstance.race = race;
    modalWindow.result.then((result) => {
      console.log(`[ DEBUG ] Modal Closed with payload`, result);
      this.selectedRace.info.falseStart = result.falseStart;
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  openVoidRace = (race) => {
    const modalWindow = this.modalService.open(RaceVoidRaceComponent, { centered: true, backdrop: 'static'});
    modalWindow.componentInstance.race = race;
    modalWindow.result.then((result) => {
      console.log(`[ DEBUG ] Modal Closed with payload`, result);
      this.selectedRace.info.void = result.void;
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  openLateRace = (race) => {
    const lateRace = {
      subject: {
        id: race.raceId,
        year: race.raceYear,
        divisionSequence: race.divisionSequence,
        name: race.raceName,
        dateTime: race.raceDatetime,
        type: 'race'
      }
    };
    const modalWindow = this.modalService.open(RaceLateRaceComponent, { centered: true, backdrop: 'static'});
    modalWindow.componentInstance.race = race;
    modalWindow.result.then((result) => {
      console.log(`[ DEBUG ] Modal Closed with payload`, result);
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }



  openLeftParadeRing = (race) => {
    const leftParadeRingRace = {
      subject: {
        id: race.raceId,
        year: race.raceYear,
        divisionSequence: race.divisionSequence,
        name: race.raceName,
        dateTime: race.raceDatetime,
        type: 'race'
      }
    };
    const modalWindow = this.modalService.open(RaceLeftParadeRingComponent, { centered: true, backdrop: 'static'});
    modalWindow.componentInstance.race = race;
    modalWindow.result.then((result) => {
      console.log(`[ DEBUG ] Modal Closed with payload`, result);
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }


  openRequestedDelay = (race) => {
    const requestedDelayRace = {
      subject: {
        id: race.raceId,
        year: race.raceYear,
        divisionSequence: race.divisionSequence,
        name: race.raceName,
        dateTime: race.raceDatetime,
        type: 'race'
      }
    };
    const modalWindow = this.modalService.open(RaceRequestedDelayComponent, { centered: true, backdrop: 'static'});
    modalWindow.componentInstance.race = race;
    modalWindow.result.then((result) => {
      console.log(`[ DEBUG ] Modal Closed with payload`, result);
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  openAddFixtureComment = (fixture) => {
    const comment = {
      subject: {
        id: fixture.fixtureId,
        year: fixture.fixtureYear,
        courseName: fixture.courseName,
        type: 'fixture'
      }
    };
    const modalWindow = this.modalService.open(AddEditCommentComponent, { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.comment = comment;
    modalWindow.result.then((result) => {
      console.log(`[ DEBUG ] Modal returned value:`, result);
      // this.selectedFixture.comments.push = result;
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  openUpdateOfficials = (fixture) => {
    const modalWindow = this.modalService.open(FixtureUpdateOfficialsComponent, { centered: true, backdrop: 'static'});
    modalWindow.componentInstance.fixture = fixture;
    modalWindow.result.then((result) => {
      console.log(`[ DEBUG ] Modal Closed with payload`, result);
      this.selectedFixture.officials = result.officials;
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  openSuspensionDateInfo = (fixture) => {
    const modalWindow = this.modalService.open(FixtureSuspensionDatesComponent, { centered: true, backdrop: 'static'});
    modalWindow.componentInstance.fixture = fixture;
  }
}
