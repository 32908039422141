import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap } from 'rxjs/operators';

import * as moment from 'moment';

import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../../../services/events.service';
import { ModalActionsService } from '../../../../services/modalActions.service';

@Component({
  selector: 'app-add-edit-enquiry',
  templateUrl: './add-edit-enquiry.component.html',
  styleUrls: ['./add-edit-enquiry.component.scss']
})
export class AddEditEnquiryComponent implements OnInit {

  @Input() enquiry;
  @Input() nextStage;
  @Input() possibleOffenders;
  private suspensionDateRequest: Subscription;
  meta: {
    title: string;
    edit: boolean;
    loading: boolean;
    saveEnabled: boolean;
    saving: boolean;
    possibleOffences: {
      loading: boolean,
      loadingFailed: boolean,
      saving: boolean
    };
    possibleCategories: Array<any>,
    appealOutcomes: Array<Object>,
    depositOutcomes: Array<Object>,
    possibleStages: Array<string>,
    possibleOutcomes: {
      loading: boolean,
      saving: boolean
    };
    possibleSuspensionDates: {
      loading: boolean,
      saving: boolean
    };
    cameraAngles: {
      loading: boolean,
      saving: boolean
    };
    enquiryMeta: {
      itemNote: string,
      tottedUpDays: number,
      tottedUpOccurrence: number,
      tottingUpPeriod: number,
      tottedUpDeferredSuspension: number
    };
  };
  possibleOffences: any[];
  formOutcomes: object;
  possibleCameras: any[];
  userId: number;
  userIsDisciplinary: boolean;
  userIsSteward: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private notification: ToastrService,
    private eventsService: EventsService,
    private modalActionsService: ModalActionsService,
  ) { }

  ngOnInit() {
    // console.log('Opening enquiry edit/create', this.enquiry);
    this.meta = {
      title: '',
      edit: this.enquiry && this.enquiry.id ? true : false,
      loading: false,
      saveEnabled: false,
      saving: false,
      possibleOffences: {
        loading: false,
        loadingFailed: false,
        saving: false
      },
      possibleCategories: [
        { value: 1, text: 'Disciplinary'  },
        { value: 2, text: 'Racecourse'  },
      ],
      appealOutcomes: [
        { value: 'upheld', text: 'Upheld' },
        { value: 'upheld_in_part', text: 'Upheld In Part' },
        { value: 'dismissed', text: 'Dismissed' },
      ],
      depositOutcomes: [
        { value: 'forfeited', text: 'Forfeited' },
        { value: 'returned', text: 'Returned' },
        { value: 'n/a', text: 'N/A' },
      ],
      possibleStages: [
        'racecourse',
        'dp_committee',
        'appeal',
        'legal'
      ],
      possibleOutcomes: {
        loading: false,
        saving: false
      },
      possibleSuspensionDates: {
        loading: false,
        saving: false
      },
      cameraAngles: {
        loading: false,
        saving: false
      },
      enquiryMeta: null
    };

    this.eventsService.observableUser.subscribe(data => {
      this.userId = data.id;
      this.userIsDisciplinary = data.roles.find(r => r === 'ROLE_HEAD_OFFICE') !== undefined;
      this.userIsSteward = data.roles.find(r => r === 'ROLE_STEWARD_ROOM_ASSISTANT' || r === 'ROLE_STIPE') !== undefined;
    });

    this.prepareModal();
  }

  applyJockeyMaster() {
    this.enquiry.offender.applyJockeyMaster = !this.enquiry.offender.applyJockeyMaster;
  }

  formatFormOutcome(outcome) {
    if (
        (this.userIsSteward
          && (outcome.applicableByRole === 'ROLE_STEWARD_ROOM_ASSISTANT' ||  outcome.applicableByRole === 'ROLE_STIPE') ) ||
        // (this.userIsDisciplinary && outcome.applicableByRole === 'ROLE_HEAD_OFFICE')
        (this.userIsDisciplinary)
      ) {
      this.formOutcomes[outcome.objectType] = {
        id: outcome.id || null,
        value: outcome.value,
        label: outcome.objectType.replace(/([A-Z])/g, ' $1'),
        type: outcome.objectType,
        isApplied: outcome.isApplied,
        attributes: Object.assign({}, outcome.attributes),
      };
      this.formOutcomes[outcome.objectType].suggestion = this.formatSuggestions(outcome.attributes);
      if (outcome.objectType === 'suspension') {
        if (outcome.suspensionDates && outcome.suspensionDates.length) {
          this.formOutcomes[outcome.objectType].datesPending = false;
        } else {
          this.formOutcomes[outcome.objectType].datesPending = true;
        }
      }
    }
  }

  formatSuggestions(attributes) {
    const suggestions = {};
    if (attributes && Object.keys(attributes).length) {
      if (attributes.period) {
        suggestions['period'] = attributes.period;
      }
      if (this.userIsSteward) {
        if (attributes.role_steward_room_assistant_lower) {
          suggestions['lower'] = attributes.role_steward_room_assistant_lower;
        }
        if (attributes.role_steward_room_assistant_upper) {
          suggestions['upper'] = attributes.role_steward_room_assistant_upper;
        }
      }
      if (this.userIsDisciplinary) {
        if (attributes.role_steward_room_assistant_lower) {
          suggestions['lower'] = attributes.role_steward_room_assistant_lower;
        }
        if (attributes.role_head_office_lower) {
          suggestions['lower'] = attributes.role_head_office_lower;
        }
        if (attributes.role_steward_room_assistant_upper) {
          suggestions['upper'] = attributes.role_steward_room_assistant_upper;
        }
        if (attributes.role_head_office_upper) {
          suggestions['upper'] = attributes.role_head_office_upper;
        }
      }
      return suggestions;
    }
  }

  prepareModal = () => {
    console.log('start', this.enquiry);
    this.meta.title = `for '${this.enquiry.offender.knownAs}'`;
    this.possibleOffences = [];
    this.formOutcomes = {};
    this.meta.enquiryMeta = {
      itemNote: this.enquiry.itemNote,
      tottedUpDays: this.enquiry.tottedUpDays,
      tottedUpOccurrence: this.enquiry.tottedUpOccurrence,
      tottingUpPeriod: this.enquiry.tottingUpPeriod,
      tottedUpDeferredSuspension: this.enquiry.tottedUpDeferredSuspension
    };
    const enquiryIds = {
      year: this.enquiry.race && this.enquiry.race.year ? this.enquiry.race.year : null,
      raceId: this.enquiry.race && this.enquiry.race.raceId ? this.enquiry.race.raceId : null,
      divisionSequence: this.enquiry.race && this.enquiry.race.divisionSequence !== undefined ? this.enquiry.race.divisionSequence : null,
      horseId: this.enquiry.horse && this.enquiry.horse.horseId ? this.enquiry.horse.horseId : null
    };
    if (!this.enquiry.enquiryId) {
      console.log('NEW');
      this.enquiry.published = 0;
      this.enquiry.category = 2;
      this.enquiry.stage = 'racecourse';
      this.enquiry.offence = null;
      this.enquiry.outcomes = null;
      this.enquiry.meta = this.enquiry.meta ? this.enquiry.meta : null;
    } else {
      console.log('EDIT');
      this.getCameraAngles();
      if (this.enquiry.outcomes && this.enquiry.outcomes.length) {
        this.enquiry.outcomes.forEach(outcome => {
          this.formatFormOutcome(outcome);
          // console.log('Received Outcomes:', outcome.objectType, outcome.value);
          if (this.typeHasSuspensionDates(outcome.objectType)) {
            if (outcome.suspensionDates.length) {
              this.formOutcomes[outcome.objectType].suspensionDates = outcome.suspensionDates
                .map( el => ({ date: moment(el.date).format('YYYY-MM-DD') }) );
            } else {
              this.formOutcomes[outcome.objectType].suspensionDates = [];
              for (let i = 0; i < this.formOutcomes[outcome.objectType].value; i++) {
                this.formOutcomes[outcome.objectType].suspensionDates.push({date: ''});
              }
            }
          }
        });
      }
      this.enquiry = {
        enquiryId: this.enquiry.enquiryId,
        category: this.enquiry.category,
        stage: this.enquiry.stage,
        appealConclusion: this.enquiry.appealConclusion,
        deposit: this.enquiry.deposit,
        published: this.enquiry.published,
        offender: this.enquiry.offender,
        offence: {
          offenceId: this.enquiry.offence.id,
          offenceDescription: this.enquiry.offence.offence
        },
        cameraAngles: this.enquiry.cameraAngles.map(c => c.id),
        cameraStartTime: this.enquiry.cameraStartTime,
        cameraStopTime: this.enquiry.cameraStopTime,
        ptcComment: this.enquiry.ptcComment ? this.enquiry.ptcComment.comment : null,
        notice: this.enquiry.notice,
        rir: this.enquiry.rir,
        rirComment: this.enquiry.rirComment,
        meta: this.enquiry.meta ? this.enquiry.meta : enquiryIds
      };
      /* Some insight in the next IF:
      *  Object.values() return value of keys in array format
      *  Array.some() return true if at least one element verify the condition, for this reason we check for `not-null`
      *  Because we check for `not-null`, we need to invert the whole condition as well, thus the first `not`
      */
      // Check meta for generic enquiries - if all ids in meta are `null`, set meta to null
      if ( !Object.values(this.enquiry.meta).some(attr => (attr !== null)) ) {
        this.enquiry.meta = null;
      }
      this.meta.saveEnabled = this.isSaveAllowed();
    }
    this.enquiry.userId = this.userId;
    console.log('END', this.enquiry);

    if (this.nextStage) {
      const nextStageIndex = this.meta.possibleStages.findIndex(s => s === this.enquiry.stage);
      // console.log(`[ DEBUG ] Requested to move to the next stage from ` +
      //   `${this.enquiry.stage} to ${this.meta.possibleStages[nextStageIndex]}`);
      this.enquiry.stage = this.meta.possibleStages[nextStageIndex + 1];
    }
    // console.log('[ DEBUG ] Enquiry ready:', this.enquiry, 'Outcomes:', this.formOutcomes);
  }

  updateStage(category) {
    // console.log(`[ DEBUG ] Updating Stage`);
    if (!this.enquiry.id) {
      if (category === 1) {
        this.enquiry.stage = 'dp_committee';
      } else {
        this.enquiry.stage = 'racecourse';
      }
    }
  }

  offenceFormat = (x: { offenceId: number, offenceDescription: string, subOffenceDescription: string }) =>
  x.offenceDescription + (x.subOffenceDescription ? ' - ' + x.subOffenceDescription : '')
  searchOffences = (query$: Observable<string>) => query$.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(q => {
      if (q.length < 3) {
        return;
      } else {
        this.meta.possibleOffences.loading = true;
        return q;
      }
    }),
    // tap(() => this.meta.possibleOffences.loading = true),
    switchMap(q => this.modalActionsService.getEnquiryOffences(q, this.enquiry.offender.type, this.enquiry.category)
      .pipe(
        // map(res => res.data.slice(0, 6)),
        map(res => res.data),
        tap(() => this.meta.possibleOffences.loadingFailed = false),
        catchError((err) => {
          this.meta.possibleOffences.loadingFailed = true;
          return of([]);
        })
      )
    ),
    tap(() => this.meta.possibleOffences.loading = false)
  )

  getOutcomes = (selection) => {
    const offence = selection.item;
    this.meta.enquiryMeta = null;
    this.formOutcomes = {};
    if (offence !== undefined) {
      this.getCameraAngles();
      this.meta.possibleOutcomes.loading = true;
      this.modalActionsService.getEnquiryOutcomes(offence, this.enquiry.offender).subscribe(
        res => {
          res.outcomes.forEach(outcome => {
            this.formatFormOutcome(outcome);
          });
          this.meta.enquiryMeta = {
            itemNote: res.itemNote,
            tottedUpDays: res.tottedUpDays,
            tottedUpOccurrence: res.tottedUpOccurrence,
            tottingUpPeriod: res.tottingUpPeriod,
            tottedUpDeferredSuspension: res.tottedUpDeferredSuspension
          };
          // console.log('Offence:', offence, 'Outcomes:', this.formOutcomes);
          this.meta.saveEnabled = this.isSaveAllowed();
          this.meta.possibleOutcomes.loading = false;
        },
        err => {
          this.notification.error(`Could't load the possible outcomes: ` +
          `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
          this.meta.possibleOutcomes.loading = false;
        },
        () => {
          this.meta.saveEnabled = this.isSaveAllowed();
        }
      );
    }
  }

  isSaveAllowed() {
    if (!this.formOutcomes) {
      return false;
    }
    if (!this.formOutcomes['inBreach'] || this.formOutcomes['inBreach'].value === null) {
      return false;
    }
    return true;
  }

  suspensionListener = (outcomeType) => {
    if (!this.formOutcomes[outcomeType].isApplied || this.formOutcomes[outcomeType].datesPending) {
      this.formOutcomes[outcomeType].value = null;
      this.formOutcomes[outcomeType].suspensionDates = [];
    } else {
      if (!this.formOutcomes[outcomeType].value) {
        this.formOutcomes[outcomeType].value = this.formOutcomes[outcomeType].suggestion.lower;
        this.getSuspensionDates(
          this.formOutcomes[outcomeType].value,
          outcomeType,
          this.formOutcomes[outcomeType].suggestion.period
        );
      }
    }
    // console.log('Suspension listener', this.formOutcomes[outcomeType]);
  }

  fineListener = (toggle, outcomeType) => {
    if (toggle === false) {
      this.formOutcomes[outcomeType].value = null;
    }
    if (toggle === true) {
      if (!this.formOutcomes[outcomeType].value) {
        this.formOutcomes[outcomeType].value = this.formOutcomes[outcomeType].suggestion.lower;
      }
    }
  }

  typeHasSuspensionDates = (outcomeType) => {
    return outcomeType === 'suspension';
  }

  getSuspensionDates = (input, outcomeType, period) => {
    // console.log('Suspension Dates request:', this.formOutcomes[outcomeType].value, outcomeType, period);
    // Known Bug of Angular require this workaround: https://github.com/angular/angular/issues/12540
    // amount = amount && (typeof amount === 'object') ? Number(amount.data) : Number(amount);
    const amount = this.formOutcomes[outcomeType].value;
    if (amount) {
      if (this.suspensionDateRequest) {
        this.suspensionDateRequest.unsubscribe();
      }
      this.meta.possibleSuspensionDates.loading = true;
      period = period.toLowerCase().replace('(s)', 's');
      this.formOutcomes[outcomeType].suspensionDates = [];
      if (this.enquiry.meta && this.enquiry.meta.raceId) {
        this.suspensionDateRequest = this.modalActionsService
        .getEnquirySuspensionDates(this.enquiry, amount, period).subscribe(
          res => {
            this.formOutcomes[outcomeType].suspensionDates = res.map(sd => ({ date: moment(sd.date).format('YYYY-MM-DD') }));
            this.meta.possibleSuspensionDates.loading = false;
            console.log('Dates: ', this.formOutcomes[outcomeType].value, this.formOutcomes[outcomeType].suspensionDates);
          },
          err => {
            this.notification.error(`Could't load the possible suspension dates: ` +
            `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
            for (let i = 0; i < amount; i++) {
              this.formOutcomes[outcomeType].suspensionDates.push({date: ''});
            }
            this.meta.possibleSuspensionDates.loading = false;
          }
        );
      } else {        
        if(period == 'months'){
          this.formOutcomes[outcomeType].suspensionDates.push({date: ''});
          this.formOutcomes[outcomeType].suspensionDates.push({date: ''});
        }else{
          for (let i = 0; i < amount; i++) {
            this.formOutcomes[outcomeType].suspensionDates.push({date: ''});
          }
        }
        this.meta.possibleSuspensionDates.loading = false;
      }
    } else {
      this.formOutcomes[outcomeType].suspensionDates = [];
    }
  }

  getCameraAngles() {
    this.meta.cameraAngles.loading = true;
    this.modalActionsService.getCameraAngles().subscribe(
      res => {
        this.possibleCameras = res.map(camera =>
          ({id: camera.id, name: camera.title + (camera.description ? ' - ' + camera.description : '')}));
        this.meta.cameraAngles.loading = false;
      },
      err => {
        this.notification.error(`Could't load the possible camera angles: ` +
        `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
        this.meta.cameraAngles.loading = false;
      }
    );
  }

  suspensionDatesValidation(suspensionOutcome) {
    let valid = true;
    if (suspensionOutcome.type === 'suspension') {
      if (suspensionOutcome.isApplied) {
        if (!suspensionOutcome.datesPending) {
          if (suspensionOutcome.value) {
            // Check if not all dates are provided
            if ( !suspensionOutcome.suspensionDates.every(sd => sd.date.length === 10) ) {
              valid = false;
            }
            // If the previous check was true
            if (valid === false) {
              // Check if all dates are empty
              if ( suspensionOutcome.suspensionDates.every(sd => sd.date.length === 0) ) {
                // ... alright, you can go then
                valid = true;
              } else {
                // ... you did fuck up, boy
                this.notification.error(`Either all suspension dates are provided or none at all.`, 'Error!');
              }
            }
          } else {
            this.notification.error(`Suspension Outcome has been selected but not amount has been specified.`, 'Error!');
            valid  = false;
          }
        }
      }
    }
    return valid;
  }

  save = () => {
    const payload = Object.assign({}, this.enquiry, this.meta.enquiryMeta);
    payload.date = payload.date ? moment(payload.date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    let validSuspensionOutcome = false;
    payload.outcomes = [];
    Object.keys(this.formOutcomes).forEach(key => {
      const processedOutcome = Object.assign({}, this.formOutcomes[key]);
      // If this is a stage change, remove all outcomes' IDs
      processedOutcome.id = this.nextStage ? null : processedOutcome.id;
      // Validate Dates for 'suspension' outcome
      validSuspensionOutcome = this.suspensionDatesValidation(processedOutcome);
      // Special cases
      if (processedOutcome.type === 'inBreach') {
        processedOutcome.isApplied = processedOutcome.value === 'in_breach' ? true : false;
      }
      if (processedOutcome.type === 'advisory') {
        processedOutcome.value = processedOutcome.attributes.type;
      }
      if (processedOutcome.type === 'suspension') {
        if (validSuspensionOutcome) {
          if (processedOutcome.suspensionDates && processedOutcome.suspensionDates.length) {
            if (processedOutcome.suspensionDates[0].date === '') {
              processedOutcome.suspensionDates = [];
            } else {
              processedOutcome.suspensionDates = processedOutcome.suspensionDates
              .map(item => ({date: moment(item.date).format('YYYY-MM-DD')}));
            }
          }
          if (processedOutcome.datesPending === true) {
            processedOutcome.suspensionDates = [];
            processedOutcome.value = null;
          }
        } else {
          return;
        }
      }
      // If refer is true, every other outcome is null
      if (this.formOutcomes['refer'].isApplied === true) {
        if (processedOutcome.type !== 'inBreach' && processedOutcome.type !== 'refer') {
          processedOutcome.value = null;
          processedOutcome.isApplied = null;
          if (processedOutcome.suspensionDates) {
            processedOutcome.suspensionDates = [];
          }
        }
      }
      // Fallback check to avoid sending non-boolean isApplied
      if (processedOutcome.value !== undefined) {
        if (processedOutcome.isApplied === undefined) {
          processedOutcome.isApplied = !!processedOutcome.value;
        }
      }
      payload.outcomes.push(processedOutcome);
      // console.log('[ DEBUG ] Preparing Outcome before Save',
      //   processedOutcome.id,
      //   processedOutcome.type,
      //   'applied: ' + processedOutcome.isApplied,
      //   processedOutcome.value
      // );
    });

    // Clean up outcomes isApplied value if BreachStatus is false
    const breachStatus = payload.outcomes.find(eo => eo.type === 'inBreach');
    if (breachStatus !== undefined && breachStatus.isApplied === false) {
      payload.outcomes = payload.outcomes
        .map(outcome => outcome.type === 'inBreach' ? outcome : Object.assign({ }, outcome, { isApplied: false }));
    }

    if (validSuspensionOutcome) {
      console.log('[ DEBUG ] Saving enquiry, payload to be sent:', payload);

      let request = this.modalActionsService.createEnquiry(payload);
      if (payload.enquiryId) {
        request = this.modalActionsService.updateEnquiry(payload);
      }
      this.meta.saving = true;
      request.subscribe(
        res => {
          console.log('[ DEBUG ] Enquiry saved, response returned:', res);
          this.eventsService.changeEnquiriesData({section: 'enquiries', result: res});
          this.notification.success(`Enquiry saved.`, 'Success!');
          this.activeModal.close(res);
          this.meta.saving = false;
        },
        err => {
          this.notification.error(`Could't save this enquiry: ` +
          `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
          this.meta.saving = false;
        }
      );
    } else {
      console.log(validSuspensionOutcome);
      this.meta.saving = false;
    }
  }

  cancel = (action) => {
    this.activeModal.dismiss(action);
  }

}
