<app-navbar></app-navbar>

<div class="print-hide">
  <div class="row my-1">
    <div class="col-2 offset-4 btn-block text-center">
      <button type="button" class="btn btn-primary" [disabled]="meta.started || meta.loading" (click)="start()">
          <span>Prepare Racecards</span>
      </button>
    </div>
    <div class="col-2 text-center">
      <div [(ngModel)]="withRPC" class="btn-group btn-group-toggle" [disabled]="meta.started || meta.loading" ngbRadioGroup name="radioCommentsVisible">
        <label ngbButtonLabel class="btn-primary">
          <input ngbButton type="radio" [value]="true"> with RPC
        </label>
        <label ngbButtonLabel class="btn-primary">
          <input ngbButton type="radio" [value]="false"> without RPC
        </label>
      </div>
    </div>
  </div>
  <div class="row my-1">
    <div class="col-4 offset-4">
      <button type="button" class="btn btn-primary btn-block" [disabled]="meta.loading || !races.length" (click)="doPrint()">
        <span *ngIf="!meta.loading">Print Racecards</span>
        <span *ngIf="meta.loading">
          <fa name="circle-o-notch" animation="spin"></fa>
          <small> {{meta.status}}</small>
        </span>
      </button>
    </div>
  </div>
  <div class="row my-1">
    <div class="col-4 offset-4">
      <button type="button" class="btn btn-secondary btn-block" [routerLink]="['/races', fixture.fixtureYear, fixture.fixtureId]">
        <span>Back to the Fixture</span>
      </button>
    </div>
  </div>
</div>
<div *ngIf="!meta.loading" class="print-visible">
  <div class="container-fluid page-break-after" *ngFor="let race of races; index as racei">
    <!-- RACE BASIC -->
    <div class="row px-0 mx-0">
      <div class="col-10 px-0 mx-0">
        <h5>{{races[racei].raceDateTime | date:'h:mm'}} {{races[racei].name}}</h5>
        <strong>Going: </strong>
        <span>{{fixture.goingText || 'Unknown'}}</span>
      </div>
      <div class="col text-right">
        {{race.info.tvCoverage}}<br>{{race.info.distance}}
      </div>
    </div>
    <!-- RACE INFO -->
    <div class="row">
      <div class="col-2">
        <strong>For: </strong>
        <span> {{race.info.ageLimit}}</span>
        <span *ngIf="race.info.ratingBandLowest !== -1"> {{race.info.ratingBandLowest}} - {{race.info.ratingBandHighest}}</span>
      </div>
      <div class="col-2">
        <strong>Value: </strong> {{race.info.totalPrize | currency:'GBP'}}
      </div>
      <div class="col-8">
        <strong>Prizes: </strong>
        <span *ngFor="let prize of race.info.prizes; index as index; first as first">
          <span *ngIf="prize !== '0.00'" class="no-wrap">
            <span *ngIf="!first"> | </span>
            <span>{{index+1}}. {{prize | currency:'GBP'}}</span>
          </span>
        </span>
      </div>
    </div>
    <!-- ENTRIES -->
    <hr>
    <div
      class=" row my-1 no-page-break border-b"
      *ngFor="let entry of race.entries"
      [ngClass]="{'non-runner': entry.nonRunnerDeclaredDate}"
      [style.min-height.px]="entry.minHeightPx"
      [style.height.px]="entry.customHeightPx"
      [style.max-height.px]="entry.customHeightPx"
      [style.font-size.em]="entry.customFontSize"
    >
      <div class="col-1 d-flex flex-column justify-content-center align-items-center px-0 mx-0">
        <span class="d-flex align-self-center lead">{{entry.saddleClothNumber}}</span>
        <span class="d-flex align-self-center" *ngIf="entry.stall">[{{entry.stall}}]</span>
      </div>
      <div class="col-1 d-flex align-items-center px-0 mx-0 mr-1">
        <img [style.height.px]="entry.imageSize" [style.width.px]="entry.imageSize" class="img-silk" src="https://draper.horseracing.software/silks?id={{entry.colourCode}}&desc={{entry.colourDescription}}{{entry.nonRunnerDeclaredDate ? '&nonrunner' : ''}}">
      </div>
      <div class="col-1 d-flex flex-column flex-grow justify-content-center pr-0 mx-0">
        <div>{{entry.formFigures.trim()}}</div>
        <div *ngIf="entry.previousForm.data.length">
          <span>{{entry.previousForm.data[0].performanceFigure}}</span>
          <!-- <span *ngIf="entry.previousForm.data[1]"> ({{entry.previousForm.data[1].performanceFigure}})</span> -->
          <span *ngIf="entry.rating"> ({{entry.rating}})</span>
        </div>
        <div *ngIf="entry.headGears" class="pt-2">
          <span *ngIf="entry.headGears">
            <span *ngFor="let item of entry.headGears">
              <span>{{item.code}}<sup *ngIf="item.firstTimeIndicator">1</sup></span>
            </span>
          </span>
        </div>
      </div>
      <div class="col-4 d-flex flex-column flex-grow justify-content-center px-0 mx-0">
        <span class="d-flex align-items-center justify-content-start text-nowrap pb-2">
          <strong>{{entry.horseName | uppercase}}</strong>
          <span>
            <small *ngIf="entry.nonRunnerDeclaredDate" class="px-1">
              <span class="badge badge-pill badge-primary">
                  <span *ngIf="!entry.nonRunnerDeclaredReason">Non Runner</span>
                  <span *ngIf="entry.nonRunnerDeclaredReason">NR - {{entry.nonRunnerDeclaredReason}}</span>
              </span>
            </small>
            <small *ngIf="entry.indicators.isWithdrawn" class="px-1">
              <span class="badge badge-pill badge-primary">Withdrawn</span>
            </small>
            <small *ngIf="entry.indicators.hasBeenSelectedForSampling" class="px-1">
              <span class="badge badge-pill badge-primary">Selected for Sampling</span>
            </small>
            <small *ngIf="entry.indicators.hasJockeyChange" class="px-1">
              <span class="badge badge-pill badge-primary">
                <span>Jockey Change</span>
                <span *ngIf="entry.jockeyChangeHistory.length > 1">({{entry.jockeyChangeHistory.length}})</span>
              </span>
            </small>
            <small *ngIf="entry.indicators.isAPeprHorse" class="px-1">
              <span class="badge badge-pill badge-primary">PEPR</span>
            </small>
            <small *ngIf="entry.indicators.hadWindSurgerySinceLastRun" class="px-1">
              <span class="badge badge-pill badge-primary">Wind Surgery</span>
            </small>
            <small *ngIf="entry.indicators.hasNewTrainer" class="pr-1">
              <span class="badge badge-pill badge-info">New Trainer</span>
            </small>
          </span>
        </span>
        <div class="text-nowrap pb-1" *ngIf="entry.lastSampledDate"><span>Sampled {{entry.lastSampledDate | date:'dd/MM/yy'}}</span></div>
        <div class="text-nowrap pb-1" *ngIf="!entry.lastSampledDate">&nbsp;</div>
        <small *ngIf="entry.shortDispensation" class="text-nowrap d-inline-block">{{entry.shortDispensation}}</small>
        <span class="text-nowrap d-inline-block">{{entry.sire}}</span>
        <span class="text-nowrap d-inline-block">{{entry.dam}}</span>
      </div>
      <div class="col-1 d-flex flex-column flex-grow justify-content-center px-0 mx-0">
        <div>&nbsp;</div>
        <div class="d-flex">{{entry.horseAge}}yo/{{entry.horseColour ? entry.horseColour.substring(0, 1) : ''}}/{{entry.horseSex ? entry.horseSex.substring(0, 1) : ''}}</div>
        <div *ngIf="entry.daysSinceLastRan.length" class="pt-2">
          <small class="pr-1">{{entry.daysSinceLastRan[0].days}} days</small>
          <small>({{entry.daysSinceLastRan[0].raceType}})</small>
        </div>
        <div *ngIf="entry.weightCarriedValue" class="d-flex pt-2">
            <small>{{entry.weightCarriedValue | toStones}}</small>
          </div>
      </div>
      <div class="col-2 d-flex flex-column flex-grow justify-content-center px-0 mx-0">
        <span>&nbsp;</span>
        <span class="d-inline-block text-truncate">{{entry.jockeyKnownAs}}<span *ngIf="entry.jockeyClaim">({{entry.jockeyClaim}})</span></span>
        <span class="d-inline-block text-truncate">{{entry.trainerKnownAs}}</span>
        <span class="d-inline-block text-truncate">{{entry.ownerKnownAs}}</span>
      </div>
      <div *ngIf="entry.latest" class="col d-flex flex-column justify-content-center">
        <div *ngIf="entry.latest.enquiry.date">
          <strong>{{entry.latest.enquiry.date | date:'dd/MM/yy h:mm'}}</strong>
          <span>{{entry.latest.enquiry.offence.offence}}</span>
          <span *ngIf="entry.latest.enquiry.offence.subOffence"> {{entry.latest.enquiry.offence.subOffence}}</span>
        </div>
        <div *ngIf="entry.latest.comment.date">
          <strong>{{entry.latest.comment.date | date:'dd/MM/yy h:mm'}} {{entry.latest.comment.courseShortName}}</strong>
          <span *ngFor="let rpc of entry.latest.comment.rpc; last as last">
            <span>{{rpc.racePerformanceComment}}</span>
            <span *ngIf="!last">, </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
