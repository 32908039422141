import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';

export interface EnquiryOffence {
  offenceId: number;
  offenceDescription: string;
}

@Injectable()
export class ModalActionsService {

  defaultHeaders: HttpHeaders;
  constructor(
    private http: HttpClient,
    private auth: AuthenticationService
  ) { }

  updateRace(race, payload, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.patch(`${environment.apiBase}/v2/races/${race.raceYear}/${race.raceId}/${race.divisionSequence}`, payload, {headers});
  }

  updateFixture(fixture, payload, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.patch(`${environment.apiBase}/v2/fixtures/${fixture.fixtureYear}/${fixture.fixtureId}`, payload, {headers});
  }

  createComment(payload, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.post(`${environment.apiBase}/v2/comments`, payload, {headers});
  }

  updateComment(payload, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.put(`${environment.apiBase}/v2/comments/${payload.id}`, payload, {headers});
  }

  createEnquiry(payload, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.post(`${environment.apiBase}/v2/enquiries`, payload, {headers});
  }

  updateEnquiry(payload, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.put(`${environment.apiBase}/v2/enquiries/${payload.enquiryId}`, payload, {headers});
  }

  getSamplingRequest(samplingId, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.get(`${environment.apiBase}/v2/sampling/${samplingId}`, {headers});
  }

  createSamplingRequest(payload, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.post(`${environment.apiBase}/v2/sampling`, payload, {headers});
  }

  updateSamplingRequest(payload, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.put(`${environment.apiBase}/v2/sampling/${payload.samplingId}`, payload, {headers});
  }

  removeSamplingRequest(payload, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.delete(`${environment.apiBase}/v2/sampling/${payload.samplingId}/?userId=${payload.userId}`, {headers});
  }

  getNonRunnerInfo(entry, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.get(`${environment.apiBase}/v2/non-runners/${entry.year}/` +
    `${entry.raceId}/${entry.divisionSequence}/${entry.horseId}`, {headers});
  }

  updateNonRunnerInfo(payload, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.put(`${environment.apiBase}/v2/non-runners/${payload.year}/` +
    `${payload.raceId}/${payload.divisionSequence}/${payload.horseId}`, payload, {headers});
  }

  getWithdrawnInfo(entry, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.get(`${environment.apiBase}/v2/withdrawal/${entry.year}/` +
    `${entry.raceId}/${entry.divisionSequence}/${entry.horseId}`, {headers});
  }

  updateWithdrawnInfo(payload, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.put(`${environment.apiBase}/v2/withdrawal/${payload.year}/` +
    `${payload.raceId}/${payload.divisionSequence}/${payload.horseId}`, payload, {headers});
  }

  updateJockeyChangeInfo(payload, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.post(`${environment.apiBase}/v2/jockey-change/${payload.year}/` +
    `${payload.raceId}/${payload.divisionSequence}/${payload.horseId}`, payload, {headers});
  }

  updateHorseShoesDispensation(payload, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.post(`${environment.apiBase}/v2/horses/${payload.horseId}/set-dispensation`, payload, {headers});
  }
  updateLateRace(race, payload, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.post(`${environment.apiBase}/v2/races/${race.raceYear}/${race.raceId}/${race.divisionSequence}/late-race`, payload, {headers});
  }
  
  updateLeftParadeRingRace(race, payload, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.post(`${environment.apiBase}/v2/races/${race.raceYear}/${race.raceId}/${race.divisionSequence}/parade-ring`, payload, {headers});
  }
  
  updateRequestedDelayRace(race, payload, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.post(`${environment.apiBase}/v2/races/${race.raceYear}/${race.raceId}/${race.divisionSequence}/requested-delay`, payload, {headers});
  }

  removeLateRace(race, payload, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.delete(`${environment.apiBase}/v2/races/${race.raceYear}/${race.raceId}/${race.divisionSequence}/late-race?userId=${payload.userId}`, {headers});
  }  

  removeParadeRingRace(race, payload, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.delete(`${environment.apiBase}/v2/races/${race.raceYear}/${race.raceId}/${race.divisionSequence}/parade-ring?userId=${payload.userId}`, {headers});
  } 

  removeRequestedDelayRace(race, payload, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.delete(`${environment.apiBase}/v2/races/${race.raceYear}/${race.raceId}/${race.divisionSequence}/requested-delay?userId=${payload.userId}`, {headers});
  }

  getRpcComments(entityType, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.get(`${environment.apiBase}/v2/lists/rpc/${entityType}`, {headers});
  }

  getEnquiryOffences(query, entityType, category = 2, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.get(`${environment.apiBase}/v2/lists/enquiry-offences?` +
    `entityType=${entityType}&categoryId=${category}&offence=${query}`, {headers});
  }

  getEnquiryOutcomes(offence, offender, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.get(`${environment.apiBase}/v2/enquiries/outcomes/${offender.type}/` +
    `${offender.id}/${offence.offenceId}`, {headers});
  }

  getEnquirySuspensionDates(enquiry, amount, period, headers = this.auth.getHeaders()): Observable<any> {
    let suspensionDatesUrl = `${environment.apiBase}/v2/enquiries/suspension-dates/` +
    `${enquiry.meta.year}/${enquiry.meta.raceId}/${enquiry.meta.divisionSequence}/` +
    `${enquiry.offender.type}/${enquiry.offender.id}?length=${amount}&unit=${period}`;
    if (enquiry.enquiryId) {
      suspensionDatesUrl += `&enquiryId=${enquiry.enquiryId}`;
    }
    return this.http.get(suspensionDatesUrl, {headers});
  }

  getJockeys(text, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.get(`${environment.apiBase}/v2/lists/jockeys?name=${text}`, {headers});
  }

  getCameraAngles(headers = this.auth.getHeaders()): Observable<any> {
    return this.http.get(`${environment.apiBase}/v2/lists/camera-angles`, {headers});
  }

  getSampleRequestReasons(headers = this.auth.getHeaders()): Observable<any> {
    return this.http.get(`${environment.apiBase}/v2/lists/sampling-reasons`, {headers});
  }

  getNonRunnerReasons(headers = this.auth.getHeaders()): Observable<any> {
    return this.http.get(`${environment.apiBase}/v2/lists/non-runner-reasons`, {headers});
  }

  getWithdrawnReasons(headers = this.auth.getHeaders()): Observable<any> {
    return this.http.get(`${environment.apiBase}/v2/lists/withdrawal-reasons`, {headers});
  }

  getLateRaces(race, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.get(`${environment.apiBase}/v2/races/${race.raceYear}/${race.raceId}/${race.divisionSequence}/late-race`, {headers});
  }

  getLeftParadeRingRaces(race, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.get(`${environment.apiBase}/v2/races/${race.raceYear}/${race.raceId}/${race.divisionSequence}/parade-ring`, {headers});
  }

  getRequestedDelayRaces(race, headers = this.auth.getHeaders()): Observable<any> {
    return this.http.get(`${environment.apiBase}/v2/races/${race.raceYear}/${race.raceId}/${race.divisionSequence}/requested-delay`, {headers});
  }
}
