import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as moment from 'moment';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../../services/events.service';
import { ModalsService } from '../../../services/modals.service';

import { AddEditCommentComponent } from '../actions/add-edit-comment/add-edit-comment.component';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {

  @Input() comment;
  meta: {
    title: string,
    viewMode: string,
    loading: boolean,
    saving: boolean
  };
  userId: number;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private notification: ToastrService,
    private eventsService: EventsService,
    private modalsService: ModalsService,
  ) { }

  ngOnInit() {
    this.meta = {
      title: '',
      viewMode: 'modal',
      loading: false,
      saving: false
    };

    this.eventsService.observableUser.subscribe(data => {
      this.userId = data.id;
    });

    if (!this.comment) {
      this.meta.viewMode = 'view';
      this.route.params.subscribe(params => {
        if (Object.keys(params).length) {
          this.comment = { id: this.route.snapshot.paramMap.get('commentId') };
        }
      });
    }

    this.loadData();
  }

  loadData = () => {
    this.meta.loading = true;
    // console.log('Loading', this.comment.commentId);
    this.modalsService.getComment(this.comment).subscribe(
      res => {
        this.comment = res;
        this.prepareModalTitle();
        this.comment.isUpdated = moment(this.comment.updatedAt).isSame(moment(this.comment.createdAt)) ? false : true;
        this.meta.loading = false;
      },
      err => {
        this.notification.error(`Could't get the Comment Details: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.loading = false;
      }
    );
  }

  prepareModalTitle = () => {
    switch (this.comment.subject.type) {
      case 'fixture': this.meta.title = `for ${this.comment.subject.courseName}`; break;
      case 'race': this.meta.title = `for the ${moment(this.comment.subject.dateTime).format('HH:mm')} race`; break;
      default: this.meta.title = `for '${this.comment.subject.knownAs}'`;
    }
  }

  openEdit = (commentId) => {
    if (this.meta.viewMode === 'modal') {
      this.activeModal.dismiss('edit mode');
    }
    const modalWindow = this.modalService.open(AddEditCommentComponent, { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.comment = this.comment;
    modalWindow.result.then((result) => {
      // console.log(`[ DEBUG ] Modal Closed with payload`, result);
      if (this.meta.viewMode === 'view') {
        this.loadData();
      }
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  delete = () => {
    this.meta.saving = true;
    this.modalsService.deleteComment(this.comment.id, this.userId).subscribe(
      res => {
        console.log('Comment deleted', this.comment);
        // Find a way of telling the sections to remove a comment
        this.eventsService.changeCommentsData({
          section: this.comment.subject.type + 'Comments',
          result: Object.assign({ deleted: true }, this.comment)
        });
        this.notification.success(`Comment deleted.`, 'Success!');
        this.activeModal.dismiss(`comment deleted`);
        this.meta.saving = false;
      },
      err => {
        this.notification.error(`Could't delete this comment: ` +
        `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
        this.meta.saving = false;
      }
    );
  }

  cancel = (action) => {
    this.activeModal.dismiss(action);
  }

}
