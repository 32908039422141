import {Component, OnInit, Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../../../services/events.service';
import { ModalActionsService } from '../../../../services/modalActions.service';
import * as moment from 'moment';

@Component({
  selector: 'app-race-requested-delay',
  templateUrl: './race-requested-delay.component.html',
  styleUrls: ['./race-requested-delay.component.scss'],
})
export class RaceRequestedDelayComponent implements OnInit {
  @Input() race;
  @Input() requestedDelayRaces;
  meta: {
    loading: boolean,
    saving: boolean
  };
  
  requestedDelayTime: string;
  inMinutes: number;
  raceTime: string;
  userId: number;
  action: string;
  seconds: true;
  time = {hour: null, minute: null, second: null};
  onTimeChange() {
    this.requestedDelayRaces.raceTime = moment(this.requestedDelayRaces.publishedRaceTime, 'HH:mm:ss').add(this.inMinutes, 'minutes').format('h:mm a');
  }
  
  constructor(
    private activeModal: NgbActiveModal,
    private notification: ToastrService,
    private eventsService: EventsService,
    private modalActionsService: ModalActionsService
  ) { }

  ngOnInit() {
    this.meta = {
      loading: false,
      saving: false
    };
    this.eventsService.observableUser.subscribe(data => {
      this.userId = data.id;
    });
    this.getRequestedDelayRaces();
  }
  getRequestedDelayRaces = () => {
    this.meta.loading = true;
    this.modalActionsService.getRequestedDelayRaces(this.race).subscribe(
      res => {
        this.requestedDelayRaces = res;
        console.log(this.requestedDelayRaces);
        this.meta.loading = false;
      },
      err => {
        this.notification.error(`Couldn't  ` +
          `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
      }
    );
  }
  confirm = () => {
    this.meta.saving = true;
    const payload: any = {
      //requestedDelayTime: this.requestedDelayRaces.raceTime,
      requestedDelayTime: moment(this.requestedDelayRaces.raceTime, 'h:mm a').format('HH:mm:ss'),
      inMinutes: this.inMinutes,
      action: this.action,
      userId: this.userId,
    };
    this.modalActionsService.updateRequestedDelayRace(this.race, payload).subscribe(
      res => {
        this.activeModal.close(res);
        this.notification.success(`${this.race.raceName} added as requested delay race`, 'Success!');
        this.meta.saving = true;
      },
      err => {
        this.notification.error(`Something went wrong ` +
          `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
        this.meta.saving = false;
      }
    );
  }
  deleteRace = () => {
    this.meta.loading = true;
    const payload: any = {
      requestedDelayTime: moment(this.requestedDelayTime, 'h:mm a').format('HH:mm:ss'),
      inMinutes: this.inMinutes,
      action: this.action,
      userId: this.userId,
    };
    this.modalActionsService.removeRequestedDelayRace(this.race, payload).subscribe(
      res => {
        this.activeModal.close(res);
        this.notification.success(`${this.race.raceName} removed as a requested delay`, 'Success!');
        this.meta.loading = false;
      },
      err => {
        this.notification.error(`Something went wrong ` +
          `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
        this.meta.loading = false;
      }
    );
  }
  cancel = (action) => {
    this.activeModal.dismiss(action);
  }
}
