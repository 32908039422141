import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {CommonModule, DatePipe} from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppRouterComponent } from './app.router.component';
import { AppRoutingModule } from './app-routing.module';

import { AuthenticationService } from './services/authentication.service';
import { HermesService } from './services/hermes.service';
import { EntriesService } from './services/entries.service';
import { EventsService } from './services/events.service';
import { FixturesService } from './services/fixtures.service';
import { HorsesService } from './services/horses.service';
import { JockeysService } from './services/jockeys.service';
import { OwnersService } from './services/owners.service';
import { RacesService } from './services/races.service';
import { SearchService } from './services/search.service';
import { TrainersService } from './services/trainers.service';
import { ModalsService } from './services/modals.service';
import { ModalActionsService } from './services/modalActions.service';
import { NgbDateUKParserFormatter } from './services/ngb-date-uk-parser-formatter';

import { ToStonesPipe } from './pipes/to-stones.pipe';
import { ObjPropertiesPipe } from './pipes/obj-properties.pipe';

import { NavbarComponent } from './components/navbar/navbar.component';
import { FixturesComponent } from './components/fixtures/fixtures.component';
import { RacesComponent } from './components/races/races.component';
import { RaceEntryComponent } from './components/commons/race-entry/race-entry.component';
import { RaceInfoComponent } from './components/races/race-info/race-info.component';
import { JockeyComponent } from './components/jockey/jockey.component';
import { HorseComponent } from './components/horse/horse.component';
import { HorseCommentsComponent } from './components/horse/horse-comments/horse-comments.component';
import { HorsePreviousFormsComponent } from './components/horse/horse-previous-forms/horse-previous-forms.component';
import { TrainerComponent } from './components/trainer/trainer.component';
import { OwnerComponent } from './components/owner/owner.component';
import { EnquiryOutcomesComponent } from './components/commons/enquiry-outcomes/enquiry-outcomes.component';
import { JockeyCommentsComponent } from './components/jockey/jockey-comments/jockey-comments.component';
import { OwnerCommentsComponent } from './components/owner/owner-comments/owner-comments.component';
import { TrainerCommentsComponent } from './components/trainer/trainer-comments/trainer-comments.component';
import { CommentComponent } from './components/modals/comment/comment.component';
import { EnquiryComponent } from './components/modals/enquiry/enquiry.component';
import { RaceFalseStartComponent } from './components/modals/actions/race-false-start/race-false-start.component';
import { RaceVoidRaceComponent } from './components/modals/actions/race-void-race/race-void-race.component';
import { RaceUpdateStatusComponent } from './components/modals/actions/race-update-status/race-update-status.component';
import { FixtureUpdateOfficialsComponent } from './components/modals/actions/fixture-update-officials/fixture-update-officials.component';
import { AddEditCommentComponent } from './components/modals/actions/add-edit-comment/add-edit-comment.component';
import { AddEditEnquiryComponent } from './components/modals/actions/add-edit-enquiry/add-edit-enquiry.component';
import { EntrySamplingRequestComponent } from './components/modals/actions/entry-sampling-request/entry-sampling-request.component';
import { FixtureSamplingComponent } from './components/fixtures/fixture-sampling/fixture-sampling.component';
import { EntryNonRunnerComponent } from './components/modals/actions/entry-non-runner/entry-non-runner.component';
import { EntryWithdrawnComponent } from './components/modals/actions/entry-withdrawn/entry-withdrawn.component';
import { EntryJockeyChangeComponent } from './components/modals/actions/entry-jockey-change/entry-jockey-change.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { FixtureRacecardsComponent } from './components/fixtures/fixture-racecards/fixture-racecards.component';
import { FixtureReportComponent } from './components/fixtures/fixture-report/fixture-report.component';
import { JockeyChangeHistoryComponent } from './components/modals/jockey-change-history/jockey-change-history.component';
import { InteractiveEventsComponent } from './components/races/interactive-events/interactive-events.component';
import { HorseDispensationComponent } from './components/horse/horse-dispensation/horse-dispensation.component';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { TimePipe } from './pipes/time.pipe';
import { RaceTimePipe } from './pipes/racetime.pipe';
import { GoingHistoryComponent } from './components/modals/going-history/going-history.component';
import { SearchComponent } from './components/search/search.component';
import { ErrorHandlerService } from './services/error-handler.service';
import { ErrorComponent } from './components/commons/error/error.component';
import { EnquirySearchComponent } from './components/commons/enquiry-search/enquiry-search.component';
import {RaceLateRaceComponent} from './components/modals/actions/race-late-race/race-late-race.component';
import {RaceLeftParadeRingComponent} from './components/modals/actions/race-left-parade-ring/race-left-parade-ring.component';
import {RaceRequestedDelayComponent} from './components/modals/actions/race-requested-delay/race-requested-delay.component';
import {FixtureSuspensionDatesComponent} from './components/modals/actions/fixture-suspension-dates/fixture-suspension-dates.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    NgSelectModule,
    NgbModule,
    ToastrModule.forRoot({
      progressBar: true,
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      tapToDismiss: false,
      closeButton: true
    }),
    AngularFontAwesomeModule
  ],
  declarations: [
    AppRouterComponent,
    NavbarComponent,
    FixturesComponent,
    RacesComponent,
    RaceEntryComponent,
    ToStonesPipe,
    ObjPropertiesPipe,
    RaceInfoComponent,
    JockeyComponent,
    HorseComponent,
    HorseCommentsComponent,
    HorsePreviousFormsComponent,
    TrainerComponent,
    OwnerComponent,
    EnquiryOutcomesComponent,
    JockeyCommentsComponent,
    OwnerCommentsComponent,
    TrainerCommentsComponent,
    CommentComponent,
    EnquiryComponent,
    RaceFalseStartComponent,
    RaceVoidRaceComponent,
    RaceLateRaceComponent,
    RaceLeftParadeRingComponent,
    RaceRequestedDelayComponent,
    RaceUpdateStatusComponent,
    FixtureUpdateOfficialsComponent,
    AddEditCommentComponent,
    AddEditEnquiryComponent,
    EntrySamplingRequestComponent,
    FixtureSamplingComponent,
    EntryNonRunnerComponent,
    EntryWithdrawnComponent,
    EntryJockeyChangeComponent,
    LoginComponent,
    LogoutComponent,
    FixtureRacecardsComponent,
    FixtureReportComponent,
    JockeyChangeHistoryComponent,
    InteractiveEventsComponent,
    HorseDispensationComponent,
    OrdinalPipe,
    TimePipe,
    RaceTimePipe,
    GoingHistoryComponent,
    SearchComponent,
    ErrorComponent,
    EnquirySearchComponent,
    FixtureSuspensionDatesComponent,
  ],
  entryComponents: [
    CommentComponent,
    EnquiryComponent,
    RaceFalseStartComponent,
    RaceVoidRaceComponent,
    RaceLateRaceComponent,
    RaceLeftParadeRingComponent,
    RaceRequestedDelayComponent,
    RaceUpdateStatusComponent,
    FixtureUpdateOfficialsComponent,
    AddEditCommentComponent,
    AddEditEnquiryComponent,
    EntrySamplingRequestComponent,
    EntryNonRunnerComponent,
    EntryWithdrawnComponent,
    EntryJockeyChangeComponent,
    JockeyChangeHistoryComponent,
    HorseDispensationComponent,
    GoingHistoryComponent,
    ErrorComponent,
    FixtureSuspensionDatesComponent,
  ],
  providers: [
    AuthenticationService,
    HermesService,
    EntriesService,
    EventsService,
    FixturesService,
    HorsesService,
    JockeysService,
    OwnersService,
    RacesService,
    SearchService,
    TrainersService,
    ModalsService,
    ModalActionsService,
    NgbActiveModal,
    { provide: NgbDateParserFormatter, useClass: NgbDateUKParserFormatter },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    DatePipe
  ],
  bootstrap: [AppRouterComponent]
})
export class AppModule { }
