import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordinal'
})
export class OrdinalPipe implements PipeTransform {

  transform(value: number, keepNumber: boolean = true): string {
    const ordinals: string[] = ['th', 'st', 'nd', 'rd'];
    const v = value % 100;
    return (keepNumber ? value : '') + (ordinals[ (v - 20) % 10] || ordinals[v] || ordinals[0]);
  }

}
