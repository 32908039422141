import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import * as moment from 'moment';

import { ToastrService } from 'ngx-toastr';
import { OwnersService } from '../../services/owners.service';
import { EventsService } from '../../services/events.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AddEditEnquiryComponent } from '../modals/actions/add-edit-enquiry/add-edit-enquiry.component';

@Component({
  selector: 'app-owner',
  templateUrl: './owner.component.html',
  styleUrls: ['./owner.component.scss']
})
export class OwnerComponent implements OnInit {

  meta: {
    loading: boolean,
    saving: boolean,
    selectedTab: string
  };
  owner: any;

  constructor(
    private notification: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private ownersService: OwnersService,
    private eventsService: EventsService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.meta = {
      loading: false,
      saving: false,
      selectedTab: 'enquiries'
    };

    this.eventsService.changeFixtureData({courseName: null});

    this.route.params.subscribe(params => {
      if (Object.keys(params).length) {
        this.owner = {
          ownerId: this.route.snapshot.paramMap.get('ownerId')
        };
        this.getOwnerInfo(this.owner.ownerId);
        if (params.tab) {
          this.selectTab(params.tab);
        }
      }
    });
  }

  getOwnerInfo = (ownerId) => {
    this.meta.loading = true;
    this.ownersService.getOwnerInfo(this.owner).subscribe(
      res => {
        this.owner = res;
        this.owner.address = this.owner.address ? this.owner.address.replace(/\|/g, ', ') : this.owner.address;
        this.meta.loading = false;
      },
      err => {

        this.notification.error(`Could't get the Owner Details: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.loading = false;
        if (err.status === 401) {
          this.router.navigate(['/logout']);
        }
      }
    );
  }

  openAddEnquiry = (entry) => {
    const possibleOffenders = [
      {
        id: entry.ownerId,
        knownAs: entry.ownerKnownAs || entry.knownAs,
        type: 'owner'
      }
    ];
    const enquiry = {
      // meta: { year: moment().year(), raceId: null, divisionSequence: null, horseId: null },
      offender: possibleOffenders[0]
    };
    const modalWindow = this.modalService
    .open(AddEditEnquiryComponent, { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.enquiry = enquiry;
    modalWindow.componentInstance.possibleOffenders = possibleOffenders;
    modalWindow.result.then((result) => {
      this.selectTab('enquiries', true);
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  selectTab = (tabName, force = false) => {
    this.location.replaceState(`/owners/${this.owner.ownerId}/${tabName}`);
    this.meta.selectedTab = tabName;
    if (this.meta.selectedTab === 'enquiries' && tabName === 'enquiries' && force === true) {
      this.eventsService.changeGenericEnquiry(true);
    }
  }

  enquiriesReloaded(value) {
    this.eventsService.changeGenericEnquiry(!value);
  }

}
