import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Observable ,  of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError} from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../../../services/events.service';
import { ModalActionsService } from '../../../../services/modalActions.service';

@Component({
  selector: 'app-entry-jockey-change',
  templateUrl: './entry-jockey-change.component.html',
  styleUrls: ['./entry-jockey-change.component.scss']
})
export class EntryJockeyChangeComponent implements OnInit {

  @Input() entry;
  jockeyChangeInfo: any;
  jockeyStashedInfo: any;
  meta: {
    title: string,
    loading: boolean,
    searching: boolean,
    saving: boolean
  };
  userId: number;

  constructor(
    private activeModal: NgbActiveModal,
    private notification: ToastrService,
    private eventsService: EventsService,
    private modalActionsService: ModalActionsService,
  ) { }

  ngOnInit() {
    console.log('Opening non runner modal', this.entry);
    this.meta = {
      title: this.entry.horseName,
      loading: false,
      searching: false,
      saving: false
    };

    this.eventsService.observableUser.subscribe(data => {
      this.userId = data.id;
    });

    this.prepareJockeyChangeInfo(this.entry);
  }

  changedMode(wantToChangeJockey) {
    if (wantToChangeJockey === true) {
      this.jockeyStashedInfo = {
        comment: this.jockeyChangeInfo.comment,
        currentJockey: Object.assign({}, this.jockeyChangeInfo.currentJockey)
      };
      this.jockeyChangeInfo.comment = null;
      this.jockeyChangeInfo.currentJockey = {
        jockeyId: null,
        jockeyKnownAs: null
      };
    } else {
      this.jockeyChangeInfo.comment = this.jockeyStashedInfo.comment;
      this.jockeyChangeInfo.currentJockey = this.jockeyStashedInfo.currentJockey;
    }
  }

  jockeyNameFormatter = (text: any) => text.jockeyKnownAs;
  searchJockey = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.meta.searching = true),
      switchMap(term => {
        if (term.length > 3) {
          return this.modalActionsService.getJockeys(term)
          .pipe(
            catchError(() => {
              return of([]);
            })
          );
        } else {
          return of([]);
        }
      }),
      tap(() => this.meta.searching = false)
    );
  }

  prepareJockeyChangeInfo = (entry) => {
    if (entry.jockeyChangeHistory.length) {
      const last = entry.jockeyChangeHistory.length - 1;
      this.jockeyChangeInfo = {
        id: entry.jockeyChangeHistory[last].id || entry.id,
        comment: entry.jockeyChangeHistory[last].comment || null,
        currentJockey: {
          jockeyId: entry.jockeyChangeHistory[last].newJockeyId,
          jockeyKnownAs: entry.jockeyChangeHistory[last].newJockeyKnownAs
        },
        newChange: false,
        allowUpdate: true
      };
    } else {
      this.jockeyChangeInfo = {
        id: null,
        comment: null,
        currentJockey: {
          jockeyId: null,
          jockeyKnownAs: null
        },
        newChange: true,
        allowUpdate: false
      };
    }
    console.log('Previous jockey change info:', this.jockeyChangeInfo);
  }

  save = () => {
    this.meta.saving = true;
    const last = this.entry.jockeyChangeHistory.length - 1;
    const payload: any = {
      userId: this.userId,
      horseId: this.entry.horseId,
      year: this.entry.year,
      raceId: this.entry.raceId,
      divisionSequence: this.entry.divisionSequence,
      oldJockeyId: this.entry.jockeyChangeHistory[last].oldJockeyId,
      newJockeyId: this.entry.jockeyChangeHistory[last].newJockeyId,
      comment: this.jockeyChangeInfo.comment
    };
    if (this.jockeyChangeInfo.id && !this.jockeyChangeInfo.newChange) {
      payload.id = this.jockeyChangeInfo.id;
    }
    console.log('sending jockey change request', payload);
    this.modalActionsService.updateJockeyChangeInfo(payload).subscribe(
      res => {
        // console.log('jockey change info saved', res);
        this.eventsService.changeRacecardData({section: 'todoList', context: 'jockeyChange',
          result: Object.assign({}, res, { horseName: this.entry.horseName})
        });
        this.notification.success(`Jockey Change information saved.`, 'Success!');
        this.activeModal.close(res);
        this.meta.saving = false;
      },
      err => {
        console.log('Failed to save data', err);
        this.notification.error(`Could't save Jockey Change information: ` +
        `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
        this.meta.saving = false;
      }
    );
  }

  cancel = (action) => {
    this.activeModal.dismiss(action);
  }

}
