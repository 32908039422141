<app-navbar></app-navbar>
<a class="back-link" [routerLink]="['/']">< Back to Fixtures</a>
<div class="container-fluid">
  <div class="row mb-5 mt-5">
    <div class="col">
      <h2 class="font-weight-bold">Search</h2>
    </div>
  </div>
  <form #searchForm="ngForm" (ngSubmit)="search()" novalidate>
    <!-- Type and Name -->

    <div class="row">
      <div class="col col-md-6">
        <label class="font-weight-bold">Select type</label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="SearchType">Type</label>
          </div>
          <select class="custom-select" (ngModelChange)="updateType($event)" name="SearchType" id="SearchType" [(ngModel)]="searchParams.type">
            <option value="" selected>Choose a Type</option>
            <option value="horses">Horse</option>
            <option value="jockeys">Jockey</option>
            <option value="trainers">Trainer</option>
            <option value="owners">Owner</option>
          </select>
        </div>
      </div>
      <div class="col col-md-6">
        <label class="font-weight-bold">Select type</label>
        <input class="form-control" autocomplete="off" name="SearchName" type="text" [disabled]="!searchParams.type" (ngModelChange)="updateValidation()" [(ngModel)]="searchParams.name" [placeholder]="'Name of the ' + meta.typeLabel">
      </div>
    </div>
    <!-- Options -->
    <div class="row pb-3">
      <div class="col text-right" *ngIf="searchParams.type === 'horses'">
        <small>Dispensation</small>
        <div class="btn-group btn-group-sm btn-group-toggle" (ngModelChange)="updateValidation()" ngbRadioGroup name="DispensationStatus" [(ngModel)]="searchParams.options[0].value">
          <label ngbButtonLabel class="btn-primary">
            <input ngbButton type="radio" [value]="1"> Yes
          </label>
          <label ngbButtonLabel class="btn-primary">
            <input ngbButton type="radio" [value]="0"> No
          </label>
        </div>
      </div>
    </div>
    <div class="row pt-3 border-">
      <div class="col text-right">
        <button type="button" class="btn btn-danger mr-2" (click)="reset()" [disabled]="meta.loading"><fa name="trash" class="mr-2"></fa> Reset</button>
        <button type="submit" class="btn btn-primary" [disabled]="!meta.isValid || meta.loading"><fa name="search" class="mr-2"></fa> Search</button>
      </div>
    </div>
  </form>
</div>
<div class="container-fluid pt-3">
  <div class="row" *ngIf="meta.loading">
    <div class="col text-center">
      <img src="assets/loader.svg" class="d-block ml-auto mr-auto" alt="spinner">
    </div>
  </div>
  <div class="row" *ngIf="!meta.loading && (results && results.length === 0)">
    <div class="col text-center">
      We couldn't find any result matching the selected criteria.
    </div>
  </div>
  <table class="table table-striped table-bordered" *ngIf="!meta.loading && (results && results.length > 0)">
    <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col" *ngIf="searchParams.type === 'horses'">Foal Date</th>
        <th scope="col" *ngIf="searchParams.type === 'horses'">Sex</th>
        <th scope="col" *ngIf="searchParams.type !== 'horses'">Birth Date</th>
        <th scope="col" *ngIf="searchParams.type !== 'horses'">Phone</th>
        <th scope="col" *ngIf="searchParams.type !== 'horses'">E-Mail</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of results; index as i">
        <td scope="row">
          <a [routerLink]="['/' + searchParams.type, item.id]" target="_blank">{{ item.knownAs || 'Unnamed' }}</a>
        </td>
        <td>{{ item.dateOfBirth | date:'dd/MM/yyyy' }}</td>
        <td *ngIf="searchParams.type === 'horses'">{{ item.sex }}</td>
        <td *ngIf="searchParams.type !== 'horses'">{{ item.phone }}</td>
        <td *ngIf="searchParams.type !== 'horses'">{{ item.email }}</td>
      </tr>
    </tbody>
  </table>

  <ngb-pagination *ngIf="!meta.loading && (results && results.length > 0)"
    class="d-flex justify-content-center"
    [boundaryLinks]="true"
    [ellipses]="true"
    [rotate]="true"
    [maxSize]="3"
    [size]="sm"
    [collectionSize]="meta.total"
    [(page)]="meta.page"
    [pageSize]="meta.perPage"
    (pageChange)="search($event)">
  </ngb-pagination>
</div>
