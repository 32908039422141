
  <div class="row" >
    <div class="col">
      <table class="table" [ngClass]="{
        'dark-background': odd,
        'non-runner': entry.nonRunnerDeclaredDate,
        'disqualification': entry.disqualifiedTimestamp,
        'glow': entry.glow}"
      >
        <tbody>
        <tr (click)="debugShowMe(entry)">
          <td class="saddleClothNumberColumn">
            <div class="lead font-weight-bold">{{entry.saddleClothNumber}}</div>
            <div *ngIf="entry.stall">[{{entry.stall}}]</div>
          </td>
          <td class="silkColumn">
            <img class="img-silk" src="https://draper.horseracing.software/silks?id={{entry.colourCode}}&desc={{entry.colourDescription}}{{entry.nonRunnerDeclaredDate ? '&nonrunner' : ''}}">
          </td>
          <td>

            <div class="d-flex align-items-center justify-content-start text-nowrap">
              <a [routerLink]="['/horses', entry.horseId]" target="_blank" class="text-underline"><strong>{{entry.horseName | uppercase}}</strong></a>
              <!-- Headgears, wind surgery -->
              <div class="pl-1" *ngIf="entry.headGears || entry.indicators.hadWindSurgerySinceLastRun">
                <small class="pr-1" *ngIf="entry.indicators.hadWindSurgerySinceLastRun">WS</small>
                <span class="d-inline" *ngIf="entry.headGears">
                  <div class="d-inline" *ngFor="let item of entry.headGears">
                    <span class="d-inline mr-1">{{item.code}}<sup *ngIf="item.firstTimeIndicator">1</sup></span>
                  </div>
                </span>
              </div>
            </div>
            <!-- Age/Sex/Colour, Icons for Comments and Enquiries,  -->
            <div class="d-flex align-items-center justify-content-start text-nowrap">
            <div>{{entry.horseAge}}yo/{{entry.horseColour ? entry.horseColour.substring(0, 1) : ''}}/{{entry.horseSex ? entry.horseSex.substring(0, 1) : ''}}</div>
            <div *ngIf="meta.previousRpcComment.loading || meta.previousEnquiry.loading || meta.todayComments.loading">
              <fa name="circle-o-notch" animation="spin"></fa>
            </div>

            <div *ngIf="!meta.previousRpcComment.loading && !meta.previousEnquiry.loading && !meta.todayComments.loading">
              <small *ngIf="entry.mostRecentRPCText && !entry.todayCommentText" placement="auto" container="body" triggers="hover" ngbTooltip="{{entry.mostRecentRPCDate | date:'dd/MM/yy'}} - {{entry.mostRecentRPCText}}">
                <a [routerLink]="['/horses', entry.horseId, 'comments']">
                  <fa size="lg" class="px-1" name="comment"></fa>
                </a>
              </small>
              <a *ngIf="entry.todayCommentText" [routerLink]="['/horses', entry.horseId, 'comments']" placement="auto" container="body" triggers="hover" ngbTooltip="{{entry.todayCommentDate | date:'dd/MM/yy'}} - {{entry.todayCommentText}}" class="fa-stack clickable" aria-hidden="true">
                <i class="fa fa-comment fa-stack-1x text-dark"></i>
                <i class="fa fa-certificate fa-stack-1x text-danger"></i>
                <span class="fa-stack text-white">{{entry.todayCommentsCount}}</span>
              </a>
              <small *ngIf="entry.mostRecentEnquiryText" placement="auto" container="body" triggers="hover" ngbTooltip="{{entry.mostRecentEnquiryDate | date:'dd/MM/yy'}} - {{entry.mostRecentEnquiryText}}">
                <a class="text-dark" [routerLink]="['/horses', entry.horseId, 'enquiries']">
                  <fa size="lg" class="px-1" name="gavel"></fa>
                </a>
              </small>
            </div>
            </div>

            <div *ngIf="entry.shortDispensation">{{entry.shortDispensation}}</div>
            <div *ngIf="entry.weightCarriedValue">{{entry.weightCarriedValue | toStones}}</div>
            <div>
              <div *ngIf="entry.formFigures" class="d-inline pr-1">
                <div
                  class="codes"
                  placement="auto" container="body" triggers="click:blur"
                  [ngbTooltip]="previousFormDetails">
                  {{entry.formFigures.trim()}}
                </div>
              </div>
              <div *ngIf="entry.previousForm.data.length" class="d-inline">
                <div  *ngIf="entry.previousForm.data[0].performanceCode" class="d-inline pr-1">
                  <div
                    class="codes"
                    placement="auto" container="body" triggers="click:blur"
                    ngbTooltip="{{entry.previousForm.data[0].performanceCodeDescription}}">
                    {{entry.previousForm.data[0].performanceCode}}
                  </div>
                </div>
                <button *ngIf="entry.previousForm.data[0].performanceFigure"
                  class="btn btn-link btn-link-no-effect text-dark p-0 m-0">
                  <span>{{entry.previousForm.data[0].performanceFigure}}</span>
                </button>
              </div>

            </div>

<!--                      <span *ngIf="entry.previousForm.data.length">-->
<!--                        <button *ngIf="entry.previousForm.data[0].performanceCode"-->
<!--                          class="btn btn-link text-dark p-0 m-0" [ngClass]="{'text-tooltip': entry.previousForm.data[0].performanceCodeDescription}"-->
<!--                          placement="auto" container="body" triggers="click:blur"-->
<!--                          ngbTooltip="{{entry.previousForm.data[0].performanceCodeDescription}}">-->
<!--                          <span>{{entry.previousForm.data[0].performanceCode}}</span>-->
<!--                        </button>-->
<!--                        <button *ngIf="entry.previousForm.data[0].performanceFigure"-->
<!--                          class="btn btn-link btn-link-no-effect text-dark p-0 m-0">-->
<!--                          <span>{{entry.previousForm.data[0].performanceFigure}}</span>-->
<!--                        </button>-->
<!--                      </span>-->
          </td>

          <td>
            <!-- Days since last run -->
            <div *ngIf="entry.daysSinceLastRan.length">
              <div class="d-inline-block" placement="top" container="body" triggers="click:blur" [ngbTooltip]="daysSinceLastRan">
                <span>Ran ({{entry.daysSinceLastRan[0].raceType}}) {{entry.daysSinceLastRan[0].days}} days ago</span>
              </div>
            </div>
            <!-- Last sampled date -->
            <div *ngIf="entry.lastSampledDate">
              <br *ngIf="!entry.daysSinceLastRan.length">
              <small class="text-nowrap">Sampled {{entry.lastSampledDate | date:'dd/MM/yy'}}</small>
            </div>
            <!-- Spacer if none of the above are available -->
            <div *ngIf="!entry.lastSampledDate || !entry.daysSinceLastRan.length" class="spacer-additional-info"></div>
          </td>

          <td>

            <!-- Jockey -->
            <div class="pb-1">
              <a *ngIf="entry.jockeyKnownAs" [routerLink]="['/jockeys', entry.jockeyId]" target="_blank" class="text-underline">
                <span>{{entry.jockeyKnownAs}}</span>
              </a>
              <a *ngIf="!entry.jockeyKnownAs" [routerLink]="null">
                <i>Not Available</i>
              </a>
              <small class="pl-1" *ngIf="entry.jockeyClaim">({{entry.jockeyClaim}} lbs)</small>
            </div>
            <!-- Trainer -->
            <div class="pb-1">
              <a *ngIf="entry.trainerKnownAs" [routerLink]="['/trainers', entry.trainerId]" target="_blank" class="text-underline">
                <span>{{entry.trainerKnownAs}}</span>
              </a>
              <a *ngIf="!entry.trainerKnownAs" [routerLink]="null">
                <span>Not Available</span>
              </a>
            </div>
            <!-- Owner -->
            <div class="pb-1">
              <a *ngIf="entry.ownerKnownAs" [routerLink]="['/owners', entry.ownerId]" target="_blank" class="text-underline">
                <span>{{entry.ownerKnownAs}}</span>
              </a>
              <a *ngIf="!entry.ownerKnownAs" [routerLink]="null">
                <span>Not Available</span>
              </a>
            </div>
          </td>
          <td>
              <h6 *ngIf="entry.nonRunnerDeclaredDate" class="d-flex pr-1">
                <span class="badge badge-pill badge-secondary p-3">
                  <span *ngIf="!entry.nonRunnerDeclaredReason">Non Runner</span>
                  <span *ngIf="entry.nonRunnerDeclaredReason">NR - {{entry.nonRunnerDeclaredReason}}</span>
                </span>
              </h6>
              <h6 *ngIf="entry.indicators.isWithdrawn" class="d-flex pr-1">
                <span class="badge badge-pill badge-danger p-3">Withdrawn</span>
              </h6>
              <h6 *ngIf="entry.indicators.hasBeenSelectedForSampling" class="d-flex pr-1">
                <span class="badge badge-pill badge-danger p-3" [ngbTooltip]="samplingReasonsTooltip">Selected for Sampling</span>
              </h6>
              <h6 *ngIf="entry.indicators.hasJockeyChange" class="d-flex pr-1">
                <span class="badge badge-pill badge-primary p-3">
                  <span>Jockey Change</span>
                  <span *ngIf="entry.jockeyChangeHistory.length > 1">({{entry.jockeyChangeHistory.length}})</span>
                </span>
              </h6>
              <h6 *ngIf="entry.indicators.isAPeprHorse" class="d-flex pr-1">
                <span class="badge badge-pill badge-danger p-3">PEPR</span>
              </h6>
              <h6 *ngIf="entry.indicators.hadWindSurgerySinceLastRun" class="d-flex pr-1">
                <span class="badge badge-pill badge-warning p-3">Wind Surgery</span>
              </h6>
              <h6 *ngIf="entry.indicators.hasNewTrainer" class="d-flex pr-1">
                <span class="badge badge-pill badge-info p-3">New Trainer</span>
              </h6>
              <h6 *ngIf="entry.indicators.other" class="d-flex pr-1">
                <span
                  *ngFor="let otherIndicator of entry.indicators.other"
                  class="badge badge-pill badge-secondary p-3"
                  ngbTooltip="{{otherIndicator.tooltip ? otherIndicator.tooltip : null}}">
                  {{otherIndicator.text}}
                </span>
              </h6>
          </td>
          <td class="finishing-position">
            <ng-container *ngIf="entry.disqualifiedTimestamp; else nonFinishingCheck">
              <span class="disqualified">DSQ</span>
            </ng-container>
            <ng-template #nonFinishingCheck>
              <ng-container *ngIf="entry.nonFinishingCode !== null; else positionContent">
                <span class="non-finisher">{{ getNonFinishingDescription(entry.nonFinishingCode) }}</span>
              </ng-container>
              <ng-template #positionContent>
                <span *ngIf="getPosition(entry); else noPosition">
                  {{ getPosition(entry) | ordinal }}
                  <span *ngIf="(entry.positionCos !== null && entry.positionPfo !== entry.positionCos) || (entry.positionJudges !== null && entry.positionPfo !== entry.positionJudges)">
                    (ptp {{ entry.positionPfo | ordinal }})
                  </span>
                  <span *ngIf="entry.distance" class="distance">
                    [{{ entry.distance.startsWith('*') ? entry.distance.substring(1) : entry.distance }}]
                  </span>
                </span>
                <ng-template #noPosition>
                  <span>-</span>
                </ng-template>
              </ng-template>
            </ng-template>
          </td>
          <td class="enabledActionsColumn">

            <div *ngIf="!enabledActions.none" ngbDropdown display="dynamic" placement="left-top">
              <button class="btn btn-primary" id="entryActions" ngbDropdownToggle>
                <span class=""><fa name="ellipsis-v"></fa></span>
              </button>
              <div ngbDropdownMenu aria-labelledby="entryActions">
                <button *ngIf="enabledActions.enquiry" (click)="openAddEnquiry(entry)" class="dropdown-item">Add Enquiry</button>
                <button *ngIf="enabledActions.comment" (click)="openAddComment(entry)" class="dropdown-item">Add Comment</button>
                <button *ngIf="enabledActions.sampling" (click)="openRequestSampling(entry)" class="dropdown-item">Sampling Request</button>
                <button *ngIf="enabledActions.nonRunner" [disabled]="!entry.nonRunnerDeclaredDate" (click)="openNonRunner(entry)" class="dropdown-item">Edit Non-Runner</button>
                <button *ngIf="enabledActions.withdrawn" [disabled]="!entry.indicators.isWithdrawn" (click)="openWithdrawn(entry)" class="dropdown-item">Edit Withdrawn</button>
                <button *ngIf="enabledActions.jockeyChange" [disabled]="!entry.jockeyChangeHistory.length" (click)="openChangeJockey(entry)" class="dropdown-item">Jockey Change</button>
                <button *ngIf="enabledActions.jockeyChangeHistory" [disabled]="!entry.jockeyChangeHistory.length" (click)="openChangeJockeyHistory(entry)" class="dropdown-item">Jockey Change History</button>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- Info about the Entry -->
<!--  <div class="row pt-3" (click)="debugShowMe(entry)">-->
<!--    &lt;!&ndash; ROW Silk and Stall &ndash;&gt;-->
<!--    <div class="col-1 col-xl-1 d-flex flex-column align-items-center justify-content-center">-->
<!--      <div class="lead font-weight-bold">{{entry.saddleClothNumber}}</div>-->
<!--      <div *ngIf="entry.stall">[{{entry.stall}}]</div>-->
<!--    </div>-->
<!--    &lt;!&ndash; ROW Colours, Name and gear, anagraphical info, comment and enquiry icons, Carried weight, Forms/Performances &ndash;&gt;-->
<!--    <div class="col-4 col-xl-3 d-flex align-items-center">-->
<!--      <img class="img-silk" src="https://media.britishhorseracing.com/images/bha_silks/?id={{entry.colourCode}}&desc={{entry.colourDescription}}{{entry.nonRunnerDeclaredDate ? '&nonrunner' : ''}}">-->
<!--      <div class="d-flex flex-column align-items-start justify-content-center pl-4">-->
<!--        <div class="d-flex align-items-center justify-content-start text-nowrap">-->
<!--          <a [routerLink]="['/horses', entry.horseId]" target="_blank">{{entry.horseName | uppercase}}</a>-->
<!--          &lt;!&ndash; Headgears, wind surgery &ndash;&gt;-->
<!--          <div class="pl-1" *ngIf="entry.headGears || entry.indicators.hadWindSurgerySinceLastRun">-->
<!--            <small class="pr-1" *ngIf="entry.indicators.hadWindSurgerySinceLastRun">WS</small>-->
<!--            <span *ngIf="entry.headGears">-->
<!--              <small *ngFor="let item of entry.headGears">-->
<!--                <span>{{item.code}}<sup *ngIf="item.firstTimeIndicator">1</sup></span>-->
<!--              </small>-->
<!--            </span>-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash; Age/Sex/Colour, Icons for Comments and Enquiries,  &ndash;&gt;-->
<!--        <div class="d-flex align-items-start justify-content-center">-->
<!--          <span>{{entry.horseAge}}yo/{{entry.horseColour.substr(0, 1)}}/{{entry.horseSex.substr(0, 1)}}</span>-->
<!--          <div class="d-flex align-self-center">-->
<!--            <span *ngIf="meta.previousRpcComment.loading || meta.previousEnquiry.loading || meta.todayComments.loading">-->
<!--              <fa name="circle-o-notch" animation="spin"></fa>-->
<!--            </span>-->
<!--            <span *ngIf="!meta.previousRpcComment.loading && !meta.previousEnquiry.loading && !meta.todayComments.loading">-->
<!--              <small *ngIf="entry.mostRecentRPCText && !entry.todayCommentText" placement="auto" container="body" triggers="hover" ngbTooltip="{{entry.mostRecentRPCDate | date:'dd/MM/yy'}} - {{entry.mostRecentRPCText}}">-->
<!--                <a class="text-dark" [routerLink]="['/horses', entry.horseId, 'comments']">-->
<!--                  <fa size="lg" class="px-1" name="comment"></fa>-->
<!--                </a>-->
<!--              </small>-->
<!--              <a *ngIf="entry.todayCommentText" [routerLink]="['/horses', entry.horseId, 'comments']" placement="auto" container="body" triggers="hover" ngbTooltip="{{entry.todayCommentDate | date:'dd/MM/yy'}} - {{entry.todayCommentText}}" class="fa-stack clickable" aria-hidden="true">-->
<!--                <i class="fa fa-comment fa-stack-1x text-dark"></i>-->
<!--                <i class="fa fa-certificate fa-stack-1x text-danger" style="margin-top:-7px; padding-left:14px;"></i>-->
<!--                <span class="fa-stack text-white" style="margin-top:-15px;padding-left:20px;font-size:10px;">{{entry.todayCommentsCount}}</span>-->
<!--              </a>-->
<!--              <small *ngIf="entry.mostRecentEnquiryText" placement="auto" container="body" triggers="hover" ngbTooltip="{{entry.mostRecentEnquiryDate | date:'dd/MM/yy'}} - {{entry.mostRecentEnquiryText}}">-->
<!--                <a class="text-dark" [routerLink]="['/horses', entry.horseId, 'enquiries']">-->
<!--                  <fa size="lg" class="px-1" name="gavel"></fa>-->
<!--                </a>-->
<!--              </small>-->
<!--            </span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <span *ngIf="entry.shortDispensation">{{entry.shortDispensation}}</span>-->
<!--        <span *ngIf="entry.weightCarriedValue">{{entry.weightCarriedValue | toStones}}</span>-->
<!--        <div>-->
<!--          <button *ngIf="entry.formFigures"-->
<!--            class="btn btn-link text-tooltip p-0 m-0"-->
<!--            placement="auto"-->
<!--            container="body"-->
<!--            triggers="click:blur"-->
<!--            [ngbTooltip]="previousFormDetails">-->
<!--              <span>{{entry.formFigures.trim()}}</span>-->
<!--          </button>-->
<!--          <span *ngIf="entry.previousForm.data.length">-->
<!--            <button *ngIf="entry.previousForm.data[0].performanceCode"-->
<!--              class="btn btn-link text-dark p-0 m-0" [ngClass]="{'text-tooltip': entry.previousForm.data[0].performanceCodeDescription}"-->
<!--              placement="auto" container="body" triggers="click:blur"-->
<!--              ngbTooltip="{{entry.previousForm.data[0].performanceCodeDescription}}">-->
<!--              <span>{{entry.previousForm.data[0].performanceCode}}</span>-->
<!--            </button>-->
<!--            <button *ngIf="entry.previousForm.data[0].performanceFigure"-->
<!--              class="btn btn-link btn-link-no-effect text-dark p-0 m-0">-->
<!--              <span>{{entry.previousForm.data[0].performanceFigure}}</span>-->
<!--            </button>-->
<!--          </span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    &lt;!&ndash; ROW Additional Info about the entry &ndash;&gt;-->
<!--    <div class="d-none d-sm-block">-->
<!--      <div class="col-3 col-xl-3 d-flex flex-column align-items-start justify-content-center pt-4 mt-4">-->
<!--        &lt;!&ndash; Days since last run &ndash;&gt;-->
<!--        <div *ngIf="entry.daysSinceLastRan.length">-->
<!--          <button-->
<!--            class="btn btn-link text-tooltip p-0 m-0"-->
<!--            placement="auto"-->
<!--            container="body"-->
<!--            triggers="click:blur"-->
<!--            [ngbTooltip]="daysSinceLastRan">-->
<!--            <span>Ran ({{entry.daysSinceLastRan[0].raceType}}) {{entry.daysSinceLastRan[0].days}} days ago</span>-->
<!--          </button>-->
<!--        </div>-->
<!--        &lt;!&ndash; Last sampled date &ndash;&gt;-->
<!--        <div *ngIf="entry.lastSampledDate">-->
<!--          <br *ngIf="!entry.daysSinceLastRan.length">-->
<!--          <small class="text-nowrap">Sampled {{entry.lastSampledDate | date:'dd/MM/yy'}}</small>-->
<!--        </div>-->
<!--        &lt;!&ndash; Spacer if none of the above are available &ndash;&gt;-->
<!--        <div *ngIf="!entry.lastSampledDate || !entry.daysSinceLastRan.length" class="spacer-additional-info"></div>-->
<!--      </div>-->
<!--    </div>-->
<!--    &lt;!&ndash; ROW People &ndash;&gt;-->
<!--    <div class="col-4 col-xl-3 d-none d-md-block">-->
<!--      <div class="d-flex flex-column align-items-start justify-content-center">-->
<!--        &lt;!&ndash; Jockey &ndash;&gt;-->
<!--        <div class="pb-1">-->
<!--          <a *ngIf="entry.jockeyKnownAs" [routerLink]="['/jockeys', entry.jockeyId]" target="_blank">-->
<!--            <span>{{entry.jockeyKnownAs}}</span>-->
<!--          </a>-->
<!--          <a *ngIf="!entry.jockeyKnownAs" [routerLink]="null">-->
<!--            <i>Not Available</i>-->
<!--          </a>-->
<!--          <span class="pl-1" *ngIf="entry.jockeyClaim">({{entry.jockeyClaim}} lbs)</span>-->
<!--        </div>-->
<!--        &lt;!&ndash; Trainer &ndash;&gt;-->
<!--        <div class="pb-1">-->
<!--          <a *ngIf="entry.trainerKnownAs" [routerLink]="['/trainers', entry.trainerId]" target="_blank">-->
<!--            <span>{{entry.trainerKnownAs}}</span>-->
<!--          </a>-->
<!--          <a *ngIf="!entry.trainerKnownAs" [routerLink]="null">-->
<!--            <span>Not Available</span>-->
<!--          </a>-->
<!--        </div>-->
<!--        &lt;!&ndash; Owner &ndash;&gt;-->
<!--        <div class="pb-1">-->
<!--          <a *ngIf="entry.ownerKnownAs" [routerLink]="['/owners', entry.ownerId]" target="_blank">-->
<!--            <span>{{entry.ownerKnownAs}}</span>-->
<!--          </a>-->
<!--          <a *ngIf="!entry.ownerKnownAs" [routerLink]="null">-->
<!--            <span>Not Available</span>-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <!-- Indicators and Actions for the Entry -->
<!--  <div class="row py-2">-->
<!--    &lt;!&ndash; FLAGS &ndash;&gt;-->
<!--    <div class="col d-flex align-items-center justify-content-start pt-1">-->
<!--      <h6 *ngIf="entry.nonRunnerDeclaredDate" class="d-flex pr-1">-->
<!--        <span class="badge badge-pill badge-secondary p-3">-->
<!--          <span *ngIf="!entry.nonRunnerDeclaredReason">Non Runner</span>-->
<!--          <span *ngIf="entry.nonRunnerDeclaredReason">NR - {{entry.nonRunnerDeclaredReason}}</span>-->
<!--        </span>-->
<!--      </h6>-->
<!--      <h6 *ngIf="entry.indicators.isWithdrawn" class="d-flex pr-1">-->
<!--        <span class="badge badge-pill badge-danger p-3">Withdrawn</span>-->
<!--      </h6>-->
<!--      <h6 *ngIf="entry.indicators.hasBeenSelectedForSampling" class="d-flex pr-1">-->
<!--        <span class="badge badge-pill badge-danger p-3" [ngbTooltip]="samplingReasonsTooltip">Selected for Sampling</span>-->
<!--      </h6>-->
<!--      <h6 *ngIf="entry.indicators.hasJockeyChange" class="d-flex pr-1">-->
<!--        <span class="badge badge-pill badge-primary p-3">-->
<!--          <span>Jockey Change</span>-->
<!--          <span *ngIf="entry.jockeyChangeHistory.length > 1">({{entry.jockeyChangeHistory.length}})</span>-->
<!--        </span>-->
<!--      </h6>-->
<!--      <h6 *ngIf="entry.indicators.isAPeprHorse" class="d-flex pr-1">-->
<!--        <span class="badge badge-pill badge-danger p-3">PEPR</span>-->
<!--      </h6>-->
<!--      <h6 *ngIf="entry.indicators.hadWindSurgerySinceLastRun" class="d-flex pr-1">-->
<!--        <span class="badge badge-pill badge-warning p-3">Wind Surgery</span>-->
<!--      </h6>-->
<!--      <h6 *ngIf="entry.indicators.hasNewTrainer" class="d-flex pr-1">-->
<!--        <span class="badge badge-pill badge-info p-3">New Trainer</span>-->
<!--      </h6>-->
<!--      <h6 *ngIf="entry.indicators.other" class="d-flex pr-1">-->
<!--        <span-->
<!--          *ngFor="let otherIndicator of entry.indicators.other"-->
<!--          class="badge badge-pill badge-secondary p-3"-->
<!--          ngbTooltip="{{otherIndicator.tooltip ? otherIndicator.tooltip : null}}"-->
<!--        >-->
<!--          {{otherIndicator.text}}-->
<!--        </span>-->
<!--      </h6>-->
<!--    </div>-->
    <!-- ACTIONS -->
<!--    <div class="col-1 d-flex align-items-center justify-content-center p-0">-->
<!--      <div *ngIf="!enabledActions.none" ngbDropdown display="dynamic" placement="left-top">-->
<!--        <button class="btn btn-primary px-2" id="entryActions" ngbDropdownToggle>-->
<!--          <span class="d-none d-lg-block">Actions</span>-->
<!--          <span class="d-block d-lg-none px-3"><fa name="bars"></fa></span>-->
<!--        </button>-->
<!--        <div ngbDropdownMenu aria-labelledby="entryActions">-->
<!--          <button *ngIf="enabledActions.enquiry" (click)="openAddEnquiry(entry)" class="dropdown-item">Add Enquiry</button>-->
<!--          <button *ngIf="enabledActions.comment" (click)="openAddComment(entry)" class="dropdown-item">Add Comment</button>-->
<!--          <button *ngIf="enabledActions.sampling" (click)="openRequestSampling(entry)" class="dropdown-item">Sampling Request</button>-->
<!--          <button *ngIf="enabledActions.nonRunner" [disabled]="!entry.nonRunnerDeclaredDate" (click)="openNonRunner(entry)" class="dropdown-item">Edit Non-Runner</button>-->
<!--          <button *ngIf="enabledActions.withdrawn" [disabled]="!entry.indicators.isWithdrawn" (click)="openWithdrawn(entry)" class="dropdown-item">Edit Withdrawn</button>-->
<!--          <button *ngIf="enabledActions.jockeyChange" [disabled]="!entry.jockeyChangeHistory.length" (click)="openChangeJockey(entry)" class="dropdown-item">Jockey Change</button>-->
<!--          <button *ngIf="enabledActions.jockeyChangeHistory" [disabled]="!entry.jockeyChangeHistory.length" (click)="openChangeJockeyHistory(entry)" class="dropdown-item">Jockey Change History</button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->


<ng-template #previousFormDetails>
  <table class="table table-sm table-striped">
    <thead>
      <tr>
        <th scope="col">Date</th>
        <th class="text-left" scope="col">Position</th>
      </tr>
    </thead>
    <tbody *ngIf="entry.previousForm.data.length">
      <tr *ngFor="let pf of entry.previousForm.data">
        <td>{{pf.raceDateTime | date:'dd/MM/yy h:mm'}} - {{pf.courseShortName}}</td>
        <td class="text-left">{{pf.finishingPosition}}/{{pf.runnersCount}}</td>
      </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #daysSinceLastRan>
  <table class="table table-sm table-striped">
    <thead>
      <tr>
        <th scope="col">Days</th>
        <th class="text-left" scope="col">Race</th>
      </tr>
    </thead>
    <tbody *ngIf="entry.daysSinceLastRan.length">
      <tr *ngFor="let item of entry.daysSinceLastRan">
        <td>{{item.days}} days</td>
        <td>{{item.raceType}}</td>
      </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #samplingReasonsTooltip>
  <div *ngIf="entry.sampling">
    <span *ngFor="let item of entry.sampling.reasons; last as lastReason">
      {{item.reason}}
      <span *ngIf="!lastReason">, </span>
    </span>
  </div>
</ng-template>
