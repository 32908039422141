import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../../services/events.service';
import { ModalsService } from '../../../services/modals.service';

import { AddEditEnquiryComponent } from '../actions/add-edit-enquiry/add-edit-enquiry.component';

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.scss']
})
export class EnquiryComponent implements OnInit {

  @Input() enquiry;
  meta: {
    viewMode: string,
    loading: boolean,
    saving: boolean,
    activeStage: string
  };
  userId: number;
  userIsDisciplinary: boolean;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private activeModal: NgbActiveModal,
    private notification: ToastrService,
    private eventsService: EventsService,
    private modalsService: ModalsService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.meta = {
      viewMode: 'modal',
      loading: false,
      saving: false,
      activeStage: null
    };

    this.eventsService.observableUser.subscribe(data => {
      this.userId = data.id;
      this.userIsDisciplinary = data.roles.find(r => r === 'ROLE_HEAD_OFFICE') !== undefined;
    });

    if (!this.enquiry) {
      this.meta.viewMode = 'view';
      this.route.params.subscribe(params => {
        if (Object.keys(params).length) {
          this.enquiry = { enquiryId: this.route.snapshot.paramMap.get('enquiryId') };
        }
      });
    }

    this.loadData();
  }

  loadData = () => {
    this.meta.loading = true;
    // console.log('Loading', this.enquiry.enquiryId);
    this.modalsService.getEnquiry(this.enquiry).subscribe(
      res => {
        this.enquiry = res;
        const originalStages = Object.assign({}, res.stages);
        this.enquiry.isUpdated = moment(this.enquiry.updatedAt).isSame(moment(this.enquiry.createdAt)) ? false : true;
        Object.keys(this.enquiry.stages).forEach(stage => {
          if (this.enquiry.stages[stage] !== null) {
            if (stage === 'appeal') {
              this.enquiry.stages[stage].appealText = this.enquiry.stages[stage].appealConclusion ?
                this.enquiry.stages[stage].appealConclusion.replace(/_/gi, ' ') : this.enquiry.stages[stage].appealConclusion;
              this.enquiry.stages[stage].deposit = this.enquiry.stages[stage].deposit === 'n/a' ?
                'N/A' : this.enquiry.stages[stage].deposit;
            }
            const breachStatus = this.enquiry.stages[stage].outcomes.find(eo => eo.objectType === 'inBreach');
            if (breachStatus !== undefined && breachStatus.isApplied) {
              this.enquiry.stages[stage].breachStatus = breachStatus.value.replace(/_/g, ' ');
            } else {
              this.enquiry.stages[stage].breachStatus = 'not in breach';
            }
            const currentEnquiry = Object.assign({}, this.enquiry);
            for (const key in this.enquiry.stages[stage]) {
              if (this.enquiry.stages[stage].hasOwnProperty(key)) {
                if (this.enquiry.stages[stage][key] === undefined) {
                  this.enquiry.stages[stage][key] = currentEnquiry[key];
                }
              }
            }
          }
          console.log(`[ DEBUG ] Stage ${stage} found. Original:`, originalStages[stage], `Processed:`, this.enquiry.stages[stage]);
        });
        this.meta.activeStage = this.enquiry.stage;
        this.meta.loading = false;
        console.log(`[ DEBUG ] Enquiry Loaded and Ready:`, this.enquiry);
      },
      err => {

        this.notification.error(`Could't get the Enquiry Details: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.loading = false;
      }
    );
  }

  openEdit = (nextStage) => {
    if (this.meta.viewMode === 'modal') {
      this.activeModal.dismiss('edit mode');
    }
    const modalWindow = this.modalService.open(AddEditEnquiryComponent,
      { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.enquiry = this.enquiry;
    modalWindow.componentInstance.nextStage = nextStage;
    modalWindow.componentInstance.possibleOffenders = [this.enquiry.offender];
    modalWindow.result.then((result) => {
      // console.log(`[ DEBUG ] Modal Closed with payload`, result);
      if (this.meta.viewMode === 'view') {
        this.loadData();
      }
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  delete = () => {
    this.meta.saving = true;
    this.modalsService.deleteEnquiry(this.enquiry.enquiryId, this.userId).subscribe(
      res => {
        console.log('Enquiry deleted', res);
        this.eventsService.changeEnquiriesData({
          section: 'enquiries',
          result: Object.assign({deleted: true}, this.enquiry)
        });
        this.notification.success(`Enquiry deleted.`, 'Success!');
        this.activeModal.dismiss('enquiry deleted');
        this.meta.saving = false;
      },
      err => {
        this.notification.error(`Could't delete this enquiry: ` +
        `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
        this.meta.saving = false;
      }
    );
  }

  cancel = (action) => {
    this.activeModal.dismiss(action);
  }

}
