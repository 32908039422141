<form #scaleForm="ngForm">
  <div class="modal-header d-flex justify-content-center">
    <h4 class="modal-title">RACE STATUS</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col text-center">
        <p *ngIf="race.status">The race's current status is <strong>{{race.info.status}}</strong></p>
        <div class="form-group">
          <label for="raceStatus">Select a new status</label>
          <select class="custom-select" id="raceStatus" name="raceStatus" [(ngModel)]="selectedStatus">
            <option *ngFor="let status of possibleStatuses" [value]="status" [disabled]="race.status === status">{{status}}</option>
          </select>
        </div>
        <br>
      </div>
    </div>
  </div>
  <div *ngIf="!meta.saving" class="modal-footer d-flex justify-content-around">
    <button type="button" class="btn btn-outline-dark" (click)="cancel('cancel')">Close</button>
    <button type="button" class="btn btn-primary" [disabled]="!selectedStatus" (click)="confirm()">Confirm</button>
  </div>
  <div *ngIf="meta.saving" class="modal-footer d-flex justify-content-around">
    <h1>
      <fa name="circle-o-notch" animation="spin"></fa>
    </h1>
  </div>
</form>
