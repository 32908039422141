import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HorsesService } from '../../services/horses.service';
import { EventsService } from '../../services/events.service';

import { HorseDispensationComponent } from './horse-dispensation/horse-dispensation.component';
import { AddEditEnquiryComponent } from '../modals/actions/add-edit-enquiry/add-edit-enquiry.component';

@Component({
  selector: 'app-horse',
  templateUrl: './horse.component.html',
  styleUrls: ['./horse.component.scss']
})
export class HorseComponent implements OnInit {

  meta: {
    loading: boolean,
    saving: boolean,
    selectedTab: string
  };
  horse: any;

  constructor(
    private notification: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private modalService: NgbModal,
    private horsesService: HorsesService,
    private eventsService: EventsService,
  ) { }

  ngOnInit() {
    this.meta = {
      loading: false,
      saving: false,
      selectedTab: null
    };

    this.eventsService.changeFixtureData({courseName: null});

    this.route.params.subscribe(params => {
      if (Object.keys(params).length) {
        this.horse = {
          horseId: this.route.snapshot.paramMap.get('horseId')
        };
        this.getHorseInfo(this.horse.horseId);
        if (params.tab) {
          this.selectTab(params.tab);
        } else {
          this.selectTab('previous-form');
        }
      }
    });
  }

  getHorseInfo = (horseId) => {
    this.meta.loading = true;
    this.horsesService.getHorseInfo(this.horse).subscribe(
      res => {
        this.horse = res;
        this.meta.loading = false;
      },
      err => {
        this.notification.error(`Could't get the Horse Details: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.loading = false;
        if (err.status === 401) {
          this.router.navigate(['/logout']);
        }
      }
    );
  }

  openAddDispensation = () => {
    const modalWindow = this.modalService.open(HorseDispensationComponent, { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.horse = this.horse;
    modalWindow.result.then((result) => {
      console.log(`[ DEBUG ] Modal Closed with payload`, result);
      this.horse.dispensation = result;
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  openAddEnquiry = (entry) => {
    const possibleOffenders = [
      {
        id: entry.horseId,
        knownAs: entry.horseName || entry.name,
        type: 'horse'
      }
    ];
    const enquiry = {
      // meta: { year: moment().year(), raceId: null, divisionSequence: null, horseId: entry.horseId },
      offender: possibleOffenders[0]
    };
    const modalWindow = this.modalService
    .open(AddEditEnquiryComponent, { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.enquiry = enquiry;
    modalWindow.componentInstance.possibleOffenders = possibleOffenders;
    modalWindow.result.then((result) => {
      this.selectTab('enquiries', true);
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  selectTab = (tabName, force = false) => {
    this.location.replaceState(`/horses/${this.horse.horseId}/${tabName}`);
    this.meta.selectedTab = tabName;
    if (this.meta.selectedTab === 'enquiries' && tabName === 'enquiries' && force === true) {
      this.eventsService.changeGenericEnquiry(true);
    }
  }

  enquiriesReloaded(value) {
    this.eventsService.changeGenericEnquiry(!value);
  }

}
