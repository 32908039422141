import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.router.component.html',
  styleUrls: ['./app.router.component.scss']
})
export class AppRouterComponent {

  constructor() { }

  ngOnInit(): void { }
}
