<div class="modal-header d-flex justify-content-center">
  <h4 class="modal-title">POSSIBLE FIXTURE SUSPENSION DATES</h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col">
      <p class="font-weight-bold">Possible suspension dates:</p>
      <p>{{ suspensionDates }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <p class="font-weight-bold">Possible suspension dates with G1 races:</p>
      <ul>
        <li *ngFor="let suspensionDate of suspensionG1RacesDates">
          {{suspensionDate}}
        </li>
      </ul>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-around">
    <button type="button" class="btn btn-outline-dark" (click)="close()">Close</button>
  </div>
</div>
