import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestOptions } from '@angular/http';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';

@Injectable()
export class FixturesService {

  defaultHeaders: HttpHeaders;
  constructor(
    private http: HttpClient,
    private auth: AuthenticationService
  ) { }

  getFixtures(date, headers = this.auth.getHeaders()): any {
    return this.http.get(`${environment.apiBase}/v2/fixtures?ondate=${date}`, {headers});
  }

  getFixtureInfo(fixture, headers = this.auth.getHeaders()): any {
    return this.http.get(`${environment.apiBase}/v2/fixtures/${fixture.fixtureYear}/${fixture.fixtureId}`, {headers});
  }

  getFixtureComments(fixture, headers = this.auth.getHeaders()): any {
    return this.http.get(`${environment.apiBase}/v2/comments?year=${fixture.fixtureYear}&fixtureId=${fixture.fixtureId}`, {headers});
  }

  getFixtureRaces(fixture, headers = this.auth.getHeaders()): any {
    return this.http.get(`${environment.apiBase}/v2/fixtures/${fixture.fixtureYear}/${fixture.fixtureId}/races`, {headers});
  }

  getFixtureSamplingRequests(fixture, headers = this.auth.getHeaders()): any {
    return this.http.get(`${environment.apiBase}/v2/fixtures/${fixture.fixtureYear}/${fixture.fixtureId}/sampling`, {headers});
  }

  // RANDOM RUNNER MANAGEMENT

  getFixtureRandomRunner(fixture, headers = this.auth.getHeaders()): any {
    return this.http.get(`${environment.apiBase}/v2/fixtures/${fixture.fixtureYear}/${fixture.fixtureId}/random-runner`, {headers});
  }

  setPotentialRandomRunner(runner): any {
    try {
      this.cleanOtherRandomRunner();
      localStorage.setItem(`Stipes_Random_Runner_${runner.fixtureId}_${runner.year}`, JSON.stringify(runner));
      return true;
    } catch (e) {
      throw e;
    }
  }

  cleanOtherRandomRunner() {
    try {
      const availableLocalStorageKeys = Object.keys(localStorage);
      for (let i = 0; i < availableLocalStorageKeys.length; i++) {
        // Filter only keys that match the name used for random runners
        if (availableLocalStorageKeys[i].match(/Stipes_Random_Runner_/gi)) {
          localStorage.removeItem(availableLocalStorageKeys[i]);
        }
      }
      return true;
    } catch (e) {
      throw e;
    }
  }

  getPotentialRandomRunner(fixtureId, fixtureYear) {
    return JSON.parse(localStorage.getItem(`Stipes_Random_Runner_${fixtureId}_${fixtureYear}`));
  }

}
