<form name="form" #formNonRunner="ngForm" (ngSubmit)="formNonRunner.form.valid && save()">
  <div class="modal-header d-flex justify-content-center">
    <h4 class="modal-title text-truncate">NON-RUNNER INFO FOR {{meta.title | uppercase}}</h4>
  </div>
  <div class="modal-body" *ngIf="meta.loading">
    <div class="row">
      <div class="col text-center">
        <fa name="circle-o-notch" size="2x" animation="spin"></fa>
      </div>
    </div>
  </div>
  <div class="modal-body" *ngIf="!meta.loading">
    <div class="row" *ngIf="nonRunnerInfo">
      <div class="col">
        <div class="form-group">
          <label>Penalty</label>
          <select
            class="custom-select"
            #penaltyStatus="ngModel"
            name="penaltyStatus"
            [(ngModel)]="nonRunnerInfo.penalty"
            required
          >
            <ng-container>
              <option [ngValue]="0">Waived</option>
              <option [ngValue]="1">Fixed</option>
            </ng-container>
          </select>
          <div *ngIf="formNonRunner.submitted && penaltyStatus.invalid">
            <div class="alert alert-danger" *ngIf="penaltyStatus.errors.required">Penalty Status is required</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="nonRunnerInfo.penalty === 1">
      <div class="col">
        <div class="form-group">
          <label>Fine</label>
          <select
            class="custom-select"
            #penaltyFine="ngModel"
            name="penaltyFine"
            [(ngModel)]="nonRunnerInfo.penaltyFine"
            required
          >
            <ng-container>
              <option [ngValue]="140">£140</option>
              <option [ngValue]="500">£500</option>
            </ng-container>
          </select>
          <div *ngIf="formNonRunner.submitted && penaltyFine.invalid">
            <div class="alert alert-danger" *ngIf="penaltyFine.errors.required">Fine is required</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="nonRunnerInfo.penalty === 1">
      <div class="col">
        <div class="form-group">
          <label>Trainer or Owner</label>
          <select
            class="custom-select"
            #types="ngModel"
            name="types"
            [(ngModel)]="nonRunnerInfo.typeOfPerson"
            required
          >
            <option *ngFor="let item of typeOfPerson" [value]="item.type">{{item.type}}</option>
          </select>
          <div *ngIf="formNonRunner.submitted && types.invalid">
            <div class="alert alert-danger" *ngIf="types.errors.required">Person is required</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="nonRunnerInfo">
      <div class="col">
        <div class="form-group">
          <label for="reason">Reason</label>
          <select class="custom-select"
            #reason="ngModel"
            name="reason"
            [(ngModel)]="nonRunnerInfo.reasonId"
            required
          >
            <option *ngFor="let item of possibleReasons" [value]="item.id">{{item.reason}}</option>
          </select>
          <div *ngIf="formNonRunner.submitted && reason.invalid">
            <div *ngIf="reason.errors.required">Reason is required</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="nonRunnerInfo">
      <div class="col">
        <div class="form-group">
          <label for="comment">Additional Comment</label>
          <textarea class="form-control textarea-height" id="comment" name="comment" [(ngModel)]="nonRunnerInfo.comment"></textarea>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="nonRunnerInfo">
      <div class="col">
        <div class="form-group">
          <label for="internalComment">Internal Comment</label>
          <textarea class="form-control textarea-height" id="internalComment" name="internalComment" [(ngModel)]="nonRunnerInfo.internalComment"></textarea>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="nonRunnerInfo">
      <div class="col col-lg-3 offset-lg-3">
        <form class="form">
          <div class="form-group">
            <label for="declaredDate">Declared Date:</label>
            <div class="input-group calendar-open-up" container="body">
              <input
                class="form-control"
                placeholder="dd-mm-yyyy"
                name="declaredDate"
                [(ngModel)]="declared.date"
                ngbDatepicker
                #declaredDate="ngbDatepicker"
                (click)="declaredDate.toggle(); $event.stopPropagation();"
                (document:click)="datepickerFix($event, declaredDate)"
                required
              >
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="declaredDate.toggle(); $event.stopPropagation();" type="button">
                  <fa name="calendar"></fa>
                </button>
              </div>
              <div *ngIf="formNonRunner.submitted && declaredDate.invalid">
                <div *ngIf="declaredDate.errors.required">Declared Date is required</div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col">
        <label for="declaredTime">Declared Time:</label>
        <ngb-timepicker
          #declaredTime="ngModel"
          name="declaredTime"
          [(ngModel)]="declared.time"
          [spinners]="false"
        ></ngb-timepicker>
        <div *ngIf="formNonRunner.submitted && declaredTime.invalid">
          <div *ngIf="declaredTime.errors.required">Declared Time is required</div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!meta.saving" class="modal-footer d-flex justify-content-around">
    <button type="button" class="btn btn-outline-dark" (click)="cancel('cancel')">Close</button>
    <button type="submit" class="btn btn-primary" [disabled]="!nonRunnerInfo || !nonRunnerInfo.reasonId === null">Save</button>
  </div>
  <div *ngIf="meta.saving" class="modal-footer d-flex justify-content-around">
    <h1>
      <fa name="circle-o-notch" animation="spin"></fa>
    </h1>
  </div>
</form>
