import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import * as moment from 'moment';

import { ToastrService } from 'ngx-toastr';
import { TrainersService } from '../../services/trainers.service';
import { EventsService } from '../../services/events.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AddEditEnquiryComponent } from '../modals/actions/add-edit-enquiry/add-edit-enquiry.component';

@Component({
  selector: 'app-trainer',
  templateUrl: './trainer.component.html',
  styleUrls: ['./trainer.component.scss']
})
export class TrainerComponent implements OnInit {

  meta: {
    loading: boolean,
    saving: boolean,
    selectedTab: string
  };
  trainer: any;

  constructor(
    private notification: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private trainersService: TrainersService,
    private eventsService: EventsService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.meta = {
      loading: false,
      saving: false,
      selectedTab: 'enquiries'
    };

    this.eventsService.changeFixtureData({courseName: null});

    this.route.params.subscribe(params => {
      if (Object.keys(params).length) {
        this.trainer = {
          trainerId: this.route.snapshot.paramMap.get('trainerId')
        };
        this.getTrainerInfo(this.trainer.trainerId);
        if (params.tab) {
          this.selectTab(params.tab);
        }
      }
    });
  }

  getTrainerInfo = (trainerId) => {
    this.meta.loading = true;
    this.trainersService.getTrainerInfo(this.trainer).subscribe(
      res => {
        this.trainer = res;
        this.trainer.address = this.trainer.address ? this.trainer.address.replace(/\|/g, ', ') : this.trainer.address;
        this.meta.loading = false;
      },
      err => {

        this.notification.error(`Could't get the Trainer Details: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.loading = false;
        if (err.status === 401) {
          this.router.navigate(['/logout']);
        }
      }
    );
  }

  openAddEnquiry = (entry) => {
    const possibleOffenders = [
      {
        id: entry.trainerId,
        knownAs: entry.trainerKnownAs || entry.knownAs,
        type: 'trainer'
      }
    ];
    const enquiry = {
      // meta: { year: moment().year(), raceId: null, divisionSequence: null, horseId: null },
      offender: possibleOffenders[0]
    };
    const modalWindow = this.modalService
    .open(AddEditEnquiryComponent, { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.enquiry = enquiry;
    modalWindow.componentInstance.possibleOffenders = possibleOffenders;
    modalWindow.result.then((result) => {
      this.selectTab('enquiries', true);
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  selectTab = (tabName, force = false) => {
    this.location.replaceState(`/trainers/${this.trainer.trainerId}/${tabName}`);
    this.meta.selectedTab = tabName;
    if (this.meta.selectedTab === 'enquiries' && tabName === 'enquiries' && force === true) {
      this.eventsService.changeGenericEnquiry(true);
    }
  }

  enquiriesReloaded(value) {
    this.eventsService.changeGenericEnquiry(!value);
  }

}
