// Possible outcomes:
// - inBreach
// - suspension
// - sst
// - refer
// - personSuspension
// - personDisqualification
// - licenseSuspension
// - horseWithdrawn
// - horseSuspension
// - horseDisqualification
// - fine
// - advisory

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-enquiry-outcomes',
  templateUrl: './enquiry-outcomes.component.html',
  styleUrls: ['./enquiry-outcomes.component.scss']
})
export class EnquiryOutcomesComponent implements OnInit {

  @Input() enquiry;
  @Input() detailed;
  outcomes: Array<any>;
  processedOutcomes: Array<String>;
  suspendedDates: Array<String>;
  suspensionPeriod: String;

  constructor() { }

  ngOnInit() {
    this.outcomes = this.enquiry.outcomes.filter(eo => eo.isApplied === true && eo.objectType !== 'inBreach');
    this.processedOutcomes = this.outcomes.map(outcome => {
      let outcomeText = '';
      switch (outcome.objectType) {
        case 'suspension': {
          if (!outcome.value) {
            outcomeText = `Suspension TBD`;
          } else {
            outcomeText = `Suspended For ${outcome.value} ${outcome.attributes.period}`;
          }
        } break;
        case 'sst': outcomeText = `Stall Test`; break;
        case 'refer': outcomeText = `Refer`; break;
        case 'personSuspension': outcomeText = `Person Suspended`; break;
        case 'personDisqualification': outcomeText = `Person Disqualified`; break;
        case 'licenseSuspension': outcomeText = `License Suspended`; break;
        case 'horseWithdrawn': outcomeText = `Horse Withdrawn`; break;
        case 'horseSuspension': outcomeText = `Horse Suspended`; break;
        case 'horseDisqualification': outcomeText = `Horse Disqualified`; break;
        case 'fine': outcomeText = `Fined for £${outcome.value}`; break;
        case 'advisory': outcomeText = `${outcome.attributes.type}`; break;
        default: outcomeText = `Unexpected: ${outcome.attributes ? outcome.attributes.type : 'Unknown'}`;
      }

      // if (this.detailed === true) {
        // const suspension = this.outcomes
        //   .find(eo => (/suspension/i)
        //   .test(eo.objectType) && eo.suspensionDates && eo.suspensionDates.length);

        if (outcome.objectType === 'suspension') {
          this.suspendedDates = outcome
            ? outcome.suspensionDates.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
            : null;
          this.suspensionPeriod = outcome.attributes.period.replace('(s)', 's');
        }

        const fine = this.outcomes.find(eo => eo.objectType === 'fine' && eo.value);
        if (fine) {
          this.enquiry.fine = fine ? fine.value : null;
        }

        // console.log(this.enquiry);
      // }

      return outcomeText;
    });
  }

}
