import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestOptions } from '@angular/http';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';

@Injectable()
export class RacesService {

  defaultHeaders: HttpHeaders;
  constructor(
    private http: HttpClient,
    private auth: AuthenticationService
  ) { }

  getRaceInfo(race, headers = this.auth.getHeaders()): any {
    return this.http.get(`${environment.apiBase}/v2/races/${race.raceYear || race.year}/${race.raceId}/${race.divisionSequence}`, {headers});
  }

  getRaceComments(race, headers = this.auth.getHeaders()): any {
    return this.http.get(`${environment.apiBase}/v2/comments?year=${race.raceYear || race.year}&raceId=${race.raceId}&divisionSequence=${race.divisionSequence}`, {headers});
  }

  getRaceEnquiries(race, headers = this.auth.getHeaders()): any {
    return this.http.get(`${environment.apiBase}/v2/enquiries?year=${race.raceYear || race.year}&raceId=${race.raceId}&divisionSequence=${race.divisionSequence}`, {headers});
  }

  getRaceEntries(race, headers = this.auth.getHeaders()): any {
    return this.http.get(`${environment.apiBase}/v2/races/${race.raceYear || race.year}/${race.raceId}/${race.divisionSequence}/entries`, {headers});
  }

}
