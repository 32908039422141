/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./owner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../commons/enquiry-search/enquiry-search.component.ngfactory";
import * as i3 from "../commons/enquiry-search/enquiry-search.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "ngx-toastr";
import * as i6 from "../../services/jockeys.service";
import * as i7 from "../../services/owners.service";
import * as i8 from "../../services/trainers.service";
import * as i9 from "../../services/horses.service";
import * as i10 from "../../services/events.service";
import * as i11 from "@angular/common";
import * as i12 from "./owner-comments/owner-comments.component.ngfactory";
import * as i13 from "./owner-comments/owner-comments.component";
import * as i14 from "@angular/router";
import * as i15 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i16 from "../navbar/navbar.component.ngfactory";
import * as i17 from "../navbar/navbar.component";
import * as i18 from "../../services/authentication.service";
import * as i19 from "./owner.component";
var styles_OwnerComponent = [i0.styles];
var RenderType_OwnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OwnerComponent, data: {} });
export { RenderType_OwnerComponent as RenderType_OwnerComponent };
function View_OwnerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "col-12 col-md-4 offset-md-4 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "spinner"], ["class", "d-block ml-auto mr-auto"], ["src", "assets/loader.svg"]], null, null, null, null, null))], null, null); }
function View_OwnerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col col-sm-4 col-md-2  pr-0 mr-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ":"])), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "col col-sm-6 col-md-10 "]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 1), _v.context.$implicit.type)); _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.details; _ck(_v, 6, 0, currVal_1); }); }
function View_OwnerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-enquiry-search", [["entityType", "owner"]], null, [[null, "reloaded"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("reloaded" === en)) {
        var pd_0 = (_co.enquiriesReloaded($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_EnquirySearchComponent_0, i2.RenderType_EnquirySearchComponent)), i1.ɵdid(1, 114688, null, 0, i3.EnquirySearchComponent, [i4.NgbModal, i5.ToastrService, i6.JockeysService, i7.OwnersService, i8.TrainersService, i9.HorsesService, i10.EventsService], { entity: [0, "entity"], entityType: [1, "entityType"] }, { reloaded: "reloaded" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.owner; var currVal_1 = "owner"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_OwnerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "results-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OwnerComponent_5)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.owner.ownerId; _ck(_v, 2, 0, currVal_0); }, null); }
function View_OwnerComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-owner-comments", [], null, null, null, i12.View_OwnerCommentsComponent_0, i12.RenderType_OwnerCommentsComponent)), i1.ɵdid(1, 114688, null, 0, i13.OwnerCommentsComponent, [i4.NgbModal, i5.ToastrService, i14.ActivatedRoute, i11.Location, i7.OwnersService, i10.EventsService], { owner: [0, "owner"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.owner; _ck(_v, 1, 0, currVal_0); }, null); }
function View_OwnerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 31, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 30, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "row mb-5 mt-5 align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h2", [["class", "font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", " ", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "col-2 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAddEnquiry(_co.owner) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "d-none d-md-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Generic Enquiry"])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OwnerComponent_3)), i1.ɵdid(15, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 14, "ngb-tabset", [], null, [[null, "tabChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tabChange" === en)) {
        var pd_0 = (_co.selectTab($event.nextId) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_NgbTabset_0, i15.RenderType_NgbTabset)), i1.ɵdid(18, 2146304, [["ownerAdditionals", 4]], 1, i4.NgbTabset, [i4.NgbTabsetConfig], { activeId: [0, "activeId"] }, { tabChange: "tabChange" }), i1.ɵqud(603979776, 1, { tabs: 1 }), (_l()(), i1.ɵeld(20, 0, null, null, 5, "ngb-tab", [["id", "enquiries"], ["title", "Enquiries"]], null, null, null, null, null)), i1.ɵdid(21, 2113536, [[1, 4]], 2, i4.NgbTab, [], { id: [0, "id"], title: [1, "title"], disabled: [2, "disabled"] }, null), i1.ɵqud(603979776, 2, { titleTpls: 1 }), i1.ɵqud(603979776, 3, { contentTpls: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_OwnerComponent_4)), i1.ɵdid(25, 16384, [[3, 4]], 0, i4.NgbTabContent, [i1.TemplateRef], null, null), (_l()(), i1.ɵeld(26, 0, null, null, 5, "ngb-tab", [["id", "comments"], ["title", "Comments"]], null, null, null, null, null)), i1.ɵdid(27, 2113536, [[1, 4]], 2, i4.NgbTab, [], { id: [0, "id"], title: [1, "title"], disabled: [2, "disabled"] }, null), i1.ɵqud(603979776, 4, { titleTpls: 1 }), i1.ɵqud(603979776, 5, { contentTpls: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_OwnerComponent_6)), i1.ɵdid(31, 16384, [[5, 4]], 0, i4.NgbTabContent, [i1.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.owner.contactDetails; _ck(_v, 15, 0, currVal_3); var currVal_4 = _co.meta.selectedTab; _ck(_v, 18, 0, currVal_4); var currVal_5 = "enquiries"; var currVal_6 = "Enquiries"; var currVal_7 = _co.meta.loading; _ck(_v, 21, 0, currVal_5, currVal_6, currVal_7); var currVal_8 = "comments"; var currVal_9 = "Comments"; var currVal_10 = _co.meta.loading; _ck(_v, 27, 0, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), _co.owner.knownAs)); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.owner.firstName; var currVal_2 = _co.owner.surname; _ck(_v, 8, 0, currVal_1, currVal_2); }); }
export function View_OwnerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i11.UpperCasePipe, []), i1.ɵpid(0, i11.TitleCasePipe, []), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-navbar", [], null, null, null, i16.View_NavbarComponent_0, i16.RenderType_NavbarComponent)), i1.ɵdid(3, 114688, null, 0, i17.NavbarComponent, [i10.EventsService, i18.AuthenticationService, i14.Router, i4.NgbModal], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OwnerComponent_1)), i1.ɵdid(5, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OwnerComponent_2)), i1.ɵdid(7, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = _co.meta.loading; _ck(_v, 5, 0, currVal_0); var currVal_1 = !_co.meta.loading; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_OwnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-owner", [], null, null, null, View_OwnerComponent_0, RenderType_OwnerComponent)), i1.ɵdid(1, 114688, null, 0, i19.OwnerComponent, [i5.ToastrService, i14.Router, i14.ActivatedRoute, i11.Location, i7.OwnersService, i10.EventsService, i4.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OwnerComponentNgFactory = i1.ɵccf("app-owner", i19.OwnerComponent, View_OwnerComponent_Host_0, {}, {}, []);
export { OwnerComponentNgFactory as OwnerComponentNgFactory };
