<form name="form" #formWithdrawn="ngForm" (ngSubmit)="formWithdrawn.form.valid && save()">
  <div class="modal-header d-flex justify-content-center">
    <h4 class="modal-title text-truncate">WITHDRAWN INFO FOR {{meta.title | uppercase}}</h4>
  </div>
  <div class="modal-body" *ngIf="meta.loading">
    <div class="row">
      <div class="col text-center">
        <fa name="circle-o-notch" size="2x" animation="spin"></fa>
      </div>
    </div>
  </div>
  <div class="modal-body" *ngIf="!meta.loading">
    <div class="row" *ngIf="withdrawnInfo">
      <div class="col">
        <div class="form-group">
          <label for="reason">Reason</label>
          <select
            class="custom-select"
            #reason="ngModel"
            name="reason"
            [(ngModel)]="withdrawnInfo.reasonId"
            required
          >
            <option *ngFor="let item of possibleReasons" [value]="item.id">{{item.reason}}</option>
          </select>
          <div *ngIf="formWithdrawn.submitted && reason.invalid">
            <div class="alert alert-danger" *ngIf="reason.errors.required">A Reason is required</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="withdrawnInfo">
      <div class="col">
        <div class="form-group">
          <label for="comment">Comment</label>
          <textarea class="form-control textarea-height" id="comment" name="comment" [(ngModel)]="withdrawnInfo.comment"></textarea>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="withdrawnInfo">
      <div class="col">
        <div class="form-group">
          <label for="internalComment">Internal Comment</label>
          <textarea class="form-control textarea-height" id="internalComment" name="internalComment" [(ngModel)]="withdrawnInfo.internalComment"></textarea>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="withdrawnInfo">
      <div class="col col-lg-3 offset-lg-3">
        <form class="form">
          <div class="form-group">
            <label for="declaredDate">Declared Date:</label>
            <div class="input-group calendar-open-up" container="body">
              <input class="form-control" placeholder="dd-mm-yyyy" id="declaredDate" name="declaredDate" [(ngModel)]="declared.date" ngbDatepicker #declaredDate="ngbDatepicker" (click)="declaredDate.toggle(); $event.stopPropagation();" (document:click)="datepickerFix($event, declaredDate)">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="declaredDate.toggle(); $event.stopPropagation();" type="button">
                  <fa name="calendar"></fa>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col">
        <label for="declaredTime">Declared Time:</label>
        <ngb-timepicker id="declaredTime" name="declaredTime" [(ngModel)]="declared.time" [spinners]="false"></ngb-timepicker>
      </div>
    </div>
  </div>
  <div *ngIf="!meta.saving" class="modal-footer d-flex justify-content-around">
    <button type="button" class="btn btn-outline-dark" (click)="cancel('cancel')">Close</button>
    <button type="submit" class="btn btn-primary" [disabled]="!withdrawnInfo">Save</button>
  </div>
  <div *ngIf="meta.saving" class="modal-footer d-flex justify-content-around">
    <h1>
      <fa name="circle-o-notch" animation="spin"></fa>
    </h1>
  </div>
</form>
